import React from 'react';

import { Code, SimpleGrid, VStack } from '@chakra-ui/react';

import { DataType, Organization } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { CopyToClipboard, KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard';
import { AddressDetails } from '@/components/common/data/AddressDetails';
import { ReferenceLink } from '@/components/common/data/ReferenceLink';

export interface OrganizationDetailsProps {
  org: Organization;
  adminActions?: React.ReactNode;
  showMetadata?: boolean;
  showAdminDetails?: boolean;
}

export const OrganizationDetails: React.FC<OrganizationDetailsProps> = ({
  org,
  adminActions,
  showMetadata,
  showAdminDetails = false,
}) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      data={org}
      dataType={DataType.Organization}
      header={adminActions}
      showMetadata={showMetadata}
      details={
        <SimpleGrid columns={3} gap={6} w="100%">
          <VStack alignItems="start">
            <KeyValue
              label={t('features:organizations.name')}
              value={org.organizationName}
            />
            <KeyValue
              label={t('features:organizations.shortName')}
              value={org.shortName}
            />
            {showAdminDetails && (
              <KeyValue
                label={t('forms:labels.type')}
                value={
                  org.type ? t(`features:organizations.type.${org.type}`) : '-'
                }
              />
            )}
          </VStack>
          <VStack alignItems="start">
            <KeyValue
              label={t('features:organizations.orgId')}
              value={
                <CopyToClipboard value={org.id}>
                  <Code>{org.id}</Code>
                </CopyToClipboard>
              }
            />
            {showAdminDetails && (
              <React.Fragment>
                <KeyValue
                  label={t('common:customer')}
                  value={
                    org.customerId ? (
                      <ReferenceLink
                        dataType={DataType.Customer}
                        id={org.customerId}
                        accessor="name"
                      />
                    ) : (
                      '-'
                    )
                  }
                />
                <KeyValue
                  label={t('forms:labels.status')}
                  value={
                    org.status
                      ? t(`features:organizations.status.${org.status}`)
                      : '-'
                  }
                />
              </React.Fragment>
            )}
          </VStack>
          <KeyValue
            label={t('forms:labels.address')}
            value={<AddressDetails data={org} />}
          />
        </SimpleGrid>
      }
    />
  );
};
