import React, { useMemo } from 'react';

import { ExternalMappingEntityType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormCombobox, FormField } from '@m3ter-com/console-core/components';
import { SelectOption } from '@m3ter-com/ui-components';

import { ErrorAlert } from '@/components/common/errors/ErrorAlert';

import useExternalMappingM3terIdField from './useExternalMappingM3terIdField';

export interface ExternalMappingM3terIdFieldProps {
  entityType?: ExternalMappingEntityType;
  externalSystem?: string;
  externalTable?: string;
  hideLabel?: boolean;
  initialEntityId?: string;
  name: string;
  optionsFilter?: (option: SelectOption) => boolean;
}

export const ExternalMappingM3terIdField: React.FC<
  ExternalMappingM3terIdFieldProps
> = ({
  entityType,
  externalSystem,
  externalTable,
  hideLabel = false,
  initialEntityId,
  name,
  optionsFilter,
}) => {
  const { t } = useTranslation();
  const { error, isLoading, options } = useExternalMappingM3terIdField(
    entityType,
    externalSystem,
    externalTable,
    initialEntityId
  );
  const availableOptions = useMemo<Array<SelectOption>>(
    () => (optionsFilter ? options.filter(optionsFilter) : options),
    [options, optionsFilter]
  );

  return (
    <React.Fragment>
      {error && <ErrorAlert error={error} />}
      <FormField
        isRequired
        hideLabel={hideLabel}
        name={name}
        label={t('forms:labels.m3terEntity')}
        control={FormCombobox}
        isLoadingOptions={isLoading}
        options={availableOptions}
      />
    </React.Fragment>
  );
};
