import React from 'react';

import { DataType, MeasurementsDeletion } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyMeasurementsDeletionsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "MeasurementsDeletionsRoute" */ '@/components/routes/admin/measurements-deletions/MeasurementsDeletionsRoute'
  ).then((module) => ({ default: module.MeasurementsDeletionsRoute }))
);

const LazyMeasurementsDeletionsListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "MeasurementsDeletionsListRoute" */ '@/components/routes/admin/measurements-deletions/MeasurementsDeletionsListRoute'
  ).then((module) => ({ default: module.MeasurementsDeletionsListRoute }))
);

const LazyMeasurementsDeletionsCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "MeasurementsDeletionsCreateRoute" */ '@/components/routes/admin/measurements-deletions/MeasurementsDeletionsCreateRoute'
  ).then((module) => ({ default: module.MeasurementsDeletionsCreateRoute }))
);

export default () =>
  createCrudRoute<MeasurementsDeletion>({
    path: 'measurements-deletions',
    dataType: DataType.MeasurmentsDeletion,
    element: <LazyMeasurementsDeletionsRoute />,
    list: <LazyMeasurementsDeletionsListRoute />,
    create: <LazyMeasurementsDeletionsCreateRoute />,
  });
