import { useCallback, useMemo, useState } from 'react';

import sortBy from 'lodash/sortBy';
import { useQuery } from '@tanstack/react-query';

import { DataType, Meter } from '@m3ter-com/m3ter-api';

import { dataTypeListAllQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';

const filterMeters = (
  meters: Array<Meter>,
  searchTerm: string
): Array<Meter> => {
  const lcSearchTerm = searchTerm.toLowerCase();

  return meters.filter((meter) => {
    const name = meter.name.toLowerCase();
    return (
      name.startsWith(lcSearchTerm) ||
      name.split(' ').some((word) => word.startsWith(lcSearchTerm))
    );
  });
};

const useUsageQueryBuilderMeters = () => {
  const pathParams = useOrgPathParams();

  const {
    data: meters = [],
    isLoading,
    error,
  } = useQuery(
    dataTypeListAllQuery({
      dataType: DataType.Meter,
      pathParams,
    })
  );

  const sortedMeters = useMemo(() => sortBy(meters, 'name'), [meters]);

  // Filtering.

  const [searchTerm, setSearchTerm] = useState<string>('');

  const clearSearchTerm = useCallback(() => {
    setSearchTerm('');
  }, []);

  const filteredSortedMeters = useMemo(
    () =>
      searchTerm !== '' ? filterMeters(sortedMeters, searchTerm) : sortedMeters,
    [searchTerm, sortedMeters]
  );

  return {
    isLoading,
    error,
    meters,
    filteredSortedMeters,
    searchTerm,
    setSearchTerm,
    clearSearchTerm,
  };
};

export default useUsageQueryBuilderMeters;
