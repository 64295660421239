import React, { useCallback, useMemo, useState } from 'react';

import { Flex } from '@chakra-ui/react';

import { DataType, ExternalMapping, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { DropdownButton, DropdownButtonAction } from '@m3ter-com/ui-components';

import { EntityRouteListIds } from '@/types/lists';

import { NamedLink } from '@/components/common/navigation/NamedLink';
import {
  ColumnDefinition,
  CrudList,
  CrudListCreateLink,
  CrudListFooter,
  CrudListHeader,
  CrudListRefreshButton,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { IntegrationDestinationLogo } from '@/components/features/integrations/IntegrationDestinationLogo';
import { EntitiesBulkDeleteAction } from '@/components/common/data/EntitiesBulkDeleteAction';
import { ExternalMappingM3terIdAccessor } from '@/components/features/external-mappings/ExternalMappingM3terIdAccessor';

const searchFields = [
  'm3terEntity',
  'm3terId',
  'externalSystem',
  'externalTable',
  'externalId',
];

export const ExternalMappingsListRoute: React.FC = () => {
  const { t } = useTranslation();

  const [selectedExternalMappingIds, setSelectedExternalMappingIds] = useState<
    Array<Id>
  >([]);

  const clearSelectedExternalMappingIds = useCallback(() => {
    setSelectedExternalMappingIds([]);
  }, []);

  const columns = useMemo<Array<ColumnDefinition<ExternalMapping>>>(
    () => [
      {
        id: 'm3terEntity',
        header: t('forms:labels.m3terEntityType'),
        accessor: (externalMapping) =>
          t(
            `features:externalMappings.entityTypes.${externalMapping.m3terEntity}`
          ),
      },
      {
        id: 'm3terId',
        header: t('forms:labels.m3terEntity'),
        accessor: (externalMapping) => (
          <ExternalMappingM3terIdAccessor externalMapping={externalMapping} />
        ),
      },
      {
        id: 'externalSystem',
        header: t('forms:labels.externalSystem'),
        accessor: (externalMapping) => (
          <IntegrationDestinationLogo
            destination={externalMapping.externalSystem}
            height="20px"
          />
        ),
      },
      {
        id: 'externalTable',
        header: t('forms:labels.externalTable'),
        accessor: 'externalTable',
      },
      {
        id: 'externalId',
        header: t('forms:labels.externalEntityId'),
        accessor: 'externalId',
      },
    ],
    [t]
  );

  const createActions = useMemo<Array<DropdownButtonAction>>(
    () => [
      {
        key: 'bulk-create',
        label: '',
        as: CrudListCreateLink,
        intent: 'primary',
      },
      {
        key: 'bulk-create',
        label: t('common:createInBulk'),
        as: NamedLink,
        name: 'external-mappings.bulk-create',
      },
    ],
    [t]
  );

  return (
    <CrudList<ExternalMapping>
      listId={EntityRouteListIds.ExternalMapping}
      searchFields={searchFields}
    >
      <CrudListHeader hideCreateLink hideRefresh>
        <Flex
          alignItems="center"
          flexFlow="row nowrap"
          gap={4}
          justifyContent="flex-start"
          marginLeft="auto"
        >
          <CrudListRefreshButton />
          <DropdownButton actions={createActions} maxButtons={1} />
        </Flex>
      </CrudListHeader>
      <CrudListTable
        columns={columns}
        onSelectedItemsChange={setSelectedExternalMappingIds}
        selectedItems={selectedExternalMappingIds}
        selectionType="multi"
      />
      <CrudListFooter>
        <EntitiesBulkDeleteAction
          dataType={DataType.ExternalMapping}
          selectedItems={selectedExternalMappingIds}
          clearSelectedItems={clearSelectedExternalMappingIds}
        />
      </CrudListFooter>
    </CrudList>
  );
};
