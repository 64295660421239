import { useEffect } from 'react';

import userflow from 'userflow.js';
import { useColorMode } from '@chakra-ui/react';

import config from '@/config';
import useAuth from '@/hooks/auth/useAuth';

const useUserflow = () => {
  const { user } = useAuth();
  const { colorMode } = useColorMode();

  // Initial init call on mount.
  useEffect(() => {
    userflow.init(config.userflow.token);
  }, []);

  // Once the user is signed in we can identify them with userflow.
  useEffect(() => {
    if (user) {
      // Only send the user ID and color mode so we don't expose any customer data to Userflow.
      userflow.identify(user.id, { colorMode });
    }
    // We don't want to call `identify` every time the color mode changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Keep the selected colour mode in sync with userflow.
  useEffect(() => {
    if (userflow.isIdentified()) {
      userflow.updateUser({ colorMode });
    }
  }, [colorMode]);
};

export default useUserflow;
