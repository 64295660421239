import React, { useCallback, useEffect, useMemo } from 'react';

import {
  chakra,
  IconButton,
  InputGroup,
  InputRightAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StyleProps,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { CalendarDaysIcon, XIcon } from 'lucide-react';
import {
  useDatepicker,
  START_DATE,
  OnDatesChangeProps,
  UseDatepickerProps,
} from '@datepicker-react/hooks';

import { Calendar } from '../Calendar/Calendar';
import {
  CalendarContextProvider,
  CalendarTranslations,
} from '../Calendar/CalendarContext';
import { TimeSelect } from '../Calendar/TimeSelect';
import { DateInput } from '../../forms/DateInput/DateInput';

export interface SystemDatePickerProps extends StyleProps {
  value: Date | null;
  isClearable: boolean;
  isDisabled: boolean;
  showTimeSelect: boolean;
  translations: CalendarTranslations;
  timeZoneLabel?: string;
  onChange: (newDate: Date | null) => void;
}

export const SystemDatePicker: React.FC<SystemDatePickerProps> = ({
  value,
  isClearable,
  isDisabled,
  showTimeSelect,
  translations,
  timeZoneLabel,
  onChange,
  ...styleProps
}) => {
  const onCalendarStateChange = useCallback(
    (data: OnDatesChangeProps) => {
      if (data.startDate === null) {
        onChange(null);
      } else {
        const retainCurrentTimeOnCalendarDateSelect =
          showTimeSelect && value !== null;
        const newDate = new Date(
          data.startDate.getFullYear(),
          data.startDate.getMonth(),
          data.startDate.getDate(),
          retainCurrentTimeOnCalendarDateSelect ? value.getHours() : 0,
          retainCurrentTimeOnCalendarDateSelect ? value.getMinutes() : 0
        );
        onChange(newDate);
      }
    },
    [showTimeSelect, value, onChange]
  );
  const calendarState = useMemo<UseDatepickerProps>(
    () => ({
      changeActiveMonthOnSelect: true,
      endDate: null,
      focusedInput: START_DATE,
      startDate: value,
      onDatesChange: onCalendarStateChange,
    }),
    [value, onCalendarStateChange]
  );
  const {
    activeMonths,
    firstDayOfWeek,
    focusedDate,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
    goToNextMonthsByOneMonth,
    goToNextYear,
    goToPreviousMonthsByOneMonth,
    goToPreviousYear,
  } = useDatepicker(calendarState);

  const onClearDate = useCallback(() => {
    onChange(null);
  }, [onChange]);

  // react-datepicker/hooks defaults to showing either the current month
  // or the last month the user viewed.
  // If this is the first time the user is opening the calendar and a value
  // has been set on the input, we want to show the month of the selected date
  // and if the user has typed a date in (which doesn't use the hooks to set the
  // selection), we want to show the typed date when they open the calendar.
  // To achieve both of these, we can use the `onDateFocus` callback to update the
  // calendar view each time the date pickers value is updated.
  // We can't have `onDateFocus` as a dependency of this useEffect though since it
  // changes reference each time the user interacts with the calendar ¯\_(ツ)_/¯
  useEffect(() => {
    if (value) {
      onDateFocus(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const {
    isOpen: isCalendarOpen,
    onClose: onCloseCalendar,
    onToggle: onToggleCalendar,
  } = useDisclosure();

  const styles = useMultiStyleConfig('DatePicker', {
    isCalendarOpen,
    isClearable,
  });

  return (
    <CalendarContextProvider
      activeMonths={activeMonths}
      firstDayOfWeek={firstDayOfWeek}
      focusedDate={focusedDate}
      showHoverRange={false}
      translations={translations}
      isDateBlocked={isDateBlocked}
      isDateFocused={isDateFocused}
      isDateHovered={isDateHovered}
      isDateSelected={isDateSelected}
      isFirstOrLastSelectedDate={isFirstOrLastSelectedDate}
      onDateFocus={onDateFocus}
      onDateHover={onDateHover}
      onDateSelect={onDateSelect}
      goToNextMonth={goToNextMonthsByOneMonth}
      goToNextYear={goToNextYear}
      goToPreviousMonth={goToPreviousMonthsByOneMonth}
      goToPreviousYear={goToPreviousYear}
    >
      <InputGroup {...styleProps} isolation="auto">
        <DateInput
          includeTime={showTimeSelect}
          value={value}
          onChange={onChange}
          isDisabled={isDisabled}
          data-testid="date-picker-input"
        />
        {!!timeZoneLabel && (
          <InputRightAddon
            data-testid="date-picker-timezone-label"
            sx={styles.timeZoneLabelWrapper}
          >
            {timeZoneLabel}
          </InputRightAddon>
        )}
        <InputRightAddon sx={styles.calendarOpenButtonWrapper}>
          <Popover
            isOpen={isCalendarOpen}
            onClose={onCloseCalendar}
            placement="bottom"
            strategy="fixed"
          >
            <PopoverTrigger>
              <IconButton
                data-testid="date-picker-calendar-button"
                aria-label="Open date picker calendar"
                icon={<CalendarDaysIcon size={16} />}
                isDisabled={isDisabled}
                sx={styles.calendarOpenButton}
                onClick={onToggleCalendar}
              />
            </PopoverTrigger>
            <PopoverContent sx={styles.calendarPopover}>
              <PopoverArrow />
              <PopoverBody data-testid="date-picker-calendar">
                <chakra.div sx={styles.calendarContentWrapper}>
                  <Calendar />
                  {showTimeSelect && (
                    <TimeSelect
                      isDisabled={isDisabled}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                </chakra.div>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </InputRightAddon>
        {isClearable && (
          <InputRightAddon sx={styles.clearButtonWrapper}>
            <IconButton
              data-testid="date-picker-clear-button"
              aria-label="Clear date"
              icon={<XIcon size={16} />}
              isDisabled={isDisabled}
              onClick={onClearDate}
              borderRadius="inherit"
            />
          </InputRightAddon>
        )}
      </InputGroup>
    </CalendarContextProvider>
  );
};
