import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { integrationRunAccessor } from '@/util/integrations';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { createCrudRoute } from '@/routes/crud';
import { IntegrationRunsRoute } from '@/components/routes/integrations/runs/IntegrationRunsRoute';
import { IntegrationRunRedirectRoute } from '@/components/routes/integrations/runs/IntegrationRunRedirectRoute';

const LazyIntegrationRunDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationRunDetailsRoute" */ '@/components/routes/integrations/runs/IntegrationRunDetailsRoute'
  ).then((module) => ({ default: module.IntegrationRunDetailsRoute }))
);

export default () =>
  createCrudRoute({
    path: 'runs',
    dataType: DataType.IntegrationRun,
    titleAccessor: integrationRunAccessor,
    element: <IntegrationRunsRoute />,
    details: <LazyIntegrationRunDetailsRoute />,
    directChildRoutes: [
      { index: true, element: <IntegrationRunRedirectRoute /> },
    ],
  });
