import {
  Id,
  PrincipalPermission,
  ResourceGroupItem,
  ServiceUserCredential,
  UserGroup,
} from '../types';

import { getOrganizationPath } from '../util/path';
import { del, get, post, put } from '../client';

export interface UpdateEntityPermissionPolicyData {
  actionName: string;
  organizationId: Id;
  permissionPolicyId: Id;
  body?: Partial<UpdateEntityPermissionPolicyBody>;
}

export interface UpdateEntityPermissionPolicyBody {
  principalId: Id;
}

// Similar to `ListResponse`, but `ResourceGroupItem` is not an `Entity`.
export interface UserGroupItemsListResponse {
  data: Array<ResourceGroupItem>;
  netxToken?: string;
}

// Similar to `ListResponse`, but `ServiceUserCredential` is not an `Entity`.
export interface ServiceUserCredentialsResponse {
  data: Array<ServiceUserCredential>;
}

export const getResourceTypes = () =>
  get({ path: '/resourcetypes' }) as Promise<Array<string>>;

export const updateEntityPermissionPolicy = (
  actionName: string,
  organizationId: Id,
  permissionPolicyId: Id,
  body?: UpdateEntityPermissionPolicyBody
) =>
  post({
    body: body ?? {},
    path: getOrganizationPath(
      '/permissionpolicies/:permissionPolicyId/:actionName'
    ),
    pathParams: {
      organizationId,
      permissionPolicyId,
      actionName,
    },
  }) as Promise<PrincipalPermission>;

export const updateEntityPermissionPolicies = (
  actionName: string,
  organizationId: Id,
  permissionPolicyIds: Array<Id>,
  body?: UpdateEntityPermissionPolicyBody
) =>
  Promise.all(
    permissionPolicyIds.map((permissionPolicyId) =>
      updateEntityPermissionPolicy(
        actionName,
        organizationId,
        permissionPolicyId,
        body
      )
    )
  );

export const getServiceUserCredentials = (
  organizationId: Id,
  serviceUserId: Id
) =>
  get({
    path: getOrganizationPath('/serviceusers/:serviceUserId/credentials'),
    pathParams: { organizationId, serviceUserId },
  }) as Promise<ServiceUserCredentialsResponse>;

export const createServiceUserCredential = (
  organizationId: Id,
  serviceUserId: Id
) =>
  post({
    path: getOrganizationPath('/serviceusers/:serviceUserId/credentials'),
    pathParams: { organizationId, serviceUserId },
  }) as Promise<ServiceUserCredential>;

export const deleteServiceUserCredential = (
  organizationId: Id,
  serviceUserId: Id,
  apiKey: Id
) =>
  del({
    path: getOrganizationPath(
      '/serviceusers/:serviceUserId/credentials/:apiKey'
    ),
    pathParams: { organizationId, serviceUserId, apiKey },
  }) as Promise<ServiceUserCredential>;

export const deactivateServiceUserCredential = (
  organizationId: Id,
  serviceUserId: Id,
  apiKey: Id
) =>
  put({
    path: getOrganizationPath(
      '/serviceusers/:serviceUserId/credentials/:apiKey/inactivate'
    ),
    pathParams: { organizationId, serviceUserId, apiKey },
  }) as Promise<ServiceUserCredential>;

export const activateServiceUserCredential = (
  organizationId: Id,
  serviceUserId: Id,
  apiKey: Id
) =>
  put({
    path: getOrganizationPath(
      '/serviceusers/:serviceUserId/credentials/:apiKey/activate'
    ),
    pathParams: { organizationId, serviceUserId, apiKey },
  }) as Promise<ServiceUserCredential>;

export const listUserGroupItems = (organizationId: Id, userGroupId: Id) =>
  post({
    path: getOrganizationPath('/resourcegroups/user/:userGroupId/contents'),
    pathParams: { organizationId, userGroupId },
  }) as Promise<UserGroupItemsListResponse>;

export const addUserToUserGroup = (
  organizationId: Id,
  userGroupId: Id,
  userId: Id
) =>
  post({
    path: getOrganizationPath('/resourcegroups/user/:userGroupId/addresource'),
    pathParams: { organizationId, userGroupId },
    body: { targetType: 'item', targetId: userId },
  }) as Promise<UserGroup>;

export const addUsersToUserGroup = async (
  organizationId: Id,
  userGroupId: Id,
  userIds: Array<Id>
) =>
  Promise.all(
    userIds.map((userId) =>
      addUserToUserGroup(organizationId, userGroupId, userId)
    )
  );

export const removeUserFromUserGroup = (
  organizationId: Id,
  userGroupId: Id,
  userId: Id
) =>
  post({
    path: getOrganizationPath(
      '/resourcegroups/user/:userGroupId/removeresource'
    ),
    pathParams: { organizationId, userGroupId },
    body: { targetType: 'item', targetId: userId },
  }) as Promise<UserGroup>;

export const removeUsersFromUserGroup = async (
  organizationId: Id,
  userGroupId: Id,
  userIds: Array<Id>
) =>
  Promise.all(
    userIds.map((userId) =>
      removeUserFromUserGroup(organizationId, userGroupId, userId)
    )
  );
