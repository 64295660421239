import React from 'react';

import { Flex, Icon, Text } from '@chakra-ui/react';
import { CircleHelpIcon } from 'lucide-react';

export interface NotFoundScreenProps {
  message: string;
}

export const NotFoundScreen: React.FC<NotFoundScreenProps> = ({ message }) => (
  <Flex
    alignItems="center"
    flexFlow="column nowrap"
    gap={4}
    height="100vh"
    justifyContent="center"
    width="100vw"
  >
    <Icon as={CircleHelpIcon} boxSize={12} />
    {!!message && <Text fontSize="xl">{message}</Text>}
  </Flex>
);
