import React from 'react';

import { List, ListItem, SimpleGrid } from '@chakra-ui/react';

import { DataType, Invitation } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import { DetailsCard } from '@/components/common/data/DetailsCard';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { ReferenceLink } from '@/components/common/data/ReferenceLink';

export interface InvitationDetailsProps {
  data: Invitation;
}

export const InvitationDetails: React.FC<InvitationDetailsProps> = ({
  data: invitation,
}) => {
  const { t } = useTranslation();
  const { toLongDate } = useDateFormatter();

  return (
    <React.Fragment>
      <BreadcrumbItem title={invitation.email} />
      <DetailsCard
        data={invitation}
        dataType={DataType.Invitation}
        showEditors={false}
        showMetadata={false}
        details={
          <SimpleGrid columns={4} gap={6} w="100%">
            <KeyValue
              label={t('forms:labels.emailAddress')}
              value={invitation.email}
            />
            <KeyValue
              label={t('forms:labels.accessEndDate')}
              value={
                invitation.dtEndAccess
                  ? toLongDate(invitation.dtEndAccess)
                  : '-'
              }
            />
            <KeyValue
              label={t('forms:labels.inviteExpiryDate')}
              value={toLongDate(invitation.dtExpiry)}
            />
            <KeyValue
              label={t('features:invitations.accepted')}
              value={invitation.accepted ? t('common:yes') : t('common:no')}
            />
            <KeyValue
              label={t('common:permissionPolicys')}
              value={
                invitation.permissionPolicyIds &&
                invitation.permissionPolicyIds.length > 0 ? (
                  <List>
                    {invitation.permissionPolicyIds.map((policyId) => (
                      <ListItem key={policyId}>
                        <ReferenceLink
                          dataType={DataType.PermissionPolicy}
                          id={policyId}
                          accessor="name"
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  '-'
                )
              }
            />
          </SimpleGrid>
        }
      />
    </React.Fragment>
  );
};
