import React, { useCallback } from 'react';

import { forwardRef } from '@chakra-ui/react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { Input, InputProps } from '../Input/Input';

export interface AdvancedNumberInputProps
  extends Omit<InputProps, 'onChange' | 'value'> {
  value?: number;
  onChange?: (value: number) => void;
}

const getSeparators = () => {
  const nf = new Intl.NumberFormat(navigator.language);
  // Using 10000 because some locales only group above 10000 rather than 1000.
  const parts = nf.formatToParts(10000.1);
  const groupPart = parts.find((part) => part.type === 'group');
  const decimalPart = parts.find((part) => part.type === 'decimal');
  return [groupPart?.value ?? ',', decimalPart?.value ?? '.'];
};

const [thousandSeparator, decimalSeparator] = getSeparators();

export const AdvancedNumberInput = forwardRef<
  AdvancedNumberInputProps,
  'input'
>(({ value, onChange, ...props }, ref) => {
  const onValueChange = useCallback(
    (values: NumberFormatValues) => {
      if (onChange) {
        onChange(values.floatValue !== undefined ? values.floatValue : NaN);
      }
    },
    [onChange]
  );

  return (
    <Input
      as={NumberFormat}
      getInputRef={ref}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      value={value}
      onValueChange={onValueChange}
      {...props}
    />
  );
});

// Make Chakra treat this like its own Input.
// https://github.com/chakra-ui/chakra-ui/issues/2269
AdvancedNumberInput.id = 'Input';
