import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import { dataTypeListQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useSearchableEntityOptions from '@/hooks/data/useSearchableEntityOptions';

const useUsageQueryBuilderAccounts = (selectedIds: Array<Id>) => {
  const {
    isLoading: isLoadingOptions,
    error: optionsError,
    options: enabledOptions,
    search,
  } = useSearchableEntityOptions({
    dataType: DataType.Account,
    searchFields: ['name', 'code'],
    accessor: 'name',
    detailAccessor: 'code',
    value: null,
  });

  const pathParams = useOrgPathParams();
  const {
    isLoading: isLoadingAccounts,
    isFetching: isFetchingAccounts,
    error,
    data: accounts = [],
  } = useQuery(
    dataTypeListQuery(
      {
        dataType: DataType.Account,
        pathParams,
        queryParams: { ids: selectedIds },
      },
      // Keep the previous data to avoid loading spinners when
      // removing accounts from the list.
      { keepPreviousData: true, enabled: selectedIds.length > 0 }
    )
  );

  // Add isDisabled to options based on whether the account is already selected.
  const options = useMemo(
    () =>
      enabledOptions.map((option) => ({
        ...option,
        isDisabled: selectedIds.includes(option.value),
      })),
    [enabledOptions, selectedIds]
  );

  return {
    isLoadingAccounts,
    isFetchingAccounts,
    isLoadingOptions,
    error: error ?? optionsError,
    options,
    search,
    accounts,
  };
};

export default useUsageQueryBuilderAccounts;
