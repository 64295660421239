import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  FormLabel,
  HStack,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  DataType,
  ChildBillingMode,
  Commitment,
  DrawdownChargeTypes,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Alert,
  FormStack,
  MultiSelectOption,
  RadioTabs,
} from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormAdvancedNumberInput,
  FormField,
  FormFieldset,
  FormInput,
  FormMultiSelect,
  FormRadioGroup,
  FormSection,
  FormSwitch,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import commitmentSchema from '@/validation/commitment';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useCurrencies from '@/hooks/util/useCurrencies';
import { FormDatePicker } from '@/components/forms/FormDatePicker';
import {
  FormEntitySelect,
  DataTypeFormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import {
  FormEntityMultiSelect,
  DataTypeFormEntityMultiSelect,
} from '@/components/forms/FormEntityMultiSelect';
import { BillInAdvanceField } from '@/components/features/pricing/BillInAdvanceField';
import { AccountContractField } from '@/components/features/accounts/AccountContractField';
import { FormPicklistSelect } from '@/components/features/accounts/FormPicklistSelect';
import useOrg from '@/hooks/data/crud/useOrg';
import { getBillInAdvanceDescription } from '@/util/billing';

import { CommitmentFeeDatesField } from './CommitmentFeeDatesField';

enum BillingType {
  WithPlan = 'plan',
  Schedule = 'schedule',
}

export interface CommitmentFormProps
  extends BaseFormProps<UnsavedEntity<Commitment>> {}

const CommitmentFeeFields: React.FC = () => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { orgConfig } = useOrg();

  const currency = useWatch({ name: 'currency' });
  const amount = useWatch({ name: 'amount' });
  const amountPrePaid = useWatch({ name: 'amountPrePaid' });
  const feeDates = useWatch({ name: 'feeDates' });

  const { setValue } = useFormContext();

  const remaining = amount - amountPrePaid;

  const { isOpen: isFullyPrepaid, onToggle: onTogglePrepaid } = useDisclosure({
    defaultIsOpen: remaining === 0,
  });

  const [billingType, setBillingType] = useState<BillingType>(
    feeDates && feeDates.length > 0
      ? BillingType.Schedule
      : BillingType.WithPlan
  );

  useEffect(() => {
    // When pre-payment is fully pre-paid we need to keep the amountPrePaid value equal to the amount.
    if (isFullyPrepaid && amountPrePaid !== amount) {
      setValue('amountPrePaid', amount);
    }
  }, [amount, amountPrePaid, isFullyPrepaid, setValue]);

  const onChangePrepaid = useCallback(() => {
    /**
     * Reset the form form values when we toggle fully pre-paid, we can set amountPrePaid to 0 here as
     * we will update the amountPrePaid value to keep it in sync with the amount value when the prepayment
     * is fully pre-paid in the use effect above.
     */
    setValue('amountFirstBill', 0);
    setValue('amountPrePaid', 0);
    setValue('billEpoch', null);
    setValue('billingInterval', 1);
    setValue('billingOffset', 0);
    setValue('billingPlanId', null);
    setValue('commitmentFeeBillInAdvance', null);
    setValue('commitmentFeeDescription', null);
    setValue('feeDates', []);

    setBillingType(BillingType.WithPlan);
    onTogglePrepaid();
  }, [onTogglePrepaid, setValue]);

  const billingOptions = useMemo(
    () => [
      {
        value: BillingType.WithPlan,
        label: t('features:commitments.billWithPlan'),
        content: (
          <VStack spacing={4}>
            <FormField
              isRequired
              name="billingPlanId"
              label={t('common:plan')}
              control={
                FormEntitySelect as DataTypeFormEntitySelect<DataType.Plan>
              }
              dataType={DataType.Plan}
              accessor="name"
              detailAccessor="code"
            />
            <FormField
              name="amountFirstBill"
              label={t('forms:labels.firstBillFee')}
              control={FormAdvancedNumberInput}
            />
            <FormField
              name="billEpoch"
              label={t('forms:labels.billingCycleDate')}
              helpText={t('forms:helpText.commitmentBillEpoch')}
              control={FormDatePicker}
              stripTime
            />
            <HStack spacing={4} alignSelf="stretch" alignItems="start">
              <FormField
                isRequired
                name="billingInterval"
                label={t('forms:labels.feeBillingInterval')}
                control={FormAdvancedNumberInput}
              />
              <FormField
                isRequired
                name="billingOffset"
                label={t('forms:labels.feeBillingOffset')}
                control={FormAdvancedNumberInput}
              />
            </HStack>
            <FormField
              isRequired
              name="commitmentFeeBillInAdvance"
              helpText={t('forms:helpText.feeBilling')}
              label={t('forms:labels.feeBilling')}
              control={BillInAdvanceField}
              defaultLabel={t('forms:labels.useConfigFromOrgConfig', {
                default: getBillInAdvanceDescription(
                  orgConfig.commitmentFeeBillInAdvance
                ),
              })}
            />
          </VStack>
        ),
      },
      {
        value: BillingType.Schedule,
        label: t('features:commitments.billOnSchedule'),
        content: (
          <CommitmentFeeDatesField
            name="feeDates"
            expectedTotal={remaining}
            currency={currency}
          />
        ),
      },
    ],
    [t, orgConfig, remaining, currency]
  );

  const onBillingTypeChange = useCallback(
    (type: BillingType) => {
      // When changing type we need to reset form values so they don't
      // get submitted and fail validation.
      if (type === BillingType.WithPlan) {
        setValue('feeDates', []);
        setValue('billingInterval', 1);
        setValue('billingOffset', 0);
      } else {
        setValue('billingPlanId', null);
        setValue('billingInterval', null);
        setValue('billingOffset', null);
        setValue('amountFirstBill', null);
        setValue('commitmentFeeBillInAdvance', null);
        setValue('billEpoch', null);
      }

      setBillingType(type);
    },
    [setValue]
  );

  return (
    <FormSection
      minW={
        isFullyPrepaid || billingType === BillingType.WithPlan ? '100%' : '50em'
      }
      transition="min-width 0.2s ease-in-out"
      heading={t('forms:labels.billingConfig')}
    >
      <FormField
        isRequired
        name="amount"
        label={t('forms:labels.amount')}
        control={FormAdvancedNumberInput}
      />
      <VStack alignItems="stretch">
        <FormLabel htmlFor="fully-prepaid-switch" mb={0}>
          {t('forms:labels.fullyPrepaid')}
        </FormLabel>
        <Text fontSize="sm" color="chakra-subtle-text">
          {t('forms:helpText.commitmentBillingConfig')}
        </Text>
        <HStack>
          <Switch
            id="fully-prepaid-switch"
            isChecked={isFullyPrepaid}
            onChange={onChangePrepaid}
          />
        </HStack>
        {isFullyPrepaid && (
          <Alert status="info">
            {t('features:commitments.billingConfigDescription')}
          </Alert>
        )}
      </VStack>
      {!isFullyPrepaid ? (
        <FormStack maxW="100%">
          <FormField
            isRequired
            isDisabled={isFullyPrepaid}
            name="amountPrePaid"
            label={t('forms:labels.amountPrePaid')}
            control={FormAdvancedNumberInput}
            helpText={t('forms:helpText.commitmentAmountPrePaid')}
          />
          {!isFullyPrepaid ? (
            <FormFieldset
              mt={2}
              padding={0}
              paddingTop={4}
              borderWidth={0}
              isDisabled={!remaining}
              legend={t('features:commitments.billingForRemaining', {
                amount:
                  remaining > 0 ? formatCurrency(remaining, currency) : '',
              })}
              alignSelf="stretch"
            >
              <RadioTabs
                options={billingOptions}
                value={billingType}
                onChange={onBillingTypeChange}
                isDisabled={!remaining}
              />
              <VStack mt={4}>
                <FormField
                  name="commitmentFeeDescription"
                  label={t('forms:labels.feeDescription')}
                  control={FormInput}
                />
              </VStack>
            </FormFieldset>
          ) : null}
        </FormStack>
      ) : null}
    </FormSection>
  );
};

const SeparateOverageUsageField: React.FC = () => {
  const { t } = useTranslation();
  const overageSurchargePercent = useWatch({ name: 'overageSurchargePercent' });

  return (
    <FormField
      name="separateOverageUsage"
      label={t('forms:labels.overageUsage')}
      control={FormSwitch}
      helpText={t('forms:helpText.separateOverageUsage')}
      isDisabled={!!overageSurchargePercent}
    />
  );
};

const OverageSurchargePercentField: React.FC = () => {
  const { t } = useTranslation();
  const separateOverageUsage = useWatch({ name: 'separateOverageUsage' });

  return (
    <FormField
      name="overageSurchargePercent"
      label={t('forms:labels.overageSurchargePercent')}
      control={FormInput}
      isDisabled={separateOverageUsage}
      type="number"
    />
  );
};

const defaultInitialValues: Partial<Commitment> = {};

export const CommitmentForm: React.FC<CommitmentFormProps> = ({
  initialValues = defaultInitialValues,
  isEdit,
  isSaving,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Commitment);

  // We need to convert the dates within fee dates to short format rather than ISO8601.
  const { toShortDate } = useDateFormatter();
  const onSubmit = useCallback(
    (formValues: Commitment) => {
      const { lineItemTypes = [], feeDates = [], ...rest } = formValues;

      onSave({
        ...rest,
        lineItemTypes: lineItemTypes.length > 0 ? lineItemTypes : undefined,
        feeDates: feeDates.map((feeDate) => ({
          ...feeDate,
          date: toShortDate(feeDate.date),
        })),
      });
    },
    [onSave, toShortDate]
  );

  const childBillingModeOptions = useMemo(
    () =>
      Object.values(ChildBillingMode).map((value) => ({
        value,
        label: t(`features:commitments.childBillingMode.${value}`),
      })),
    [t]
  );

  const lineItemTypeOptions = useMemo<Array<MultiSelectOption>>(
    () =>
      Object.values(DrawdownChargeTypes).map((chargeType) => ({
        value: chargeType,
        label: t(`features:billing.lineItemTypes.${chargeType}`),
      })),
    [t]
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={commitmentSchema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <HStack spacing={4}>
            <FormField
              isRequired
              name="startDate"
              label={t('forms:labels.startDateInclusive')}
              control={FormDatePicker}
            />
            <FormField
              isRequired
              name="endDate"
              label={t('forms:labels.endDateExclusive')}
              control={FormDatePicker}
            />
          </HStack>
          <FormField
            isRequired
            name="accountingProductId"
            label={t('forms:labels.accountingProduct')}
            control={
              FormEntitySelect as DataTypeFormEntitySelect<DataType.Product>
            }
            dataType={DataType.Product}
            accessor="name"
            detailAccessor="code"
          />
          <FormField
            isRequired
            name="currency"
            label={t('forms:labels.currency')}
            control={FormPicklistSelect}
            useCodeAsValue
            dataType={DataType.Currency}
          />
          <AccountContractField name="contractId" />
        </FormSection>
        <CommitmentFeeFields />
        <FormSection isOptional heading={t('forms:labels:prepaymentDrawdown')}>
          <FormField
            name="productIds"
            label={t('forms:labels.drawDownProducts')}
            control={
              FormEntityMultiSelect as DataTypeFormEntityMultiSelect<DataType.Product>
            }
            dataType={DataType.Product}
            accessor="name"
            detailAccessor="code"
            helpText={t('forms:helpText.drawDownProducts', {
              entityName: entityNamings.singularLower,
            })}
          />
          <FormField
            name="lineItemTypes"
            label={t('forms:labels.drawDownChargeTypes')}
            control={FormMultiSelect}
            options={lineItemTypeOptions}
            isClearable
          />
        </FormSection>
        <FormSection isOptional heading={t('forms:labels.usageSettings')}>
          <FormField
            name="commitmentUsageDescription"
            label={t('forms:labels.usageDescription')}
            control={FormInput}
          />
          <SeparateOverageUsageField />
          <HStack spacing={4} alignSelf="stretch" alignItems="start">
            <OverageSurchargePercentField />
            <FormField
              name="overageDescription"
              label={t('forms:labels.overageDescription')}
              control={FormInput}
            />
          </HStack>
          <FormField
            name="childBillingMode"
            label={t('forms:labels.commitmentChildBillingMode')}
            helpText={t('forms:helpText.childBillingMode')}
            control={FormRadioGroup}
            stacked
            options={childBillingModeOptions}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', {
                  entityName: entityNamings.singularLower,
                })
              : t('forms:buttons.createEntity', {
                  entityName: entityNamings.singularLower,
                })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
