import { useQuery } from '@tanstack/react-query';

import {
  DataType,
  dataTypeToExternalMappingEntityType,
  Id,
} from '@m3ter-com/m3ter-api';

import { dataTypeListAllQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';

const useEntityExternalMappings = (dataType: DataType, entityId: Id) => {
  const entityType = dataTypeToExternalMappingEntityType[dataType];

  const pathParams = useOrgPathParams({ entityId, entityType });
  const {
    data: entityExternalMappings = [],
    error: entityExternalMappingsError,
    isLoading: isLoadingExternalMappings,
  } = useQuery(
    dataTypeListAllQuery(
      {
        dataType: DataType.ExternalMapping,
        actionName: 'getEntityMappings',
        pathParams,
      },
      { enabled: !!entityType }
    )
  );

  return {
    entityExternalMappings,
    entityExternalMappingsError,
    isLoadingExternalMappings,
  };
};

export default useEntityExternalMappings;
