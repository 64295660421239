import React, { ReactElement } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
} from '@chakra-ui/react';
import { FilterIcon } from 'lucide-react';

import { Entity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Form, FormActions } from '@m3ter-com/console-core/components';
import { BadgeIconButton } from '@m3ter-com/ui-components';

import { ListSearchCriteria } from '@/types/lists';

import useCrudListFilters from '@/hooks/data/crud/useCrudListFilters';

import { CrudListFilterField } from './CrudListFilterFields/CrudListFilterField';

const defaultInitialValues: ListSearchCriteria = {};

export function CrudListFilterMenu<
  EntityType extends Entity = Entity
>(): ReactElement<any, any> | null {
  const { t } = useTranslation();

  const {
    applyFilters,
    currentFilters = defaultInitialValues,
    entityNamings,
    filterDefinitions,
    filtersCount,
    isFilterModalOpen,
    onCloseFilterModal,
    onToggleFilterModal,
  } = useCrudListFilters<EntityType>();

  return Object.keys(filterDefinitions || {}).length > 0 ? (
    <React.Fragment>
      <BadgeIconButton
        aria-label={t('features:search.toggleFilterModal')}
        icon={<FilterIcon size={16} />}
        badge={filtersCount || undefined}
        onClick={onToggleFilterModal}
      />
      <Modal
        isOpen={isFilterModalOpen}
        onClose={onCloseFilterModal}
        data-testid="filter-modal"
      >
        <ModalOverlay />
        <ModalContent minW="50em">
          <ModalHeader>
            {t('common:filterEntities', {
              entityName: entityNamings.pluralLower,
            })}
          </ModalHeader>
          <ModalCloseButton />
          <Form onSubmit={applyFilters} initialValues={currentFilters}>
            <ModalBody>
              <VStack spacing={4}>
                {filterDefinitions!.map((definition) => (
                  <CrudListFilterField<EntityType>
                    key={definition.name}
                    filterDefinition={definition}
                  />
                ))}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <FormActions
                cancelText={t('common:cancel')}
                submitText={t('common:applyFilter')}
                onCancel={onCloseFilterModal}
              />
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </React.Fragment>
  ) : null;
}
