import React from 'react';

export const CaretRightIcon: React.FC = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      d="M3.75 9.01552V2.98448C3.75 2.56688 4.25491 2.35774 4.5502 2.65303L7.56572 5.66855C7.74877 5.85159 7.74877 6.14841 7.56572 6.33145L4.5502 9.34697C4.25491 9.64228 3.75 9.43313 3.75 9.01552Z"
      fill="currentColor"
    />
  </svg>
);
