import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ColumnDisplay, DataTableProps } from '../DataTable/DataTable';
import { TableSortingDirection } from '../TableColumnSortButton/TableColumnSortButton';
import {
  getRowCellRawValue,
  CellValue,
  RowCell,
  RowItem,
  RowItemAccessor,
  RowItemRawValueAccessor,
} from '../tableCommon';

type InteractiveColumnDefinitionBase<
  D extends InteractiveRowItem = InteractiveRowItem
> = {
  id: string;
  accessor: RowItemAccessor<D>;
  header: React.ReactNode;
  align?: 'left' | 'center' | 'right';
  defaultHidden?: boolean;
};

type SortableColumnDefinition<
  D extends InteractiveRowItem = InteractiveRowItem
> = InteractiveColumnDefinitionBase<D> & {
  canSort: true;
  rawValueAccessor: RowItemRawValueAccessor<D>;
  sortType: SortingType;
};

type NonSortableColumnDefinition<
  D extends InteractiveRowItem = InteractiveRowItem
> = InteractiveColumnDefinitionBase<D> & {
  canSort?: false;
  rawValueAccessor?: RowItemRawValueAccessor<D>;
  sortType?: SortingType;
};

export type InteractiveColumnDefinition<
  D extends InteractiveRowItem = InteractiveRowItem
> = SortableColumnDefinition<D> | NonSortableColumnDefinition<D>;

export interface InteractiveColumn {
  id: string;
  align?: 'left' | 'center' | 'right';
  canSort: boolean;
  headerCellValue: CellValue;
}

export interface InteractiveRowItem extends RowItem {
  id: string;
}

export interface InteractiveRow<
  D extends InteractiveRowItem = InteractiveRowItem
> {
  cells: Array<RowCell>;
  item: D;
}

type PaginationType = 'client' | 'server';
type RowSelectionMode = 'single' | 'multi';
type SortingType = 'string' | 'number' | 'date';

type SortingFunction = (
  itemA: InteractiveRowItem,
  itemB: InteractiveRowItem,
  accessor: RowItemRawValueAccessor<any>,
  sortingDirection: TableSortingDirection
) => -1 | 0 | 1;

const dateSortingFunction: SortingFunction = (
  itemA,
  itemB,
  accessor,
  sortingDirection
) => {
  let valueA = getRowCellRawValue<any>(itemA, accessor);
  let valueB = getRowCellRawValue<any>(itemB, accessor);
  const canSortRows =
    (typeof valueA === 'string' || typeof valueA === 'number') &&
    (typeof valueB === 'string' || typeof valueB === 'number');
  if (!canSortRows) return 0;

  valueA = new Date(valueA as string | number).valueOf();
  valueB = new Date(valueB as string | number).valueOf();

  if (Number.isNaN(valueA) || Number.isNaN(valueB)) {
    return 0;
  }

  if (sortingDirection === TableSortingDirection.Ascending) {
    return valueA < valueB ? -1 : 1;
  }

  return valueA > valueB ? -1 : 1;
};

const numberSortingFunction: SortingFunction = (
  itemA,
  itemB,
  accessor,
  sortingDirection
) => {
  const valueA = getRowCellRawValue<any>(itemA, accessor);
  const valueB = getRowCellRawValue<any>(itemB, accessor);
  const canSortRows = typeof valueA === 'number' && typeof valueB === 'number';
  if (!canSortRows) return 0;

  if (sortingDirection === TableSortingDirection.Ascending) {
    return valueA < valueB ? -1 : 1;
  }

  return valueA > valueB ? -1 : 1;
};

const stringSortingFunction: SortingFunction = (
  itemA,
  itemB,
  accessor,
  sortingDirection
) => {
  let valueA = getRowCellRawValue<any>(itemA, accessor);
  let valueB = getRowCellRawValue<any>(itemB, accessor);
  const canSortRows = typeof valueA === 'string' && typeof valueB === 'string';
  if (!canSortRows) return 0;

  valueA = (valueA as string).toLowerCase();
  valueB = (valueB as string).toLowerCase();

  if (sortingDirection === TableSortingDirection.Ascending) {
    return valueA < valueB ? -1 : 1;
  }

  return valueA > valueB ? -1 : 1;
};

const sortingFunctionMap: Record<SortingType, SortingFunction> = {
  date: dateSortingFunction,
  number: numberSortingFunction,
  string: stringSortingFunction,
};

const PAGE_SIZE = 20;

const useInteractiveTable = <D extends InteractiveRowItem = InteractiveRowItem>(
  columnDefinitions: Array<InteractiveColumnDefinition<D>>,
  allItems: Array<D>,
  paginationType?: PaginationType,
  rowSelectionMode?: RowSelectionMode,
  selectedItemId?: string | null,
  onSelectedItemChange?: (itemId: string | null) => void,
  selectedItemIds?: Array<string>,
  onSelectedItemsChange?: (itemIds: Array<string>) => void,
  isItemSelectable?: string | ((item: D) => boolean)
) => {
  /**
   * Selection
   */

  const onSelectedItemsChangeAdapter = useCallback(
    (itemIds: Array<string>) => {
      if (rowSelectionMode === 'single' && onSelectedItemChange) {
        onSelectedItemChange(itemIds[0]);
      } else if (rowSelectionMode === 'multi' && onSelectedItemsChange) {
        onSelectedItemsChange(itemIds);
      }
    },
    [rowSelectionMode, onSelectedItemChange, onSelectedItemsChange]
  );

  const selectedItems = useMemo(
    () => (selectedItemId ? [selectedItemId] : selectedItemIds),
    [selectedItemId, selectedItemIds]
  );

  // Memoized callback for checking if a row can be selected or not based on the item in that row.
  const isItemDisabled = useCallback(
    (rowItem: D) => {
      let isSelectable = !!rowSelectionMode;
      if (isItemSelectable) {
        isSelectable =
          typeof isItemSelectable === 'function'
            ? isItemSelectable(rowItem)
            : !!rowItem[isItemSelectable];
      }

      return !isSelectable;
    },
    [isItemSelectable, rowSelectionMode]
  );

  /**
   * Column display
   */

  const [columnDisplay, setColumnDisplay] = useState<
    Array<ColumnDisplay> | undefined
  >(
    columnDefinitions.map(({ id, defaultHidden }) => ({
      id,
      visible: !defaultHidden,
    }))
  );
  useEffect(() => {
    // Ensure that we update our columnDisplay state if the table's columns change,
    // without losing visibility changes to columns that are still present.
    setColumnDisplay((previousColumnDisplay) => {
      return columnDefinitions.map((columnDefinition) => {
        const previousDisplayState = previousColumnDisplay?.find(
          (columnState) => columnState.id === columnDefinition.id
        );
        const visible = previousDisplayState
          ? previousDisplayState.visible
          : !columnDefinition.defaultHidden;
        return {
          id: columnDefinition.id,
          visible,
        };
      });
    });
  }, [columnDefinitions]);

  /**
   * Sorting functionality
   */

  // Conversion of `canSort` to `isSortable`.
  const adaptedColumnDefinitions = useMemo(
    () =>
      columnDefinitions.map((columnDefinition) => ({
        ...columnDefinition,
        isSortable: columnDefinition.canSort,
      })),
    [columnDefinitions]
  );

  const [sortColumn, setSortColumn] = useState<string | undefined>();
  const [sortDescending, setSortDescending] = useState<boolean>(false);

  // Reset if the column definitions change.
  useEffect(() => {
    setSortColumn(undefined);
    setSortDescending(false);
  }, [columnDefinitions]);

  const onSortChange = useCallback((columnId: string, descending: boolean) => {
    setSortColumn(columnId);
    setSortDescending(descending);
  }, []);

  /**
   * Client-side pagination functionality
   */

  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  // Reset current page & page count if items are added / changed
  useEffect(() => {
    setCurrentPage(1);
    setPageCount(Math.ceil(allItems.length / PAGE_SIZE));
  }, [allItems]);

  const clientPaginationProps = useMemo(
    () => ({ currentPage, pageCount, onChange: setCurrentPage }),
    [currentPage, pageCount, setCurrentPage]
  );

  const items = useMemo<Array<D>>(() => {
    // First, sort all items if sorting has been selected.
    let sortedRowItems = [...allItems];
    if (sortColumn) {
      const selectedSortingColumn = columnDefinitions.find(
        (columnDefinition) => columnDefinition.id === sortColumn
      );
      if (selectedSortingColumn?.canSort) {
        const sortingFunction =
          sortingFunctionMap[selectedSortingColumn.sortType];
        sortedRowItems = sortedRowItems.sort((rowInstanceA, rowInstanceB) =>
          sortingFunction(
            rowInstanceA,
            rowInstanceB,
            selectedSortingColumn.rawValueAccessor,
            sortDescending
              ? TableSortingDirection.Descending
              : TableSortingDirection.Ascending
          )
        );
      }
    }

    // Secondly, slice out a paginated set of row items if client-side pagination
    // is enabled. Otherwise, we just use the whole sorted set.
    let visibleItems: Array<D> = sortedRowItems;
    if (paginationType === 'client') {
      const minIndex = (currentPage - 1) * PAGE_SIZE;
      const maxIndex = currentPage * PAGE_SIZE;
      visibleItems = sortedRowItems.slice(minIndex, maxIndex);
    }

    return visibleItems;
  }, [
    allItems,
    sortColumn,
    sortDescending,
    columnDefinitions,
    paginationType,
    currentPage,
  ]);

  /**
   * DataTable props
   */

  const dataTableProps: DataTableProps<D> = useMemo(
    () => ({
      items,
      columnDefinitions: adaptedColumnDefinitions,
      idAccessor: 'id',
      selectionType: rowSelectionMode,
      selectedItems,
      onSelectedItemsChange: onSelectedItemsChangeAdapter,
      isItemDisabled,
      sortColumn,
      sortDescending,
      onSortChange,
      columnDisplay,
      onColumnDisplayChange: setColumnDisplay,
    }),
    [
      items,
      adaptedColumnDefinitions,
      rowSelectionMode,
      selectedItems,
      onSelectedItemsChangeAdapter,
      isItemDisabled,
      sortColumn,
      sortDescending,
      onSortChange,
      columnDisplay,
    ]
  );

  return {
    dataTableProps,
    clientPaginationProps,
  };
};

export default useInteractiveTable;
