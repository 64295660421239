import React, { useCallback, useMemo } from 'react';

import useDatePickerDate from '../useDatePickerDate';
import {
  CalendarTranslations,
  defaultTranslations,
} from '../Calendar/CalendarContext';

import { SystemDateRangePicker, DateRange } from './SystemDateRangePicker';

// Re-export to match component name.
export type DateRangePickerTranslations = CalendarTranslations;

export interface DateStringRange {
  start: string;
  end: string;
}

export interface PredefinedRange extends DateStringRange {
  label: string;
}

export interface DateRangePickerProps {
  value: DateStringRange | null;
  translations?: CalendarTranslations;
  isClearable?: boolean;
  isDisabled?: boolean;
  timeZone?: string;
  ranges?: Array<PredefinedRange>;
  onChange: (value: DateStringRange | null) => void;
}

const defaultRanges: Array<PredefinedRange> = [];

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  timeZone,
  translations = defaultTranslations,
  isClearable = true,
  isDisabled = false,
  ranges = defaultRanges,
  onChange,
}) => {
  const { toSystemDate, fromSystemDate } = useDatePickerDate(timeZone, false);

  const valueWithDates = useMemo(() => {
    const start = value ? toSystemDate(value.start) : null;
    const end = value ? toSystemDate(value.end) : null;

    return start && end ? { start, end } : null;
  }, [value, toSystemDate]);

  const rangesWithDates = useMemo(
    () =>
      ranges.map((range) => ({
        label: range.label,
        start: toSystemDate(range.start)!,
        end: toSystemDate(range.end)!,
      })),
    [ranges, toSystemDate]
  );

  const handleChange = useCallback(
    (range: DateRange | null) => {
      const start = range ? fromSystemDate(range.start) : null;
      const end = range ? fromSystemDate(range.end) : null;
      onChange(start && end ? { start, end } : null);
    },
    [onChange, fromSystemDate]
  );

  return (
    <SystemDateRangePicker
      value={valueWithDates}
      onChange={handleChange}
      translations={translations}
      isClearable={isClearable}
      isDisabled={isDisabled}
      ranges={rangesWithDates}
    />
  );
};
