import React from 'react';

export const SortIcon: React.FC = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      d="M3.21101 6.74995H8.78914C9.2907 6.74995 9.54148 7.35698 9.18757 7.71089L6.39851 10.5C6.1782 10.7203 5.82195 10.7203 5.60398 10.5L2.81257 7.71089C2.45867 7.35698 2.70945 6.74995 3.21101 6.74995ZM9.18757 4.28901L6.39851 1.49995C6.1782 1.27964 5.82195 1.27964 5.60398 1.49995L2.81257 4.28901C2.45867 4.64292 2.70945 5.24995 3.21101 5.24995H8.78914C9.2907 5.24995 9.54148 4.64292 9.18757 4.28901Z"
      fill="currentColor"
    />
  </svg>
);
