import React from 'react';

import { Navigate, Params } from 'react-router-dom';

import useNamedLink from '@/hooks/navigation/useNamedLink';

export interface NamedNavigateProps {
  name: string;
  params?: Params;
  queryParams?: Record<string, any>;
  replace?: boolean;
}

export const NamedNavigate: React.FC<NamedNavigateProps> = ({
  name,
  params,
  queryParams,
  replace,
}) => {
  const link = useNamedLink(name, params, queryParams);

  return <Navigate to={link} replace={replace} />;
};
