import React, { ComponentProps } from 'react';

import { forwardRef } from '@chakra-ui/react';
import { PencilIcon } from 'lucide-react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { IconButton } from '@m3ter-com/ui-components';

export type EntityEditButtonProps = Omit<
  ComponentProps<typeof IconButton>,
  'aria-label' | 'icon'
>;

export const EntityEditButton = forwardRef<EntityEditButtonProps, 'button'>(
  (props, ref) => {
    const { t } = useTranslation();

    return (
      <IconButton
        ref={ref}
        aria-label={t('common:edit')}
        size="sm"
        icon={<PencilIcon size={16} />}
        {...props}
      />
    );
  }
);
