import React from 'react';

import { Code, SimpleGrid } from '@chakra-ui/react';

import { DataType, Destination } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { CopyToClipboard, KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard';

export interface DestinationDetailsProps {
  data: Destination;
}

export const DestinationDetails: React.FC<DestinationDetailsProps> = ({
  data: destination,
}) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      showEditButton
      data={destination}
      dataType={DataType.Destination}
      showMetadata={false}
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue label={t('forms:labels.name')} value={destination.name} />
          <KeyValue label={t('forms:labels.code')} value={destination.code} />
          <KeyValue
            label={t('forms:labels.description')}
            value={destination.description || '-'}
          />
          <KeyValue
            label={t('forms:labels.url')}
            value={
              destination.url && (
                <CopyToClipboard value={destination.url}>
                  {destination.url}
                </CopyToClipboard>
              )
            }
          />
          {!!destination.credentials && (
            <React.Fragment>
              <KeyValue
                label={t('features:integrations.credentialType')}
                value={destination.credentials.type}
              />
              <KeyValue
                label={t('features:integrations.destination')}
                value={destination.credentials.destination}
              />
              <KeyValue
                label={t('features:integrations.destinationId')}
                value={
                  <CopyToClipboard
                    value={destination.credentials.destinationId}
                  >
                    <Code>{destination.credentials.destinationId}</Code>
                  </CopyToClipboard>
                }
              />
              <KeyValue
                label={t('features:integrations.apiKey')}
                value={
                  <CopyToClipboard value={destination.credentials.apiKey}>
                    <Code>{destination.credentials.apiKey}</Code>
                  </CopyToClipboard>
                }
              />
              <KeyValue
                label={t('features:integrations.apiSecret')}
                value={destination.credentials.secret}
              />
            </React.Fragment>
          )}
        </SimpleGrid>
      }
    />
  );
};
