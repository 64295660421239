import { EventTypes, Id, ListResponse, M3terEvent } from '../types';

import { getOrganizationPath } from '../util/path';
import { get, post, put } from '../client';

interface EvaluateNotificationCalculationRequestBody {
  eventName: string;
  calculation: string;
}

interface EvaluateNotificationCalculationResponse {
  success: boolean;
  errorMessage?: string;
}

interface DownloadResponse {
  url: string;
}

export const getEventTypes = (organizationId: Id) =>
  get({
    path: getOrganizationPath('/events/types'),
    pathParams: { organizationId },
  }) as Promise<EventTypes>;

export const evaluationNotificationCalculation = (
  organizationId: Id,
  body: EvaluateNotificationCalculationRequestBody
) =>
  post({
    path: getOrganizationPath('/notifications/evaluate'),
    pathParams: { organizationId },
    body,
  }) as Promise<EvaluateNotificationCalculationResponse>;

export const actionEvents = (organizationId: Id, ids: Array<Id>) =>
  put({
    path: getOrganizationPath('/events/action'),
    pathParams: { organizationId },
    queryParams: { ids },
  }) as Promise<ListResponse<M3terEvent>>;

// This function expects a full URL, which the client will use without prepending the base URL.
export const getEventDownloadUrl = async (url: string) => {
  const response = await (get({ path: url }) as Promise<DownloadResponse>);
  return response.url;
};
