import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ServiceUserCredential } from '@m3ter-com/m3ter-api';

export interface ServiceUserCredentialsContextValues {
  newServiceUserCredentials?: ServiceUserCredential;
  setNewServiceUserCredentials: (credentials?: ServiceUserCredential) => void;
}

const ServiceUserCredentialsContext =
  createContext<ServiceUserCredentialsContextValues | null>(null);

export const ServiceUserCredentialsContextProvider: React.FC<
  PropsWithChildren
> = ({ children }) => {
  const [newServiceUserCredentials, setNewServiceUserCredentials] = useState<
    ServiceUserCredential | undefined
  >();

  const value = useMemo<ServiceUserCredentialsContextValues>(
    () => ({
      newServiceUserCredentials,
      setNewServiceUserCredentials,
    }),
    [newServiceUserCredentials, setNewServiceUserCredentials]
  );

  return (
    <ServiceUserCredentialsContext.Provider value={value}>
      {children}
    </ServiceUserCredentialsContext.Provider>
  );
};

export const useServiceUserCredentialsContext = () =>
  useContext(ServiceUserCredentialsContext)!;
