import React from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { UserGroupCreateForm } from '@/components/features/access/user-group/UserGroupCreateForm';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import useUserGroupCreate from '@/hooks/features/access/useUserGroupCreate';

export const UserGroupsCreateRoute: React.FC = () => {
  const { t } = useTranslation();
  const { onCancel, onSave, isSaving, error } = useUserGroupCreate();

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:create')} />
      {error && <ErrorAlert error={error} />}
      <UserGroupCreateForm
        isSaving={isSaving}
        onSave={onSave}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};
