import React, { useMemo } from 'react';

import { Controller, get, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Skeleton,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';

import { findAllByKey } from '../../../utils/object';
import { JsonEditor, JsonEditorProps } from '../JsonEditor/JsonEditor';

export interface FormJsonEditorProps
  extends Omit<JsonEditorProps, 'value' | 'onChange'> {
  name: string;
  label: string;
  hideLabel?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
}

export const FormJsonEditor: React.FC<FormJsonEditorProps> = ({
  name,
  label,
  isDisabled = false,
  isLoading = false,
  isRequired = false,
  hideLabel = false,
  ...jsonEditorProps
}) => {
  // Since this editor will be used to allow editing of an entire object,
  // we need to access the current form state's errors and find any error
  // messages that are tied to any field at any level of the object this
  // JSON input represents. Otherwise, the consumer would be forced to
  // tie all validation to the root property that this input is representing,
  // rather than validating child properties like would usually be done when
  // validating a multi-field form.
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();
  const errors = useMemo<Array<string>>(() => {
    if (!formErrors) {
      return [];
    }

    const fieldErrors = get(formErrors, name);
    if (!fieldErrors) {
      return [];
    }

    return findAllByKey<string>(fieldErrors, 'message');
  }, [formErrors, name]);

  const formLabel = <FormLabel>{label}</FormLabel>;

  return (
    <FormControl
      id={name}
      isInvalid={errors.length > 0}
      isDisabled={isDisabled}
      isRequired={isRequired}
    >
      {hideLabel ? <VisuallyHidden>{formLabel}</VisuallyHidden> : formLabel}
      <Skeleton isLoaded={!isLoading} w="100%">
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => (
            <JsonEditor
              value={value}
              onChange={onChange}
              isDisabled={isDisabled}
              {...jsonEditorProps}
            />
          )}
        />
      </Skeleton>
      {errors.length > 0 && (
        <VStack alignItems="flex-start" spacing={0}>
          {errors.map((error, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <FormErrorMessage key={index}>{error}</FormErrorMessage>
          ))}
        </VStack>
      )}
    </FormControl>
  );
};
