import React from 'react';

import { DataType, ExportDestination } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { ExportDestinationsRoute } from '@/components/routes/data-exports/export-destinations/ExportDestinationsRoute';

const LazyExportDestinationsDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "ExportDestinationsDetails" */ '@/components/routes/data-exports/export-destinations/ExportDestinationsDetailsRoute'
  ).then((module) => ({ default: module.ExportDestinationsDetailsRoute }))
);

const LazyExportDestinationsCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "ExportDestinationsCreate" */ '@/components/routes/data-exports/export-destinations/ExportDestinationsCreateRoute'
  ).then((module) => ({ default: module.ExportDestinationsCreateRoute }))
);

const LazyExportDestinationsEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "ExportDestinationsEdit" */ '@/components/routes/data-exports/export-destinations/ExportDestinationsEditRoute'
  ).then((module) => ({ default: module.ExportDestinationsEditRoute }))
);

export default (): NamedRoute =>
  createCrudRoute<ExportDestination>({
    path: 'destinations',
    dataType: DataType.ExportDestination,
    element: <ExportDestinationsRoute />,
    create: <LazyExportDestinationsCreateRoute />,
    edit: <LazyExportDestinationsEditRoute />,
    details: <LazyExportDestinationsDetailsRoute />,
  });
