import React from 'react';

import { SimpleGrid, VStack } from '@chakra-ui/react';

import { DataType, NotificationRule } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge';
import { DetailsCard } from '@/components/common/data/DetailsCard';

import { NotificationRuleEvents } from './NotificationRuleEvents';
import { NotificationRuleDestinations } from './NotificationRuleDestinations';

export interface NotificationRuleDetailsProps {
  data: NotificationRule;
}

export const NotificationRuleDetails: React.FC<
  NotificationRuleDetailsProps
> = ({ data: notificationRule }) => {
  const { id } = notificationRule;
  const { t } = useTranslation();

  return (
    <DetailsCard
      showEditButton
      data={notificationRule}
      dataType={DataType.NotificationRule}
      showMetadata={false}
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue
            label={t('forms:labels.name')}
            value={notificationRule.name}
          />
          <KeyValue
            label={t('forms:labels.event')}
            value={notificationRule.eventName}
          />
          <KeyValue
            label={t('forms:labels.description')}
            value={notificationRule.description}
          />
          <KeyValue
            label={t('forms:labels.status')}
            value={
              <ActiveStatusBadge
                active={notificationRule.active}
                inactiveLabel={t('common:inactive')}
                ml={0}
              />
            }
          />
          <KeyValue
            label={t('features:notifications.alwaysFireNotification')}
            value={
              notificationRule.alwaysFireEvent
                ? t('common:yes')
                : t('common:no')
            }
          />
          <KeyValue
            label={t('forms:labels.calculation')}
            value={notificationRule.calculation || '-'}
          />
        </SimpleGrid>
      }
    >
      <VStack spacing={4} alignItems="stretch">
        <NotificationRuleEvents notificationRuleId={id} />
        <NotificationRuleDestinations notificationRuleId={id} />
      </VStack>
    </DetailsCard>
  );
};
