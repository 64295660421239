import React, { useEffect, useCallback, useState, ReactElement } from 'react';

import { Entity, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { ListSearchOperator, OtherListIds } from '@/types/lists';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { CrudList } from '@/components/common/crud/CrudList';

import { EntitySelectModalBaseProps } from './types';
import { EntitySelectModalWrapper } from './EntitySelectModalWrapper';

export type EntityMultiSelectModalProps<E extends Entity> =
  EntitySelectModalBaseProps<E> & {
    onConfirm: (newEntityIds: Array<Id>) => void;
    selected: Array<Id>;
    listHeader?: React.ReactNode;
  };

export function EntityMultiSelectModal<E extends Entity = Entity>({
  dataType,
  isOpen,
  onClose,
  onConfirm,
  selected,
  listHeader,
  ...crudListProps
}: EntityMultiSelectModalProps<E>): ReactElement<any, any> {
  const { t } = useTranslation();

  // We keep an internal state of the selected entities for a few reasons:
  // 1. It means the modal can be opened and selections can be made without
  // calling onSelectChange every time a row of the crud list is selected /
  // de-selected.
  // 2. It also means the user can cancel out of the modal after making changes,
  // without the external selected value being updated.
  const [internalSelected, setInternalSelected] = useState<Array<Id>>(selected);
  useEffect(() => {
    setInternalSelected(selected);
  }, [selected]);

  const handleConfirm = useCallback(() => {
    onConfirm(internalSelected);
    onClose();
    setInternalSelected(selected);
  }, [onConfirm, internalSelected, onClose, selected]);
  const handleClose = useCallback(() => {
    onClose();
    setInternalSelected(selected);
  }, [onClose, selected]);

  const entityNamings = useEntityNamings(dataType);

  return (
    <EntitySelectModalWrapper
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={handleConfirm}
      title={t('forms:labels.selectX', { x: entityNamings.pluralLower })}
    >
      <React.Fragment>
        {listHeader}
        <CrudList
          {...crudListProps}
          dataType={dataType}
          listId={OtherListIds.EntitySelectModal}
          onSelectedItemsChange={setInternalSelected}
          selectedItems={internalSelected}
          selectionType="multi"
          searchOperator={ListSearchOperator.Or}
        />
      </React.Fragment>
    </EntitySelectModalWrapper>
  );
}
