import React, { useEffect, useMemo } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';

import { DataType, Id } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, AlertLink, SelectOption } from '@m3ter-com/ui-components';
import { FormField, FormSelect } from '@m3ter-com/console-core/components';

import {
  FormEntitySelect,
  DataTypeFormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';

export interface ProductPlanTemplateFieldsProps {
  planTemplateOptions: Array<SelectOption>;
  isNoPlanTemplatesForProduct?: boolean;
}

export const ProductPlanTemplateFields: React.FC<
  ProductPlanTemplateFieldsProps
> = ({ planTemplateOptions, isNoPlanTemplatesForProduct }) => {
  const { t } = useTranslation();

  const productId: Id | undefined = useWatch({ name: 'productId' });
  const { setValue } = useFormContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const createPlanTemplateQueryParams = useMemo(
    () => ({ productId }),
    [productId]
  );

  // Reset the plan template ID when the options change.
  useEffect(() => {
    setValue('planTemplateId', null);
  }, [planTemplateOptions, setValue]);

  useEffect(() => {
    let params = {};

    const returnPath = searchParams.get('returnPath');
    if (returnPath) {
      params = { returnPath };
    }

    if (productId) {
      params = { ...params, productId };
    }

    setSearchParams(params, { replace: true });
  }, [productId, searchParams, setSearchParams]);

  return (
    <React.Fragment>
      <Alert status="info">
        <Trans
          t={t as TFunction}
          i18nKey="features:plans.planTemplateHelpText"
          components={{
            createPlanTemplateLink: (
              <AlertLink
                as={CrudCreateLink}
                addReturnPath
                dataType={DataType.PlanTemplate}
                queryParams={createPlanTemplateQueryParams}
              />
            ),
          }}
        />
      </Alert>
      <FormField
        isRequired
        name="productId"
        label={t('common:product')}
        control={FormEntitySelect as DataTypeFormEntitySelect<DataType.Product>}
        dataType={DataType.Product}
        accessor="name"
        detailAccessor="code"
      />
      <FormField
        isRequired
        isDisabled={isNoPlanTemplatesForProduct}
        name="planTemplateId"
        label={t('common:planTemplate')}
        control={FormSelect}
        options={planTemplateOptions}
        alert={
          productId &&
          isNoPlanTemplatesForProduct && (
            <Alert status="info">
              <Trans
                t={t as TFunction}
                i18nKey="features:plans.noPlanTemplatesForProduct"
                components={{
                  createPlanTemplateLink: (
                    <AlertLink
                      as={CrudCreateLink}
                      addReturnPath
                      dataType={DataType.PlanTemplate}
                      queryParams={createPlanTemplateQueryParams}
                    />
                  ),
                }}
              />
            </Alert>
          )
        }
      />
    </React.Fragment>
  );
};
