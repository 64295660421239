import { useMemo } from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

export interface EntityNamings {
  plural: string;
  pluralLower: string;
  singular: string;
  singularLower: string;
}

const useEntityNamings = (dataType: DataType) => {
  const { t } = useTranslation();

  const plural = t(`common:${dataType}s`);
  const pluralLower = plural.toLowerCase();
  const singular = t(`common:${dataType}`);
  const singularLower = singular.toLowerCase();

  return useMemo<EntityNamings>(
    () => ({
      plural,
      pluralLower,
      singular,
      singularLower,
    }),
    [plural, pluralLower, singular, singularLower]
  );
};

export default useEntityNamings;
