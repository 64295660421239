import { spawn } from 'redux-saga/effects';

import accountsSaga from './accounts/accounts.saga';
import analyticsSaga from './analytics/analytics.saga';
import billingSaga from './billing/billing.saga';
import eventsNotificationsSaga from './events-notifications/eventsNotifications.saga';
import flagsSaga from './flags/flags.saga';
import integrationsSaga from './integrations/integrations.saga';
import pricingSaga from './pricing/pricing.saga';
import usageSaga from './usage/usage.saga';

export default function* featuresSaga() {
  yield spawn(accountsSaga);
  yield spawn(analyticsSaga);
  yield spawn(billingSaga);
  yield spawn(eventsNotificationsSaga);
  yield spawn(flagsSaga);
  yield spawn(integrationsSaga);
  yield spawn(pricingSaga);
  yield spawn(usageSaga);
}
