import { Id } from '../types';

import { getOrganizationPath } from '../util/path';
import { put } from '../client';

interface ResendTemporaryPasswordResponse {
  Message: string;
}

export const resendTemporaryPassword = (organizationId: Id, userId: Id) =>
  put({
    path: getOrganizationPath('/users/:userId/password/resend'),
    pathParams: { organizationId, userId },
  }) as Promise<ResendTemporaryPasswordResponse>;
