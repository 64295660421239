import React, { ReactElement, useMemo } from 'react';

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Table,
  Text,
  Thead,
  Td,
  Tr,
  Th,
  Tbody,
  Flex,
} from '@chakra-ui/react';

import { DataType, DataTypeToEntity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';
import { isActive, isPlanPricing } from '@m3ter-com/console-core/utils';

import { PricingDataType } from '@/types/data';

import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import useEntityNamings from '@/hooks/util/useEntityNamings';

import { PricingScheduleRow } from './PricingScheduleRow';

export interface PricingScheduleProps<DT extends PricingDataType> {
  pricings: Array<DataTypeToEntity[DT]>;
  currencyCode: string;
  isForPlan: boolean;
  createPricingQueryParams?: Record<string, any>;
  dataType: DT;
}

export function PricingSchedule<DT extends PricingDataType>({
  pricings,
  createPricingQueryParams,
  currencyCode,
  isForPlan,
  dataType,
}: PricingScheduleProps<DT>): ReactElement<any, any> | null {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(dataType);

  const isStandardPricing = dataType === DataType.Pricing;

  // When displaying plan template pricing it shouldn't show as active if
  // there is any active plan pricing that would override it.
  const anyActivePlanPricing = useMemo(
    () =>
      isForPlan &&
      pricings.some((pricing) => isPlanPricing(pricing) && isActive(pricing)),
    [isForPlan, pricings]
  );

  return (
    <Card>
      <CardHeader>
        <Flex justify="space-between" alignItems="center">
          <Heading size="md">
            {t('features:pricing.pricingEntitySchedule', {
              entityName: entityNamings.singular,
            })}
          </Heading>
          <Button
            intent="primary"
            size="sm"
            as={CrudCreateLink}
            addReturnPath
            dataType={dataType}
            queryParams={createPricingQueryParams}
          >
            {t('forms:buttons.createEntity', {
              entityName: entityNamings.singularLower,
            })}
          </Button>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant="unstyled">
          <Thead color="chakra-subtle-text">
            <Tr>
              <Th w="24px" />
              <Th>{t('common:start')}</Th>
              <Th>{t('common:end')}</Th>
              <Th />
              {isForPlan && <Th>{t('common:from')}</Th>}
              <Th>{entityNamings.singular}</Th>
              {isStandardPricing && <Th>{t('forms:labels.minimumSpend')}</Th>}
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {pricings.length > 0 ? (
              pricings.map((pricing) => (
                <PricingScheduleRow
                  key={pricing.id}
                  pricing={pricing}
                  currencyCode={currencyCode}
                  anyActivePlanPricing={anyActivePlanPricing}
                  isForPlan={isForPlan}
                  dataType={dataType}
                />
              ))
            ) : (
              <Tr data-testid="empty-pricing-content-row">
                <Td colSpan={isForPlan ? 8 : 7}>
                  <Text textAlign="center">
                    {t('errors:generic.noDataToDisplay', {
                      entityName: entityNamings.pluralLower,
                    })}
                  </Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
