import React, { useMemo } from 'react';

import { useWatch } from 'react-hook-form';
import orderBy from 'lodash/orderBy';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack, SelectOption } from '@m3ter-com/ui-components';
import {
  FormAdvancedNumberInput,
  FormField,
  FormInput,
  FormSection,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import usePlanTemplates from '@/hooks/features/planTemplates/usePlanTemplates';
import { BillInAdvanceField } from '@/components/features/pricing/BillInAdvanceField';
import { FormCustomFieldsEditorDefaults } from '@/components/forms/FormCustomFieldsEditorDefaults';
import useOrg from '@/hooks/data/crud/useOrg';
import { getBillInAdvanceDescription } from '@/util/billing';

import { PlanTemplateLink } from './PlanTemplateLink';
import { ProductPlanTemplateFields } from './ProductPlanTemplateFields';
import { PlanTemplateValue } from './PlanTemplateValue';
import { TransactionalPricingFields } from './TransactionalPricingFields';

export interface PlanFormFieldsProps {
  isEdit: boolean;
  includeCustomFields?: boolean;
}

/**
 * Plan form fields shared between plan create / edit and account custom plan create.
 *
 * Renders the product / plan template fields for a create action and the plan template
 * link for an edit action.
 */
export const PlanFormFields: React.FC<PlanFormFieldsProps> = ({
  isEdit,
  includeCustomFields = true,
}) => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();
  const { orgConfig } = useOrg();

  const { isLoaded, planTemplates } = usePlanTemplates();
  const productId: string | undefined = useWatch({ name: 'productId' });
  // We always need to find the selected plan template, to show a link to it when editing
  // and show the values being overridden.
  const planTemplateId: string | undefined = useWatch({
    name: 'planTemplateId',
  });

  const selectedPlanTemplate = useMemo(
    () =>
      planTemplates.find((planTemplate) => planTemplate.id === planTemplateId),
    [planTemplateId, planTemplates]
  );

  const planTemplateOptions = useMemo(
    () =>
      orderBy(
        planTemplates.reduce((options, planTemplate) => {
          if (planTemplate.productId === productId) {
            options.push({ value: planTemplate.id, label: planTemplate.name });
          }
          return options;
        }, new Array<SelectOption>()),
        'label'
      ),
    [planTemplates, productId]
  );

  const isNoPlanTemplatesForProduct =
    isLoaded && planTemplateOptions.length === 0;

  const isNoPlanTemaplateSelected =
    !isEdit && (isNoPlanTemplatesForProduct || !selectedPlanTemplate);

  return (
    <FormStack mb={4}>
      <FormSection
        heading={t('common:entityDetails', { entityName: t('common:plan') })}
      >
        {isEdit ? (
          <PlanTemplateLink planTemplate={selectedPlanTemplate} />
        ) : (
          <ProductPlanTemplateFields
            planTemplateOptions={planTemplateOptions}
            isNoPlanTemplatesForProduct={isNoPlanTemplatesForProduct}
          />
        )}
        <NameCodeFields isDisabled={isNoPlanTemplatesForProduct} />
        {isFeatureEnabled(Feature.TransactionPricing) && (
          <TransactionalPricingFields />
        )}
      </FormSection>
      <FormSection isOptional heading={t('forms:labels.standingCharge')}>
        <FormField
          name="standingCharge"
          label={t('forms:labels.standingCharge')}
          helpText={
            <PlanTemplateValue
              planTemplate={selectedPlanTemplate}
              fieldName="standingCharge"
            />
          }
          control={FormAdvancedNumberInput}
          isDisabled={isNoPlanTemaplateSelected}
        />
        <FormField
          name="standingChargeBillInAdvance"
          helpText={t('forms:helpText.standingChargeBilling')}
          label={t('forms:labels.standingChargeBilling')}
          control={BillInAdvanceField}
          defaultLabel={t('forms:labels.useConfigFromPlanTemplate', {
            default: getBillInAdvanceDescription(
              selectedPlanTemplate?.standingChargeBillInAdvance ??
                orgConfig.standingChargeBillInAdvance
            ),
          })}
          isDisabled={isNoPlanTemaplateSelected}
        />
        <FormField
          name="standingChargeDescription"
          helpText={t('features:pricing.displayedBillLineItem')}
          label={t('forms:labels.standingChargeDescription')}
          control={FormInput}
          isDisabled={isNoPlanTemaplateSelected}
        />
      </FormSection>
      <FormSection isOptional heading={t('forms:labels.minimumSpend')}>
        <FormField
          name="minimumSpend"
          label={t('forms:labels.productMinimumSpend')}
          helpText={
            <PlanTemplateValue
              planTemplate={selectedPlanTemplate}
              fieldName="minimumSpend"
            />
          }
          control={FormAdvancedNumberInput}
          isDisabled={isNoPlanTemaplateSelected}
        />
        <FormField
          name="minimumSpendBillInAdvance"
          helpText={t('forms:helpText.minimumSpendBilling')}
          label={t('forms:labels.minimumSpendBilling')}
          control={BillInAdvanceField}
          defaultLabel={t('forms:labels.useConfigFromPlanTemplate', {
            default: getBillInAdvanceDescription(
              selectedPlanTemplate?.minimumSpendBillInAdvance ??
                orgConfig.minimumSpendBillInAdvance
            ),
          })}
          isDisabled={isNoPlanTemaplateSelected}
        />
        <FormField
          name="minimumSpendDescription"
          helpText={t('features:pricing.displayedBillLineItem')}
          label={t('forms:labels.minimumSpendDescription')}
          control={FormInput}
          isDisabled={isNoPlanTemaplateSelected}
        />
      </FormSection>
      {includeCustomFields && (
        <FormCustomFieldsEditorDefaults dataType={DataType.Plan} />
      )}
    </FormStack>
  );
};
