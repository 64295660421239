import React from 'react';

import { DataType, ServiceUser } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import serviceUserSchema from '@/validation/serviceUser';
import useEntityNamings from '@/hooks/util/useEntityNamings';

const defaultInitialValues = {};

export interface ServiceUserEditFormProps extends BaseFormProps<ServiceUser> {}

export const ServiceUserEditForm: React.FC<ServiceUserEditFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.ServiceUser);

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={serviceUserSchema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <FormField
            isRequired
            name="name"
            label={t('forms:labels.name')}
            control={FormInput}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t('forms:buttons.updateEntity', {
            entityName: entityNamings.singularLower,
          })}
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
