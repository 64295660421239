import React, { ElementType, PropsWithChildren } from 'react';

import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';

export interface UsageQueryBuilderSectionProps {
  colorScheme: string;
  icon: ElementType;
  title: string;
}

export const UsageQueryBuilderSection: React.FC<
  PropsWithChildren<UsageQueryBuilderSectionProps>
> = ({ colorScheme, icon, title, children }) => {
  const color = useColorModeValue(`${colorScheme}.700`, `${colorScheme}.200`);
  const bg = useColorModeValue('gray.100', 'gray.800');

  return (
    <Flex
      bg={bg}
      color={color}
      borderRadius="md"
      p={2}
      alignItems="center"
      gap={2}
    >
      <Icon as={icon} boxSize={6} mx={2} />
      <Box>
        <Text fontWeight="bold" mb={1}>
          {title}
        </Text>
        {children}
      </Box>
    </Flex>
  );
};
