import React, { ComponentProps } from 'react';

import { forwardRef } from '@chakra-ui/react';
import { ListIcon } from 'lucide-react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { IconButton } from '@m3ter-com/ui-components';

export type EntityDetailsButtonProps = Omit<
  ComponentProps<typeof IconButton>,
  'aria-label' | 'icon'
>;

export const EntityDetailsButton = forwardRef<
  EntityDetailsButtonProps,
  'button'
>((props, ref) => {
  const { t } = useTranslation();

  return (
    <IconButton
      ref={ref}
      aria-label={t('common:details')}
      size="sm"
      icon={<ListIcon size={16} />}
      {...props}
    />
  );
});
