import React, { useCallback } from 'react';

import {
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Radio,
  RadioGroup,
  VStack,
  VisuallyHidden,
} from '@chakra-ui/react';
import { XIcon } from 'lucide-react';

import {
  PermissionEffect,
  PermissionPolicyStatement,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { IconButton, MultiSelect, Select } from '@m3ter-com/ui-components';

import { NestedCheckbox } from '@/components/forms/NestedCheckbox';
import { PermissionPolicyEditorOptions } from '@/components/forms/PermissionPolicyEditor';

export interface PermissionStatementFieldRowProps {
  hideLabels: boolean;
  options: PermissionPolicyEditorOptions;
  value: PermissionPolicyStatement;
  canRemove: boolean;
  onChange: (value: PermissionPolicyStatement) => void;
  onRemove: () => void;
}

enum FilterType {
  All = 'all',
  Values = 'values',
}

const emptyArray: Array<never> = [];

const selectAllValue = '*';

export const PermissionStatementFieldRow: React.FC<
  PermissionStatementFieldRowProps
> = ({ hideLabels, options, value, canRemove, onChange, onRemove }) => {
  const { t } = useTranslation();
  const { effects, actions, resources } = options;

  const onEffectChanged = useCallback(
    (effect: PermissionEffect | null) => {
      if (effect) {
        onChange({ ...value, effect });
      }
    },
    [onChange, value]
  );

  const onActionChanged = useCallback(
    (action: Array<string> | null) => {
      if (action) {
        onChange({ ...value, action });
      }
    },
    [onChange, value]
  );

  const onResourceChanged = useCallback(
    (resource: Array<string> | null) => {
      if (resource) {
        onChange({ ...value, resource });
      }
    },
    [onChange, value]
  );

  const filterType =
    value.resource[0] === selectAllValue ? FilterType.All : FilterType.Values;

  const handleRadioChange = useCallback(
    (newOption: FilterType) => {
      if (newOption === FilterType.All) {
        onResourceChanged([selectAllValue]);
      } else {
        onResourceChanged(emptyArray);
      }
    },
    [onResourceChanged]
  );

  const effectsFormLabel = (
    <FormLabel>{t('features:access.effects')}</FormLabel>
  );
  const actionsFormLabel = (
    <FormLabel>{t('features:access.actions')}</FormLabel>
  );
  const resourcesFormLabel = (
    <FormLabel>{t('features:access.resources')}</FormLabel>
  );

  return (
    <Grid
      gap={4}
      gridTemplateColumns="minmax(150px, 400px) auto minmax(min-content, 500px) 40px"
      alignSelf="stretch"
    >
      <FormControl>
        {hideLabels ? (
          <VisuallyHidden>{effectsFormLabel}</VisuallyHidden>
        ) : (
          effectsFormLabel
        )}
        <Select<PermissionEffect>
          value={value.effect}
          onChange={onEffectChanged}
          options={effects}
        />
      </FormControl>
      <FormControl>
        {hideLabels ? (
          <VisuallyHidden>{actionsFormLabel}</VisuallyHidden>
        ) : (
          actionsFormLabel
        )}
        <NestedCheckbox
          value={value.action}
          onChange={onActionChanged}
          options={actions}
          selectAllLabel={t('forms:labels.allX', {
            x: '$t(features:access.actions)',
          })}
        />
      </FormControl>
      <FormControl>
        {hideLabels ? (
          <VisuallyHidden>{resourcesFormLabel}</VisuallyHidden>
        ) : (
          resourcesFormLabel
        )}
        <VStack alignItems="stretch">
          <RadioGroup value={filterType} onChange={handleRadioChange}>
            <HStack>
              <Radio value={FilterType.All}>
                {t('forms:labels.allX', {
                  x: '$t(features:access.resources)',
                })}
              </Radio>
              <Radio value={FilterType.Values}>
                {t('features:access.selectedResources')}
              </Radio>
            </HStack>
          </RadioGroup>
          <MultiSelect<string>
            isSearchable
            searchMatchFrom="any"
            options={resources}
            isDisabled={filterType === FilterType.All}
            value={filterType === FilterType.All ? emptyArray : value.resource}
            placeholder={t('features:statements.addValues')}
            onChange={onResourceChanged}
          />
        </VStack>
      </FormControl>
      {canRemove && (
        <IconButton
          aria-label={t('common:remove')}
          icon={<XIcon />}
          onClick={onRemove}
          mt={hideLabels ? 0 : 8}
        />
      )}
    </Grid>
  );
};
