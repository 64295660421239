import React, { useMemo } from 'react';

import { Spinner } from '@chakra-ui/react';

import { IntegrationConfig } from '@m3ter-com/m3ter-api';

import useQueryString from '@/hooks/navigation/useQueryString';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { useIntegrationConfigSchemaContext } from '@/components/features/integrations/IntegrationConfigSchemaContext';
import { IntegrationConfigForm } from '@/components/features/integrations/IntegrationConfigForm';

interface InterfaceConfigCreateRouteQueryParams {
  destination?: string;
  entityType?: string;
}

export const IntegrationConfigCreateRoute: React.FC = () => {
  const { destination, entityType } =
    useQueryString<InterfaceConfigCreateRouteQueryParams>();
  const initialValues = useMemo(
    () => ({
      configData: {},
      destination,
      entityType,
    }),
    [destination, entityType]
  );

  const { configSchemaError, isLoadingConfigSchema } =
    useIntegrationConfigSchemaContext();

  if (isLoadingConfigSchema) {
    return <Spinner />;
  }

  if (configSchemaError) {
    return <ErrorAlert error={configSchemaError} />;
  }

  return (
    <CrudCreate<IntegrationConfig>
      form={IntegrationConfigForm}
      initialValues={initialValues}
    />
  );
};
