import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  update,
  PathParams,
  DataType,
  DataTypeToEntity,
} from '@m3ter-com/m3ter-api';

import { dataUpdated } from '@/store/data/data';
import useNotification, {
  NotificationOptions,
} from '@/hooks/util/useNotification';
import useOrg from '@/hooks/data/crud/useOrg';

interface UpdateMutationData<DT extends DataType> {
  actionName?: string;
  failureNotification?: NotificationOptions;
  newEntity: DataTypeToEntity[DT];
  pathParams?: PathParams;
  returnPath?: string;
  successNotification?: NotificationOptions;
}

const useEntityUpdateMutation = <DT extends DataType>(dataType: DT) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useNotification();
  const { currentOrgId: organizationId } = useOrg();

  const queryClient = useQueryClient();

  const {
    error,
    isPending: isSaving,
    mutate: updateEntity,
  } = useMutation({
    mutationFn: async (updateData: UpdateMutationData<DT>) => {
      const pathParams = {
        organizationId,
        ...updateData.pathParams,
      };
      return update({
        actionName: updateData.actionName,
        data: updateData.newEntity,
        dataType,
        id: updateData.newEntity.id,
        pathParams,
      });
    },
    onError: (_error, updateData) => {
      if (updateData.failureNotification) {
        toast(updateData.failureNotification);
      }
    },
    onSuccess: (updatedEntity, updateData) => {
      dispatch(dataUpdated(dataType, [updatedEntity]));
      queryClient.invalidateQueries({ queryKey: [dataType] });
      if (updateData.successNotification) {
        toast(updateData.successNotification);
      }
      if (updateData.returnPath) {
        navigate(updateData.returnPath);
      }
    },
  });

  return {
    error,
    isSaving,
    updateEntity,
  };
};

export default useEntityUpdateMutation;
