import React from 'react';

import { DataType, ExportJob } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { ExportJobsRoute } from '@/components/routes/data-exports/export-jobs/ExportJobsRoute';
import { ExportJobsListRoute } from '@/components/routes/data-exports/export-jobs/ExportJobsListRoute';

export default (): NamedRoute =>
  createCrudRoute<ExportJob>({
    path: 'export-jobs',
    dataType: DataType.ExportJob,
    element: <ExportJobsRoute />,
    list: <ExportJobsListRoute />,
  });
