import uniqBy from 'lodash/uniqBy';

import {
  IntegrationConfig,
  IntegrationConfigDestinationSchema,
  IntegrationConfigSchema,
  IntegrationRun,
  IntegrationRunLogEntry,
  IntegrationRunLogEntryType,
  IntegrationRunLogHttpEntry,
  IntegrationRunLogMessageEntry,
} from '@m3ter-com/m3ter-api';

import { isLocalOrAlpha } from '@/config';

export const getAvailableIntegrationDestinationSchemas = (
  integrationConfigSchema: IntegrationConfigSchema
): Array<IntegrationConfigDestinationSchema> => {
  // Integrations for some entity types/ destinations are a WIP and are marked as such with a
  // 'released' field.
  // We only show those as options when running the console locally or in alpha.
  // Destinations are also repeated for each supported entity type so we de-duplicate by the
  // 'destination' field.
  const showUnreleased = isLocalOrAlpha();
  return uniqBy(
    Object.values(integrationConfigSchema.supportedEntities)
      .filter((entitySchema) => entitySchema.released || showUnreleased)
      .flatMap((entitySchema) => entitySchema.destinations)
      .filter(
        (destinationSchema) =>
          destinationSchema.destination.toLowerCase() !== 'webhook' &&
          (destinationSchema.released || showUnreleased)
      ),
    'destination'
  );
};

export const integrationAccessor = (integration: IntegrationConfig) =>
  integration.name || '-';

export const integrationDetailsAccessor = (integration: IntegrationConfig) =>
  `${integration.entityType} - ${integration.destination}`;

export const integrationRunAccessor = (integrationRun: IntegrationRun) => {
  let result = integrationRun.entityType;
  if (integrationRun.entityId) {
    result += ` (${integrationRun.entityId})`;
  }
  result += ` - ${integrationRun.destination}`;
  return result;
};

export const isIntegrationRunLogHttpEntry = (
  runLogEntry: IntegrationRunLogEntry
): runLogEntry is IntegrationRunLogHttpEntry =>
  runLogEntry.type === IntegrationRunLogEntryType.Http;

export const isIntegrationRunLogMessageEntry = (
  runLogEntry: IntegrationRunLogEntry
): runLogEntry is IntegrationRunLogMessageEntry =>
  runLogEntry.type === IntegrationRunLogEntryType.Message;
