import React from 'react';

import { Pagination } from '@m3ter-com/ui-components';

import { useCrudListContext } from './CrudListContext';

export const CrudListPagination: React.FC = () => {
  const {
    currentPage,
    hasMore,
    isLoading,
    knownPageCount,
    loadNextListPage,
    loadSpecificListPage,
  } = useCrudListContext();

  return (
    <Pagination
      currentPage={currentPage}
      isDisabled={isLoading}
      hasMore={hasMore}
      pageCount={knownPageCount}
      onChange={loadSpecificListPage}
      onNext={loadNextListPage}
    />
  );
};
