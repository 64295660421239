import React from 'react';

import { DataType, UserAdmin } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyUserAdminRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UserAdminRoute" */ '@/components/routes/admin/users/UserAdminRoute'
  ).then((module) => ({ default: module.UserAdminRoute }))
);

const LazyUserAdminListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UserAdminListRoute" */ '@/components/routes/admin/users/UserAdminListRoute'
  ).then((module) => ({ default: module.UserAdminListRoute }))
);

const LazyUserAdminCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UserAdminCreateRoute" */ '@/components/routes/admin/users/UserAdminCreateRoute'
  ).then((module) => ({ default: module.UserAdminCreateRoute }))
);

const LazyUserAdminEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UserAdminEditRoute" */ '@/components/routes/admin/users/UserAdminEditRoute'
  ).then((module) => ({ default: module.UserAdminEditRoute }))
);

const LazyUserAdminDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "UserAdminDetailsRoute" */ '@/components/routes/admin/users/UserAdminDetailsRoute'
  ).then((module) => ({ default: module.UserAdminDetailsRoute }))
);

export default () =>
  createCrudRoute<UserAdmin>({
    path: 'users',
    dataType: DataType.UserAdmin,
    element: <LazyUserAdminRoute />,
    list: <LazyUserAdminListRoute />,
    create: <LazyUserAdminCreateRoute />,
    edit: <LazyUserAdminEditRoute />,
    details: <LazyUserAdminDetailsRoute />,
  });
