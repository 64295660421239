import React from 'react';

import { Grid, IconButton } from '@chakra-ui/react';
import { XIcon } from 'lucide-react';

import { PermissionPolicyStatement } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { JsonEditor } from '@m3ter-com/console-core/components';

interface PermissionStatementJsonFieldRowProps {
  value: PermissionPolicyStatement;
  canRemove: boolean;
  onChange: (value: PermissionPolicyStatement) => void;
  onRemove: () => void;
}

export const PermissionStatementJsonFieldRow: React.FC<
  PermissionStatementJsonFieldRowProps
> = ({ value, canRemove, onChange, onRemove }) => {
  const { t } = useTranslation();

  return (
    <Grid w="100%" gridTemplateColumns="1fr 40px" gap={4} alignSelf="stretch">
      <JsonEditor value={value} onChange={onChange} />
      {canRemove && (
        <IconButton
          aria-label={t('common:remove')}
          icon={<XIcon />}
          onClick={onRemove}
        />
      )}
    </Grid>
  );
};
