import React from 'react';

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  SimpleGrid,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';

import { DataType, Entity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, CopyToClipboard, KeyValue } from '@m3ter-com/ui-components';

import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';
import { ExternalMappingCreateLink } from '@/components/common/navigation/ExternalMappingCreateLink';
import { IntegrationDestinationLogo } from '@/components/features/integrations/IntegrationDestinationLogo';

import useEntityMetadata from './useEntityMetadata';

export interface MetadataCardProps {
  data: Entity;
  dataType: DataType;
}

export const MetadataCard: React.FC<MetadataCardProps> = ({
  data,
  dataType,
}) => {
  const { t } = useTranslation();

  const {
    customFields,
    customFieldsKeys,
    entityExternalMappings,
    entityExternalMappingsError,
    entityNamings,
    isLoadingExternalMappings,
  } = useEntityMetadata(data, dataType);

  return (
    <Card>
      <CardHeader>
        <Heading as="h3" size="md">
          {t('common:entityMetadata', { entityName: entityNamings.singular })}
        </Heading>
      </CardHeader>
      <CardBody>
        <VStack
          alignItems="stretch"
          divider={<StackDivider />}
          justifyContent="flex-start"
          spacing={4}
        >
          <Box>
            <Heading as="h4" mb={4} size="sm">
              {t('common:customFields')}
            </Heading>
            <SimpleGrid columns={3} gap={6}>
              {customFieldsKeys.length === 0 && (
                <Text gridColumn="1 / -1">
                  {t('features:customFields.noCustomFieldsDefined')}
                </Text>
              )}
              {customFieldsKeys.map((key) => (
                <KeyValue
                  key={key}
                  label={key}
                  value={
                    <CopyToClipboard value={`${customFields[key]}`}>
                      {customFields[key]}
                    </CopyToClipboard>
                  }
                />
              ))}
            </SimpleGrid>
          </Box>
          <Box>
            <HStack alignItems="center" justifyContent="space-between" mb={4}>
              <Heading as="h4" size="sm">
                {t('common:externalMappings')}
              </Heading>
              <Button
                size="sm"
                as={ExternalMappingCreateLink}
                dataType={dataType}
                entityId={data.id}
              />
            </HStack>
            <LoadingErrorContentSwitch
              error={entityExternalMappingsError}
              isLoading={isLoadingExternalMappings}
            >
              <SimpleGrid columns={3} gap={6}>
                {entityExternalMappings.length === 0 && (
                  <Text gridColumn="1 / -1">
                    {t('features:externalMappings.noExternalMappings')}
                  </Text>
                )}
                {entityExternalMappings.map((mapping) => (
                  <KeyValue
                    key={mapping.id}
                    label={
                      <HStack
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={2}
                      >
                        <IntegrationDestinationLogo
                          destination={mapping.externalSystem}
                          height="20px"
                        />
                        <Text as="span">-</Text>
                        <Text as="span">{mapping.externalTable}</Text>
                      </HStack>
                    }
                    value={
                      <CopyToClipboard value={mapping.externalId}>
                        {mapping.externalId}
                      </CopyToClipboard>
                    }
                  />
                ))}
              </SimpleGrid>
            </LoadingErrorContentSwitch>
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
};
