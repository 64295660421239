import React from 'react';

import {
  BlocksIcon,
  CircleAlertIcon,
  KeyRoundIcon,
  RouteIcon,
  WebhookIcon,
} from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Navigation } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import { NamedNavLink } from '@/components/common/navigation/NamedNavLink';

export const IntegrationsNavigation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Navigation>
      <NamedNavLink
        name={getCrudRouteName(DataType.Integration, CrudRouteType.List)}
        icon={<BlocksIcon />}
      >
        {t('features:integrations.configurations')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(
          DataType.IntegrationCredential,
          CrudRouteType.List
        )}
        icon={<KeyRoundIcon />}
      >
        {t('features:integrations.credentials')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.Destination, CrudRouteType.List)}
        icon={<WebhookIcon />}
      >
        {t('common:destinations')}
      </NamedNavLink>
      <NamedNavLink name="integration.events" icon={<CircleAlertIcon />}>
        {t('features:integrations.integrationEvents')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.ExternalMapping, CrudRouteType.List)}
        icon={<RouteIcon />}
      >
        {t('common:externalMappings')}
      </NamedNavLink>
    </Navigation>
  );
};
