import React from 'react';

import { HardDriveUploadIcon, LogsIcon } from 'lucide-react';

import { Navigation } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { DataType } from '@m3ter-com/m3ter-api';

import { NamedNavLink } from '@/components/common/navigation/NamedNavLink';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';

export const DataExportsNavigation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Navigation>
      <NamedNavLink name="data.export" icon={<HardDriveUploadIcon />}>
        {t('common:dataExport')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.ExportJob, CrudRouteType.List)}
        icon={<LogsIcon />}
      >
        {t('common:exportJobs')}
      </NamedNavLink>
    </Navigation>
  );
};
