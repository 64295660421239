import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { chakra, useMultiStyleConfig } from '@chakra-ui/react';
import { format, isValid } from 'date-fns';

import { Option } from '../../forms/selects/types';
import { Select } from '../../forms/selects/Select/Select';

import { useCalendarContext } from './CalendarContext';

export interface TimeSelectProps {
  isDisabled: boolean;
  value: Date | null;
  onChange: (newDate: Date | null) => void;
}

const TIME_FORMAT_KEY = 'HH:mm';

const HOURS = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];
const MINUTES = ['00', '15', '30', '45'];
const DEFAULT_TIME_OPTIONS = HOURS.flatMap<Option>((hourString) => {
  return MINUTES.map((minutesString) => {
    const timeString = `${hourString}:${minutesString}`;
    return {
      value: timeString,
      label: timeString,
    };
  });
});

export const TimeSelect: React.FC<TimeSelectProps> = ({
  isDisabled,
  value,
  onChange,
}) => {
  const [timeString, setTimeString] = useState('');
  const [customTimeOption, setCustomTimeOption] = useState<
    Option | undefined
  >();
  useEffect(() => {
    if (value === null || !isValid(value)) {
      setTimeString('');
    } else {
      const valueTimeString = format(value, TIME_FORMAT_KEY);
      setTimeString(valueTimeString);
      if (
        DEFAULT_TIME_OPTIONS.some(
          (timeOption) => timeOption.value === valueTimeString
        )
      ) {
        setCustomTimeOption(undefined);
      } else {
        setCustomTimeOption({ value: valueTimeString, label: valueTimeString });
      }
    }
  }, [value]);
  const options = useMemo(
    () =>
      customTimeOption
        ? [customTimeOption, ...DEFAULT_TIME_OPTIONS]
        : DEFAULT_TIME_OPTIONS,
    [customTimeOption]
  );

  const onSelectTime = useCallback(
    (newTimeValue: string | null) => {
      if (!value) {
        return;
      }

      const selectedYear = value.getFullYear();
      const selectedMonth = value.getMonth();
      const selectedDate = value.getDate();
      let newDate: Date;
      if (!newTimeValue) {
        newDate = new Date(selectedYear, selectedMonth, selectedDate, 0, 0);
      } else {
        const [hourString, minutesString] = newTimeValue.split(':');
        newDate = new Date(
          selectedYear,
          selectedMonth,
          selectedDate,
          parseInt(hourString, 10),
          parseInt(minutesString, 10)
        );
      }
      onChange(newDate);
    },
    [value, onChange]
  );

  const { translations } = useCalendarContext();

  const styles = useMultiStyleConfig('DatePicker', {});

  return (
    <chakra.div
      data-testid="date-picker-calendar-time-dropdown"
      sx={styles.timeSelectWrapper}
    >
      <Select
        isDisabled={isDisabled || value === null}
        options={options}
        placeholder={translations.timeDropdownPlaceHolder}
        value={timeString}
        onChange={onSelectTime}
      />
    </chakra.div>
  );
};
