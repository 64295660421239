import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import { useQuery } from '@tanstack/react-query';

import {
  getIntegrationConfigSchema,
  IntegrationConfigSchema,
} from '@m3ter-com/m3ter-api';

import useOrg from '@/hooks/data/crud/useOrg';

export interface IntegrationConfigSchemaContextValues {
  configSchema: IntegrationConfigSchema;
  configSchemaError?: Error | null;
  isLoadingConfigSchema: boolean;
}

const IntegrationConfigSchemaContext =
  createContext<IntegrationConfigSchemaContextValues | null>(null);

export const IntegrationConfigSchemaContextProvider: React.FC<
  PropsWithChildren
> = ({ children }) => {
  const { currentOrgId: organizationId } = useOrg();

  const {
    data: configSchema,
    error: configSchemaError,
    isPending: isLoadingConfigSchema,
  } = useQuery({
    queryKey: ['integration-config-schema', organizationId],
    queryFn: () => getIntegrationConfigSchema(organizationId),
  });

  const value = useMemo<IntegrationConfigSchemaContextValues>(
    () => ({
      configSchema: configSchema || {
        globalConfigurationOptions: [],
        supportedEntities: {},
      },
      configSchemaError,
      isLoadingConfigSchema,
    }),
    [configSchema, configSchemaError, isLoadingConfigSchema]
  );

  return (
    <IntegrationConfigSchemaContext.Provider value={value}>
      {children}
    </IntegrationConfigSchemaContext.Provider>
  );
};

export const useIntegrationConfigSchemaContext = () =>
  useContext(IntegrationConfigSchemaContext)!;
