import React from 'react';

import { SimpleGrid } from '@chakra-ui/react';

import { DataType, Counter } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink';
import { formatEntityUnit } from '@/util/data';

interface ItemCounterDetailsProps {
  data: Counter;
  showEditButton?: boolean;
}

export const ItemCounterDetails: React.FC<ItemCounterDetailsProps> = ({
  data: itemCounter,
  showEditButton = false,
}) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      data={itemCounter}
      dataType={DataType.Counter}
      showEditButton={showEditButton}
      showMetadata={false}
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue label={t('forms:labels.name')} value={itemCounter.name} />
          <KeyValue label={t('forms:labels.code')} value={itemCounter.code} />
          <KeyValueReferenceLink
            label={t('common:product')}
            dataType={DataType.Product}
            id={itemCounter.productId}
            accessor="name"
            defaultValue={t('common:global')}
          />
          <KeyValue
            label={t('forms:labels.unit')}
            value={formatEntityUnit(itemCounter.unit)}
          />
        </SimpleGrid>
      }
    />
  );
};
