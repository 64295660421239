import React from 'react';

import { DataType, IntegrationCredential } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { createCrudRoute } from '@/routes/crud';
import { IntegrationCredentialsRoute } from '@/components/routes/integrations/credentials/IntegrationCredentialsRoute';
import { IntegrationCredentialCreateRoute } from '@/components/routes/integrations/credentials/IntegrationCredentialCreateRoute';

const LazyIntegrationCredentialsList = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationCredentialsList" */ '@/components/features/integrations/IntegrationCredentialsList'
  ).then((module) => ({ default: module.IntegrationCredentialsList }))
);

export default (): NamedRoute =>
  createCrudRoute<IntegrationCredential>({
    path: 'credentials',
    dataType: DataType.IntegrationCredential,
    element: <IntegrationCredentialsRoute />,
    list: <LazyIntegrationCredentialsList />,
    create: <IntegrationCredentialCreateRoute />,
  });
