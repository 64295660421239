import React, { useMemo } from 'react';

import { Box, HStack, Text } from '@chakra-ui/react';
import sortBy from 'lodash/sortBy';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Select, SelectOption } from '@m3ter-com/ui-components';

import useProducts from '@/hooks/features/products/useProducts';

export interface ProductSelectorProps {
  includeGlobal?: boolean;
}

export const ProductSelector: React.FC<ProductSelectorProps> = ({
  includeGlobal = false,
}) => {
  const { t } = useTranslation();

  const {
    products,
    selectedProductIdIfNotGlobal,
    selectedProductId,
    selectProduct,
  } = useProducts();

  const productId = includeGlobal
    ? selectedProductIdIfNotGlobal
    : selectedProductId;

  const options = useMemo<Array<SelectOption>>(
    () =>
      products
        ? sortBy(products, 'name').map((product) => ({
            value: product.id,
            label: product.name,
            secondaryLabel: product.code,
          }))
        : [],
    [products]
  );

  return (
    <HStack minW="50%" maxW="100%">
      <Text>{t('common:product')}:</Text>
      <Box flex={1} data-testid="product-select">
        <Select
          isSearchable
          isClearable={includeGlobal}
          placeholder={`(${t('common:global')})`}
          options={options}
          value={productId ?? null}
          onChange={selectProduct}
        />
      </Box>
    </HStack>
  );
};
