import React from 'react';

import { PlanGroup } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { PlanGroupDetails } from '@/components/features/pricing/PlanGroupDetails';
import { PlanGroupLinksList } from '@/components/features/pricing/PlanGroupLinksList';

const PlansGroupsDetailsRouteComponent: React.FC<{ data: PlanGroup }> = ({
  data,
}) => (
  <PlanGroupDetails showEditButton showIsCustomDetails planGroup={data}>
    <PlanGroupLinksList planGroup={data} />
  </PlanGroupDetails>
);

export const PlanGroupsDetailsRoute: React.FC = () => (
  <CrudDetails<PlanGroup> component={PlansGroupsDetailsRouteComponent} />
);
