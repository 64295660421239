import React from 'react';

import { GridItem, SimpleGrid } from '@chakra-ui/react';

import { DataType, CompoundAggregation } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';
import { formatNumber } from '@m3ter-com/console-core/utils';

import { formatEntityUnit } from '@/util/data';
import { DetailsCard } from '@/components/common/data/DetailsCard';

export interface CompoundAggregationDetailsProps {
  compoundAggregation: CompoundAggregation;
  showMetadata?: boolean;
}

export const CompoundAggregationDetails: React.FC<
  CompoundAggregationDetailsProps
> = ({ compoundAggregation, showMetadata }) => {
  const { t } = useTranslation();

  return (
    <DetailsCard
      showEditButton
      data={compoundAggregation}
      dataType={DataType.CompoundAggregation}
      showMetadata={showMetadata}
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue
            label={t('forms:labels.name')}
            value={compoundAggregation.name}
          />
          {!!compoundAggregation.code && (
            <KeyValue
              label={t('forms:labels.code')}
              value={compoundAggregation.code}
            />
          )}
          <KeyValue
            label={t('forms:labels.unit')}
            value={formatEntityUnit(compoundAggregation.unit)}
          />
          <KeyValue
            label={t('forms:labels.quantityPerUnit')}
            value={formatNumber(compoundAggregation.quantityPerUnit)}
          />
          <KeyValue
            label={t('forms:labels.rounding')}
            value={t(`common:rounding.${compoundAggregation.rounding}`)}
          />
          <GridItem colSpan={3}>
            <KeyValue
              label={t('forms:labels.calculation')}
              value={compoundAggregation.calculation}
            />
          </GridItem>
        </SimpleGrid>
      }
    />
  );
};
