import { create } from 'zustand';

import { StoreWithActions } from './types';

interface Breadcrumb {
  title: string;
  hideInTrail: boolean;
  to?: string;
}

interface BreadcrumbActions {
  addBreadcrumb: (title: string, to?: string, hideInTrail?: boolean) => void;
  removeBreadcrumb: (title: string, to?: string) => void;
}

interface BreadcrumbState {
  breadcrumbs: Array<Breadcrumb>;
}

type BreadcrumbStore = StoreWithActions<BreadcrumbState, BreadcrumbActions>;

const useBreadcrumbStore = create<BreadcrumbStore>((set) => ({
  breadcrumbs: [],
  actions: {
    addBreadcrumb: (title, to, hideInTrail = false) => {
      set((state) => ({
        breadcrumbs: [...state.breadcrumbs, { to, title, hideInTrail }],
      }));
    },
    removeBreadcrumb: (title, to) => {
      set((state) => ({
        breadcrumbs: state.breadcrumbs.filter(
          (item) => item.to !== to || item.title !== title
        ),
      }));
    },
  },
}));

export const useBreadcrumbsState = () =>
  useBreadcrumbStore((state) => state.breadcrumbs);

export const useBreadcrumbActions = () =>
  useBreadcrumbStore((state) => state.actions);
