import { useCallback, useMemo, useState } from 'react';

import sortBy from 'lodash/sortBy';

import { OperationalDataType } from '@m3ter-com/m3ter-api';

const filterOperationalDataTypes = (
  types: Array<OperationalDataType>,
  searchTerm: string
): Array<OperationalDataType> => {
  const ucSearchTerm = searchTerm.toUpperCase();

  return types.filter((type) => {
    return (
      type.startsWith(ucSearchTerm) ||
      type.split('_').some((word) => word.startsWith(ucSearchTerm))
    );
  });
};

const useUsageQueryBuilderDataTypes = (
  operationalDataTypes: Array<OperationalDataType>
) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const sortedDataTypes = useMemo(
    () => sortBy(operationalDataTypes),
    [operationalDataTypes]
  );

  const filteredSortedDataTypes = useMemo(
    () =>
      searchTerm !== ''
        ? filterOperationalDataTypes(sortedDataTypes, searchTerm)
        : sortedDataTypes,
    [searchTerm, sortedDataTypes]
  );

  const clearSearchTerm = useCallback(() => {
    setSearchTerm('');
  }, []);

  return {
    filteredSortedDataTypes,
    searchTerm,
    setSearchTerm,
    clearSearchTerm,
  };
};

export default useUsageQueryBuilderDataTypes;
