import { call, put, select, StrictEffect } from 'redux-saga/effects';

import {
  create,
  del,
  list,
  listAll,
  retrieve,
  update,
  DataType,
  Entity,
  PathParams,
  QueryParams,
} from '@m3ter-com/m3ter-api';

import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';
import {
  getInterceptor,
  InterceptorType,
} from '@/store/data/interceptors.saga';
import {
  dataCreated,
  dataDeleted,
  dataLoaded,
  dataUpdated,
  resetExcluding,
  singletonLoaded,
  singletonUpdated,
} from '@/store/data/data';

/**
 * This saga exports wrappers of the API methods that also dispatch data
 * actions to keep the store state in sync. They return the raw response
 * from the API so that consuming sagas can use it however they require.
 *
 * They do not handle errors, that responsibility lies with the consumer.
 */
export function* retrieveData(
  dataType: DataType,
  id: string,
  pathParams: PathParams = {}
): Generator<StrictEffect, void, any> {
  const organizationId = yield select(selectCurrentOrgId);

  // Note: Do not add `relationships` to the retrieve call because
  // it will mean the data slice will contain a mixture of entities
  // and entities with related data.
  const response = yield call(retrieve, {
    dataType,
    id,
    pathParams: { organizationId, ...pathParams },
  });

  const interceptor = yield call(
    getInterceptor,
    dataType,
    InterceptorType.AfterRetrieve
  );
  const data = interceptor ? yield call(interceptor, response) : response;

  yield put(dataLoaded(dataType, [data], pathParams));

  return data;
}

export function* retrieveSingletonData(
  dataType: DataType
): Generator<StrictEffect, Entity, any> {
  const organizationId = yield select(selectCurrentOrgId);

  const response = yield call(retrieve, {
    dataType,
    pathParams: { organizationId },
  });

  yield put(singletonLoaded(dataType, response));

  return response;
}

export function* listData(
  dataType: DataType,
  queryParams: QueryParams = {},
  actionName: string = 'list',
  pathParams: PathParams = {}
): Generator<StrictEffect, void, any> {
  const organizationId = yield select(selectCurrentOrgId);

  // Note: Do not add `relationships` to the list call because
  // it will mean the data slice will contain a mixture of entities
  // and entities with related data.
  const response = yield call(list, {
    dataType,
    actionName,
    pathParams: { organizationId, ...pathParams },
    queryParams,
  });

  const interceptor = yield call(
    getInterceptor,
    dataType,
    InterceptorType.AfterList
  );
  const updatedResponse = interceptor
    ? {
        ...response,
        data: yield call(interceptor, response.data, queryParams),
      }
    : response;

  yield put(dataLoaded(dataType, updatedResponse.data, pathParams));

  return updatedResponse;
}

export function* listAllData<E extends Entity = Entity>(
  dataType: DataType,
  queryParams: QueryParams = {},
  pathParams: PathParams = {},
  actionName: string = 'list'
): Generator<StrictEffect, Array<E> | undefined, any> {
  const organizationId = yield select(selectCurrentOrgId);

  const { data } = yield call(listAll, {
    dataType,
    pathParams: { organizationId, ...pathParams },
    queryParams,
    actionName,
  });

  const interceptor = yield call(
    getInterceptor,
    dataType,
    InterceptorType.AfterList
  );
  const updatedData = interceptor
    ? yield call(interceptor, data, queryParams)
    : data;

  yield put(dataLoaded(dataType, updatedData, pathParams));
  return updatedData;
}

export function* deleteData(
  dataType: DataType,
  id: string,
  pathParams: PathParams = {}
): Generator<StrictEffect, void, any> {
  const organizationId = yield select(selectCurrentOrgId);

  const response = yield call(del, {
    dataType,
    id,
    pathParams: { organizationId, ...pathParams },
  });

  yield put(dataDeleted(dataType, [id], pathParams));

  const interceptor = yield call(
    getInterceptor,
    dataType,
    InterceptorType.AfterDelete
  );
  if (interceptor) {
    yield call(interceptor, response);
  }

  return response;
}

export function* createData(
  dataType: DataType,
  data: any,
  pathParams: PathParams = {}
): Generator<StrictEffect, void, any> {
  const organizationId = yield select(selectCurrentOrgId);

  const createInterceptor = yield call(
    getInterceptor,
    dataType,
    InterceptorType.BeforeCreate
  );
  const updatedData = createInterceptor
    ? yield call(createInterceptor, data)
    : data;

  const response = yield call(create, {
    dataType,
    pathParams: { organizationId, ...pathParams },
    data: updatedData,
  });

  // The response from creating should be treated the same as a retrieve.
  const retrieveInterceptor = yield call(
    getInterceptor,
    dataType,
    InterceptorType.AfterRetrieve
  );
  const updatedResponse = retrieveInterceptor
    ? yield call(retrieveInterceptor, response)
    : response;

  yield put(dataCreated(dataType, [updatedResponse], pathParams));

  return updatedResponse;
}

export function* updateData(
  dataType: DataType,
  id: string,
  data: any,
  pathParams: PathParams = {}
): Generator<StrictEffect, void, any> {
  const organizationId = yield select(selectCurrentOrgId);

  const interceptor = yield call(
    getInterceptor,
    dataType,
    InterceptorType.BeforeUpdate
  );
  const updatedData = interceptor ? yield call(interceptor, data) : data;

  const response = yield call(update, {
    dataType,
    id,
    pathParams: {
      organizationId,
      ...pathParams,
    },
    data: updatedData,
  });

  // The response from updating should be treated the same as a retrieve.
  const retrieveInterceptor = yield call(
    getInterceptor,
    dataType,
    InterceptorType.AfterRetrieve
  );
  const updatedResponse = retrieveInterceptor
    ? yield call(retrieveInterceptor, response)
    : response;

  yield put(dataUpdated(dataType, [updatedResponse], pathParams));

  return updatedResponse;
}

export function* updateSingletonData(
  dataType: DataType,
  data: any
): Generator<StrictEffect, Entity, any> {
  const organizationId = yield select(selectCurrentOrgId);

  const response = yield call(update, {
    dataType,
    pathParams: { organizationId },
    data,
  });

  yield put(singletonUpdated(dataType, response));

  return response;
}

// Helper for checking if at least one of a data type exists.
export function* hasAny(
  dataType: DataType,
  params: QueryParams = {}
): Generator<StrictEffect, boolean, any> {
  const organizationId = yield select(selectCurrentOrgId);
  const response = yield call(list, {
    dataType,
    pathParams: { organizationId },
    queryParams: { ...params, pageSize: 1 },
  });

  return response.data.length > 0;
}

export function* resetExcludingSaga(excludedDataTypes: Array<DataType> = []) {
  yield put(resetExcluding(excludedDataTypes));
}
