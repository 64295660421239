import React from 'react';

import { DataType, OrganizationAdmin } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyOrganizationAdminRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "OrganizationAdminRoute" */ '@/components/routes/admin/organizations/OrganizationAdminRoute'
  ).then((module) => ({ default: module.OrganizationAdminRoute }))
);

const LazyOrganizationAdminListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "OrganizationAdminListRoute" */ '@/components/routes/admin/organizations/OrganizationAdminListRoute'
  ).then((module) => ({ default: module.OrganizationAdminListRoute }))
);

const LazyOrganizationAdminDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "OrganizationAdminDetailsRoute" */ '@/components/routes/admin/organizations/OrganizationAdminDetailsRoute'
  ).then((module) => ({ default: module.OrganizationAdminDetailsRoute }))
);

const LazyOrganizationAdminCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "OrganizationAdminCreateRoute" */ '@/components/routes/admin/organizations/OrganizationAdminCreateRoute'
  ).then((module) => ({ default: module.OrganizationAdminCreateRoute }))
);

const LazyOrganizationAdminEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "OrganizationAdminEditRoute" */ '@/components/routes/admin/organizations/OrganizationAdminEditRoute'
  ).then((module) => ({ default: module.OrganizationAdminEditRoute }))
);
export default () =>
  createCrudRoute<OrganizationAdmin>({
    path: 'organizations',
    dataType: DataType.OrganizationAdmin,
    element: <LazyOrganizationAdminRoute />,
    list: <LazyOrganizationAdminListRoute />,
    details: <LazyOrganizationAdminDetailsRoute />,
    create: <LazyOrganizationAdminCreateRoute />,
    edit: <LazyOrganizationAdminEditRoute />,
  });
