import { SystemStyleObject, ComponentMultiStyleConfig } from '@chakra-ui/react';
import { SystemStyleFunction } from '@chakra-ui/theme-tools';

const parts = [
  'item',
  'link',
  'icon',
  'group',
  'groupTitle',
  'header',
  'divider',
];

const baseStyleItem: SystemStyleFunction = (props) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: 10,
  px: 3,
  color: 'chakra-body-text',
  bg: props.isActive ? 'nav-highlight' : undefined,
  _hover: {
    bg: 'nav-highlight',
  },
  ':after': props.isActive
    ? {
        content: "''",
        position: 'absolute',
        left: 0,
        top: 0,
        display: 'block',
        width: 1,
        height: '100%',
        backgroundColor: 'green-adaptive',
        borderRightRadius: 'full',
      }
    : undefined,
});

const baseStyleLink: SystemStyleFunction = (props) => ({
  ...baseStyleItem(props),
});

const baseStyleIcon: SystemStyleObject = {
  display: 'inline-block',
  marginRight: 2,
  verticalAlign: 'middle',
};

const baseStyleGroupTitle: SystemStyleFunction = (props) => ({
  ...baseStyleItem(props),
});

const baseStyleGroupPanel: SystemStyleObject = {
  paddingLeft: 6,
};

const baseStyleHeader: SystemStyleObject = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
};

const baseStyleDivider: SystemStyleObject = {
  border: 0,
  borderBottom: '2px solid',
  borderColor: 'chakra-border-color',
  my: 1,
};

const navigation: ComponentMultiStyleConfig = {
  parts,
  baseStyle: (props) => ({
    link: baseStyleLink(props),
    icon: baseStyleIcon,
    groupTitle: baseStyleGroupTitle(props),
    groupPanel: baseStyleGroupPanel,
    header: baseStyleHeader,
    divider: baseStyleDivider,
  }),
};

export default navigation;
