import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useCombobox,
  UseComboboxGetInputPropsOptions,
  UseComboboxStateChange,
} from 'downshift';
import {
  Box,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  chakra,
  useFormControl,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { Input, InputProps } from '../Input/Input';

export interface Option {
  value: string;
  label: string;
  secondaryLabel?: string;
}

export interface ComboboxProps extends Omit<InputProps, 'onChange'> {
  value: string | undefined;
  onChange: (value: string) => void;
  isLoadingOptions?: boolean;
  options?: Array<Option>;
}

export const Combobox: React.FC<ComboboxProps> = ({
  value,
  onChange,
  isLoadingOptions = false,
  options = [],
  ...inputProps
}) => {
  const [items, setItems] = useState<Array<Option>>(options);

  // If the options change we need to reset the items.
  useEffect(() => {
    setItems(options);
  }, [options]);

  const initialSelectedItem = useMemo(
    () => items.find((item) => item.value === value),
    [items, value]
  );

  const [inputValue, setInputValue] = useState(() => {
    // If the initial value matches an item's value then the display value
    // should be the label of that item. If not, just use the value or an empty string.
    return initialSelectedItem ? initialSelectedItem.label : value ?? '';
  });

  const onSelectedItemChange = useCallback(
    (change: UseComboboxStateChange<Option>) => {
      if (!change.selectedItem) {
        return;
      }
      onChange(change.selectedItem.value);
    },
    [onChange]
  );

  const {
    isOpen,
    openMenu,
    selectItem,
    getInputProps,
    getMenuProps,
    getItemProps,
  } = useCombobox({
    items,
    itemToString: (item) => item?.label ?? '',
    inputValue,
    onInputValueChange: ({ inputValue: newInputValue }) => {
      setInputValue(newInputValue || ''); // Use an empty string as fallback
    },
    onSelectedItemChange,
    initialSelectedItem,
  });

  const onFocus = useCallback(() => {
    if (!isOpen) {
      openMenu();
    }
  }, [isOpen, openMenu]);

  const handleInputValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const handledInputValue = event.target.value;
      setItems(
        handledInputValue
          ? options.filter((option) =>
              option.label
                .toLowerCase()
                .startsWith(handledInputValue.toLowerCase())
            )
          : options
      );
      setInputValue(handledInputValue);
      onChange(handledInputValue);
      selectItem(null);
    },
    [options, selectItem, onChange]
  );

  const { id } = useFormControl({});
  const comboboxInputOptions = useMemo<UseComboboxGetInputPropsOptions>(
    () => ({
      id,
      onChange: handleInputValueChange,
      onFocus,
      onClick: onFocus, // Handle input field click
    }),
    [id, handleInputValueChange, onFocus]
  );

  const styles = useMultiStyleConfig('Combobox', {});

  const showMenu = isOpen && items.length > 0;

  return (
    <Box sx={styles.wrapper}>
      <InputGroup>
        <Input
          {...inputProps}
          {...getInputProps(comboboxInputOptions)}
          value={inputValue}
        />
        {isLoadingOptions && (
          <InputRightElement>
            <Spinner
              size="sm"
              color="chakra-subtle-text"
              data-testid="loading"
            />
          </InputRightElement>
        )}
      </InputGroup>
      <List sx={showMenu ? styles.list : undefined} {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <ListItem
              key={item.value}
              sx={styles.listItem}
              {...getItemProps({ item, index })}
            >
              {item.label}
              {!!item.secondaryLabel && (
                <chakra.span>{item.secondaryLabel}</chakra.span>
              )}
            </ListItem>
          ))}
      </List>
    </Box>
  );
};
