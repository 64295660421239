import React from 'react';

import { Spinner } from '@chakra-ui/react';

import { IntegrationConfig } from '@m3ter-com/m3ter-api';

import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { useIntegrationConfigSchemaContext } from '@/components/features/integrations/IntegrationConfigSchemaContext';
import { IntegrationConfigForm } from '@/components/features/integrations/IntegrationConfigForm';

export const IntegrationConfigEditRoute: React.FC = () => {
  const { configSchemaError, isLoadingConfigSchema } =
    useIntegrationConfigSchemaContext();

  if (isLoadingConfigSchema) {
    return <Spinner />;
  }

  if (configSchemaError) {
    return <ErrorAlert error={configSchemaError} />;
  }

  return <CrudEdit<IntegrationConfig> form={IntegrationConfigForm} />;
};
