import React from 'react';

import { Card, CardBody, CardHeader, Heading, VStack } from '@chakra-ui/react';

import { DataType, Account } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button, CardActionsHeader } from '@m3ter-com/ui-components';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import {
  AccountCommitmentListType,
  AccountCommitmentsList,
} from '@/components/features/accounts/AccountCommitmentsList';

export interface AccountCommitmentsProps {
  account: Account;
}

export const AccountCommitments: React.FC<AccountCommitmentsProps> = ({
  account,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Commitment);

  const accountId = account.id;

  return (
    <VStack spacing={4} alignItems="stretch">
      <Card>
        <CardActionsHeader
          actions={
            <Button
              size="sm"
              as={CrudCreateLink}
              addReturnPath
              dataType={DataType.Commitment}
            >
              {t('forms:buttons.createEntity', {
                entityName: entityNamings.singularLower,
              })}
            </Button>
          }
        >
          <Heading size="md">
            {t('features:account.activeAndPendingPrepayments')}
          </Heading>
        </CardActionsHeader>
        <CardBody>
          <AccountCommitmentsList
            accountId={accountId}
            listType={AccountCommitmentListType.ActiveAndPending}
          />
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <Heading size="md">
            {t('features:account.previousPrepayments')}
          </Heading>
        </CardHeader>
        <CardBody>
          <AccountCommitmentsList
            accountId={accountId}
            listType={AccountCommitmentListType.Expired}
          />
        </CardBody>
      </Card>
    </VStack>
  );
};
