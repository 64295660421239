import { useCallback, useMemo } from 'react';

import { DataType, User } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { NotificationOptions } from '@/hooks/util/useNotification';
import useEntityUpdateMutation from '@/hooks/data/useEntityUpdateMutation';

const useUserActivation = () => {
  const { t } = useTranslation();

  const {
    error: errorUpdatingUserActivation,
    isSaving: isUpdatingUserActivation,
    updateEntity: updateUserActivation,
  } = useEntityUpdateMutation(DataType.User);

  const userActivatedSuccessNotification = useMemo<NotificationOptions>(
    () => ({
      status: 'success',
      description: t('notifications:userActivatedSuccess'),
      duration: 5000,
    }),
    [t]
  );
  const userActivatedFailureNotification = useMemo<NotificationOptions>(
    () => ({
      status: 'error',
      description: t('notifications:userActivatedFailure'),
    }),
    [t]
  );
  const userDeactivatedSuccessNotification = useMemo<NotificationOptions>(
    () => ({
      status: 'success',
      description: t('notifications:userDeactivatedSuccess'),
      duration: 5000,
    }),
    [t]
  );
  const userDeactivatedFailureNotification = useMemo<NotificationOptions>(
    () => ({
      status: 'error',
      description: t('notifications:userDeactivatedFailure'),
    }),
    [t]
  );

  const onActivateUser = useCallback(
    (user: User) => {
      updateUserActivation({
        newEntity: { ...user, dtEndAccess: undefined },
        successNotification: userActivatedSuccessNotification,
        failureNotification: userActivatedFailureNotification,
      });
    },
    [
      updateUserActivation,
      userActivatedFailureNotification,
      userActivatedSuccessNotification,
    ]
  );

  const onDeactivateUser = useCallback(
    (user: User) => {
      const now = new Date(Date.now()).toISOString();
      updateUserActivation({
        newEntity: { ...user, dtEndAccess: now },
        successNotification: userDeactivatedSuccessNotification,
        failureNotification: userDeactivatedFailureNotification,
      });
    },
    [
      updateUserActivation,
      userDeactivatedFailureNotification,
      userDeactivatedSuccessNotification,
    ]
  );

  return {
    errorUpdatingUserActivation,
    isUpdatingUserActivation,
    onActivateUser,
    onDeactivateUser,
  };
};

export default useUserActivation;
