import { spawn } from 'redux-saga/effects';
import { Router } from '@remix-run/router';

import appSaga from '@/store/app/app.saga';
import crudSaga from '@/store/crud/crud.saga';
import featuresSaga from '@/store/features/featuresSaga';
import notificationsSaga from '@/store/notifications/notifications.saga';
import productsSaga from '@/store/products/products.saga';
import redirectionSaga from '@/store/redirection/redirection.saga';
import singletonsSaga from '@/store/singletons/singletons.saga';
import usersSaga from '@/store/users/users.saga';
import utilsSaga from '@/store/utils/utils.saga';

export interface Context {
  router: Router;
}

// https://redux-saga.js.org/docs/advanced/RootSaga/
export default function* rootSaga(context: Context) {
  yield spawn(appSaga, context);
  yield spawn(crudSaga);
  yield spawn(featuresSaga);
  yield spawn(notificationsSaga);
  yield spawn(productsSaga);
  yield spawn(redirectionSaga, context);
  yield spawn(singletonsSaga);
  yield spawn(usersSaga);
  yield spawn(utilsSaga);
}
