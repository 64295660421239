import React from 'react';

import { Grid } from '@chakra-ui/react';

import { ExportDestinationsList } from '@/components/features/data-exports/ExportDestinationsList';
import { ExportSchedulesList } from '@/components/features/data-exports/ExportSchedulesList';

export const DataExportOverviewRoute: React.FC = () => {
  return (
    <Grid templateColumns="3fr max-content" gap={4}>
      <ExportSchedulesList />
      <ExportDestinationsList />
    </Grid>
  );
};
