import { PayloadAction } from '@reduxjs/toolkit';
import { Task } from 'redux-saga';
import {
  call,
  cancel,
  fork,
  put,
  select,
  take,
  StrictEffect,
} from 'redux-saga/effects';

import {
  isSearchable,
  DataType,
  Entity,
  ListResponse,
  QueryParams,
  UnknownEntity,
} from '@m3ter-com/m3ter-api';

import {
  ListSearchComparator,
  ListSearchCriteria,
  ListSearchCriterion,
} from '@/types/lists';

import { ids } from '@/util/data';
import { extractError } from '@/util/error';
import { listAllData, listData } from '@/store/data/data.saga';
import { selectListState } from '@/store/crud';

import { loadRelatedData } from './crudUtils.saga';
import {
  filterList,
  listLoadFailure,
  listLoadSuccess,
  loadList,
  loadNextListPage,
  loadSpecificListPage,
  refreshList,
  searchList,
  sortList,
  BaseListPayload,
  List,
} from './list';

interface ClientSideSearchCriterion {
  fieldName: string;
  searchQuery: string;
}

const DEFAULT_PAGE_SIZE = 20;

export const searchComparatorSymbolsMap: Record<ListSearchComparator, string> =
  {
    [ListSearchComparator.Contains]: '~',
    [ListSearchComparator.Equal]: ':',
    [ListSearchComparator.NotEqual]: '!:',
    [ListSearchComparator.LessThan]: '<',
    [ListSearchComparator.LessThanOrEqual]: '<=',
    [ListSearchComparator.GreaterThan]: '>',
    [ListSearchComparator.GreaterThanOrEqual]: '>=',
  };

const containsValidSearchCriteria = (
  criteria?: ListSearchCriteria
): boolean => {
  const flatCriteria = Object.values(criteria || {}).flat();
  return flatCriteria.length > 0;
};

const getClientSideSearchCriteria = (
  criteria?: ListSearchCriteria
): Array<ClientSideSearchCriterion> => {
  const flatCriteria = Object.entries(criteria || {}).flatMap(
    ([fieldName, fieldCriteria]) => {
      return Array.isArray(fieldCriteria)
        ? fieldCriteria.map((fieldCriterion) => ({ fieldName, fieldCriterion }))
        : [{ fieldName, fieldCriterion: fieldCriteria }];
    }
  );
  return flatCriteria
    .filter(
      (fieldCriteria) =>
        fieldCriteria.fieldCriterion.comparator ===
        ListSearchComparator.Contains
    )
    .map((fieldCriteria) => ({
      fieldName: fieldCriteria.fieldName,
      searchQuery: `${fieldCriteria.fieldCriterion.value}`,
    }));
};

const buildSearchCriterionQuery = (
  fieldName: string,
  criterion: ListSearchCriterion
): string =>
  `${fieldName}${searchComparatorSymbolsMap[criterion.comparator]}${
    criterion.value
  }`;

const buildSearchCriteriaQuery = (criteria: ListSearchCriteria): string => {
  const queryElements = new Array<string>();
  Object.entries(criteria).forEach(([fieldName, fieldCriteria]) => {
    (Array.isArray(fieldCriteria) ? fieldCriteria : [fieldCriteria]).forEach(
      (fieldCriterion) => {
        if (fieldCriterion) {
          queryElements.push(
            buildSearchCriterionQuery(fieldName, fieldCriterion)
          );
        }
      }
    );
  });

  return queryElements.join('$');
};

const buildSearchQuery = (
  searchCriteria?: ListSearchCriteria,
  filterCriteria?: ListSearchCriteria
): string => {
  const queryElements = new Array<string>();

  if (searchCriteria) {
    queryElements.push(buildSearchCriteriaQuery(searchCriteria));
  }
  if (filterCriteria) {
    queryElements.push(buildSearchCriteriaQuery(filterCriteria));
  }

  return queryElements.join('$');
};

export function* loadSimpleListData(
  dataType: DataType,
  listId: string,
  listState: List
): Generator<StrictEffect, ListResponse<Entity> | undefined, any> {
  try {
    const previousPageState =
      listState.currentPageIndex > 0
        ? listState.pages[listState.currentPageIndex - 1]
        : undefined;
    const newPageIndex = previousPageState ? listState.currentPageIndex : 0;
    const nextToken = previousPageState?.nextToken;

    const response: ListResponse<Entity> = yield call(
      listData,
      dataType,
      {
        pageSize: DEFAULT_PAGE_SIZE,
        ...listState.queryParams, // will override (default) pageSize if present in params.
        nextToken,
      },
      listState.actionName,
      listState.pathParams
    );

    const relatedData = yield call(
      loadRelatedData,
      dataType,
      response.data,
      listState.relationships
    );

    yield put(
      listLoadSuccess(
        dataType,
        listId,
        ids(response.data),
        newPageIndex,
        relatedData,
        response.nextToken
      )
    );

    return response;
  } catch (error) {
    yield put(listLoadFailure(dataType, listId, extractError(error)));
  }

  return undefined;
}

export function* loadListDataWithClientSideSearch(
  dataType: DataType,
  listId: string,
  listState: List,
  clientSideSearchCriteria: Array<ClientSideSearchCriterion>
): Generator<StrictEffect, void, any> {
  try {
    // Remove pageSize queryParam because we're going to load all the entities
    // in order to manually filter them.
    const { pageSize: _, ...queryParams } = listState.queryParams || {};

    // Load all entities for the given dataType and then manually filter them based on
    // the provided search criteria.
    const allData: Array<UnknownEntity> = yield call(
      listAllData,
      dataType,
      queryParams,
      listState.pathParams,
      listState.actionName
    );
    const matchingData = allData.filter((entity) =>
      clientSideSearchCriteria.some((searchCriteria) => {
        const entityValue = entity[searchCriteria.fieldName]
          ? `${entity[searchCriteria.fieldName]}`
          : undefined;
        return (
          !!entityValue &&
          entityValue
            .toLowerCase()
            .includes(`${searchCriteria.searchQuery}`.toLowerCase())
        );
      })
    );

    const relatedData = yield call(
      loadRelatedData,
      dataType,
      matchingData,
      listState.relationships
    );

    yield put(
      listLoadSuccess(dataType, listId, ids(matchingData), 0, relatedData)
    );
  } catch (error) {
    yield put(listLoadFailure(dataType, listId, extractError(error)));
  }
}

export function* loadListDataWithServerSearch(
  dataType: DataType,
  listId: string,
  listState: List
): Generator<StrictEffect, ListResponse<Entity> | undefined, any> {
  try {
    const previousPageState =
      listState.currentPageIndex > 0
        ? listState.pages[listState.currentPageIndex - 1]
        : undefined;
    const nextToken = parseInt(previousPageState?.nextToken ?? '0', 10);
    const newPageIndex = nextToken ? listState.currentPageIndex : 0;
    const fromDocument = Number.isNaN(nextToken) ? 0 : nextToken;

    const queryParams: QueryParams = {
      pageSize: DEFAULT_PAGE_SIZE,
      ...listState.queryParams, // will override (default) pageSize if present in params.
      fromDocument,
      // pageSize: 1,
    };
    const searchQuery = buildSearchQuery(
      listState.searchCriteria,
      listState.filterCriteria
    );
    if (searchQuery) {
      queryParams.searchQuery = searchQuery;
      queryParams.operator = listState.searchOperator;
    }
    if (listState.sortCriteria?.sortBy) {
      queryParams.sortBy = listState.sortCriteria.sortBy;
    }
    if (listState.sortCriteria?.sortOrder) {
      queryParams.sortOrder = listState.sortCriteria.sortOrder;
    }

    const response: ListResponse<Entity> = yield call(
      listData,
      dataType,
      queryParams,
      'search',
      listState.pathParams
    );

    const relatedData = yield call(
      loadRelatedData,
      dataType,
      response.data,
      listState.relationships
    );

    yield put(
      listLoadSuccess(
        dataType,
        listId,
        ids(response.data),
        newPageIndex,
        relatedData,
        response.nextToken
      )
    );

    return response;
  } catch (error) {
    yield put(listLoadFailure(dataType, listId, extractError(error)));
  }

  return undefined;
}

export function* loadListDataSaga(
  action: PayloadAction<BaseListPayload>
): Generator<StrictEffect, void, any> {
  const { dataType, listId } = action.payload;
  const listSelector = yield call(selectListState, dataType, listId);
  const listState: List | undefined = yield select(listSelector);
  if (!listState) {
    return;
  }

  const isSearching = containsValidSearchCriteria(listState.searchCriteria);
  const isFiltering = containsValidSearchCriteria(listState.filterCriteria);

  // We have 3 ways of loading list data:
  //
  // 1. Using server-side filtering using "/search" endpoints. This supports complex filtering and sorting via query params.
  //
  // 2. Client-side filtering. This supports searching on one or more fields simple "CONTAINS" filters. No complex filtering
  // or value comparisons, no sorting.
  //
  // 3. Simple lists. No filtering of any kind, no sorting, just a page of results at a time from an endpoint.
  // TODO: Remove option 2 and all related code when all entities have search endpoints.

  // Option 1
  const supportsServerSearch = yield call(isSearchable, dataType);
  const canDoServerSearch =
    supportsServerSearch &&
    ['list', 'search'].includes(listState.actionName) &&
    (isSearching || isFiltering);
  if (canDoServerSearch) {
    yield call(loadListDataWithServerSearch, dataType, listId, listState);
    return;
  }

  // Option 2 / Option 3
  const clientSideSearchCriteria = getClientSideSearchCriteria(
    listState.searchCriteria
  );
  if (isSearching && clientSideSearchCriteria.length > 0) {
    yield call(
      loadListDataWithClientSideSearch,
      dataType,
      listId,
      listState,
      clientSideSearchCriteria
    );
  } else {
    yield call(loadSimpleListData, dataType, listId, listState);
  }
}

const listLoadingActionTypes = [
  filterList.type,
  loadList.type,
  loadNextListPage.type,
  loadSpecificListPage.type,
  refreshList.type,
  searchList.type,
  sortList.type,
];
export default function* listSaga(): Generator<StrictEffect, void, any> {
  // For list actions, we want to track running instances of the loadListDataSaga, for each dataType
  // / listId combination. If a new action comes in for the same list (same dataType and listId), we
  // want to cancel the running task and start fresh.
  // Otherwise, someone could, for example, change their search query twice in a small timeframe
  // and they would get the results of the first search flashing on screen before the second search
  // finishes and updates the list state again.
  const activeListLoadingTasks: Partial<Record<string, Task>> = {};
  while (true) {
    const nextListLoadAction: PayloadAction<BaseListPayload> = yield take(
      listLoadingActionTypes
    );
    const taskKey = `${nextListLoadAction.payload.dataType}-${nextListLoadAction.payload.listId}`;
    const runningListLoadingTask = activeListLoadingTasks[taskKey];
    if (runningListLoadingTask && runningListLoadingTask.isRunning()) {
      yield cancel(runningListLoadingTask);
    }
    activeListLoadingTasks[taskKey] = yield fork(
      loadListDataSaga,
      nextListLoadAction
    );
  }
}
