import React from 'react';

import { Badge, chakra, Icon } from '@chakra-ui/react';
import { CircleXIcon, InfoIcon, TriangleAlertIcon } from 'lucide-react';

import { AlertSeverity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

export interface SeverityBadgeProps {
  severity: AlertSeverity;
}

export const SeverityBadge: React.FC<SeverityBadgeProps> = ({ severity }) => {
  const { t } = useTranslation();

  let colorScheme;
  let icon;
  switch (severity) {
    case AlertSeverity.Info:
      colorScheme = 'blue';
      icon = InfoIcon;
      break;
    case AlertSeverity.Warn:
      colorScheme = 'orange';
      icon = TriangleAlertIcon;
      break;
    case AlertSeverity.Error:
      colorScheme = 'red';
      icon = CircleXIcon;
      break;
    default:
    // No-op
  }

  return (
    <Badge
      data-testid="severity-badge"
      whiteSpace="nowrap"
      colorScheme={colorScheme}
    >
      {icon && (
        <chakra.span mr={1} display="inline-flex" alignSelf="center">
          <Icon as={icon} w={2} h={2} />
        </chakra.span>
      )}
      {t(`features:alerts.severities.${severity}`)}
    </Badge>
  );
};
