import React, { useCallback, MouseEvent } from 'react';

import {
  forwardRef,
  Tag,
  TagCloseButton,
  TagLabel,
  TagProps,
  useStyleConfig,
} from '@chakra-ui/react';

export interface TagButtonProps extends TagProps {
  closeLabel?: string;
  onClick?: () => void;
  onClose?: () => void;
}

export const TagButton = forwardRef<TagButtonProps, 'button'>(
  (
    {
      closeLabel = 'Close',
      onClick,
      onClose,
      colorScheme = 'gray',
      size = 'md',
      children,
      ...tagProps
    },
    ref
  ) => {
    const onCloseClick = useCallback(
      (event: MouseEvent) => {
        event.stopPropagation(); // Prevent `onClick` call.
        if (onClose) {
          onClose();
        }
      },
      [onClose]
    );

    const styles = useStyleConfig('TagButton', { colorScheme, size, onClick });

    return (
      <Tag
        ref={ref}
        onClick={onClick}
        colorScheme={colorScheme}
        size={size}
        sx={styles}
        role={onClick ? 'button' : undefined}
        {...tagProps}
      >
        <TagLabel display="flex" gap={2} alignItems="center">
          {children}
        </TagLabel>
        {onClose && (
          <TagCloseButton onClick={onCloseClick} aria-label={closeLabel} />
        )}
      </Tag>
    );
  }
);
