import React from 'react';

import { Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from 'lucide-react';

import { Bill, IntegrationRun } from '@m3ter-com/m3ter-api';

export interface ExternalInvoiceLinkProps {
  bill: Bill;
  integrationRun?: IntegrationRun;
}

export const ExternalInvoiceLink: React.FC<ExternalInvoiceLinkProps> = ({
  bill,
  integrationRun,
}) => {
  // If we have an external invoice reference we can use that as the text.
  // Fall back to the name of the integration, the external ID or finally to `-`.
  const label =
    bill.externalInvoiceReference ??
    integrationRun?.destination ??
    integrationRun?.externalId ??
    '-';

  // Add an external link if there is a URL in the integration data.
  return integrationRun?.url ? (
    <Link
      isExternal
      alignItems="center"
      display="flex"
      flexFlow="row nowrap"
      gap={2}
      justifyContent="flex-start"
      href={integrationRun.url}
      title={integrationRun.destination}
    >
      <span>{label}</span>
      <ExternalLinkIcon size={16} />
    </Link>
  ) : (
    // Without the Fragment the return type doesn't match `React.FC`
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>{label}</React.Fragment>
  );
};
