import React, {
  useCallback,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';

import { Box, Radio, SimpleGrid, useMultiStyleConfig } from '@chakra-ui/react';

export interface RadioTileOption<V extends string = string> {
  value: V;
  label: string;
  content: ReactNode;
}

export interface RadioTilesProps<V extends string = string> {
  options: Array<RadioTileOption<V>>;
  columns?: number;
  value?: string;
  onChange?: (value: V) => void;
}

interface RadioTileProps<V extends string = string> {
  option: RadioTileOption<V>;
  isSelected: boolean;
  onSelect: (value: V) => void;
}

function RadioTile<V extends string = string>({
  option,
  isSelected,
  onSelect,
}: PropsWithChildren<RadioTileProps<V>>): ReactElement<any, any> {
  const styles = useMultiStyleConfig(
    'RadioTile',
    isSelected ? { variant: 'selected' } : {}
  );

  return (
    <Box
      sx={styles.wrapper}
      onClick={() => {
        onSelect(option.value);
      }}
    >
      <Radio value={option.value} isChecked={isSelected}>
        {option.label}
      </Radio>
      <Box sx={styles.content}>{option.content}</Box>
    </Box>
  );
}

export function RadioTiles<V extends string = string>({
  columns = 3,
  options,
  value,
  onChange,
}: PropsWithChildren<RadioTilesProps<V>>): ReactElement<any, any> {
  // Browsers fire 2 click events when clicking a label that controls a radio button
  // (one for the label and one for the radio) so we need to only trigger the onChange
  // when the value has actually changed. This also stops multiple clicks on the same
  // option from calling `onChange`.
  // See https://github.com/chakra-ui/chakra-ui/issues/2854
  const onSelect = useCallback(
    (newValue: V) => {
      if (onChange && newValue !== value) {
        onChange(newValue);
      }
    },
    [onChange, value]
  );

  return (
    <SimpleGrid columns={columns} gap={4}>
      {options.map((option) => (
        <RadioTile
          key={option.value}
          isSelected={option.value === value}
          onSelect={onSelect}
          option={option}
        />
      ))}
    </SimpleGrid>
  );
}
