import React from 'react';

import { VStack } from '@chakra-ui/react';

import { Id, ServiceUserCredential } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, CopyToClipboard, KeyValue } from '@m3ter-com/ui-components';

export interface ServiceUserCredentialDetailsProps {
  credential: ServiceUserCredential;
  organizationId: Id;
}

export const ServiceUserCredentialDetails: React.FC<
  ServiceUserCredentialDetailsProps
> = ({ credential, organizationId }) => {
  const { t } = useTranslation();

  return (
    <VStack spacing={4} alignItems="stretch">
      <Alert status="warning" mb={4}>
        {t('features:users.serviceUsers.apiSecretWarning')}
      </Alert>
      <KeyValue
        fontSize="x-large"
        label={t('forms:labels.orgId')}
        value={
          <CopyToClipboard value={organizationId}>
            {organizationId}
          </CopyToClipboard>
        }
      />
      <KeyValue
        fontSize="x-large"
        label={t('features:users.serviceUsers.accessKeyId')}
        value={
          <CopyToClipboard value={credential.apiKey}>
            {credential.apiKey}
          </CopyToClipboard>
        }
      />
      <KeyValue
        fontSize="x-large"
        label={t('features:users.serviceUsers.apiSecret')}
        value={
          <CopyToClipboard value={credential.apiSecret}>
            {credential.apiSecret}
          </CopyToClipboard>
        }
      />
    </VStack>
  );
};
