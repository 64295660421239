import React, { ComponentProps } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { UsageQueryBuilderAccounts } from '@/components/features/usage/query-builder/UsageQueryBuilderAccounts';

export interface FormUsageQueryBuilderAccountsProps
  extends Omit<
    ComponentProps<typeof UsageQueryBuilderAccounts>,
    'value' | 'onChange'
  > {
  name: string;
}

export const FormUsageQueryBuilderAccounts: React.FC<
  FormUsageQueryBuilderAccountsProps
> = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = [], onChange } }) => (
        <UsageQueryBuilderAccounts
          value={value}
          onChange={onChange}
          {...props}
        />
      )}
    />
  );
};
