import React, { PropsWithChildren } from 'react';

import { SimpleGrid } from '@chakra-ui/react';

import { DataType, AccountPlan } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import { DetailsCard } from '@/components/common/data/DetailsCard';
import { ReferenceLink } from '@/components/common/data/ReferenceLink';

export interface AccountPlanDetailsProps {
  accountPlan: AccountPlan;
}

export const AccountPlanDetails: React.FC<
  PropsWithChildren<AccountPlanDetailsProps>
> = ({ children, accountPlan }) => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();

  const isPlanGroup = !!accountPlan.planGroupId;

  return (
    <DetailsCard
      showEditButton
      data={accountPlan}
      dataType={DataType.AccountPlan}
      entityNameOverride={
        isPlanGroup
          ? t('features:planGroups.planGroupAttachment')
          : t('features:plans.planAttachment')
      }
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue
            label={t('common:start')}
            value={toLongDateTime(accountPlan.startDate)}
          />
          <KeyValue
            label={t('common:end')}
            value={
              accountPlan.endDate ? toLongDateTime(accountPlan.endDate) : '-'
            }
          />
          <KeyValue
            label={t('forms:labels.accountChildBillingMode')}
            value={
              accountPlan.childBillingMode
                ? t(
                    `features:account.childBillingMode.${accountPlan.childBillingMode}`
                  )
                : '-'
            }
          />
          <KeyValue
            label={t('forms:labels.contract')}
            value={
              accountPlan.contractId ? (
                <ReferenceLink
                  dataType={DataType.Contract}
                  id={accountPlan.contractId}
                  accessor="name"
                />
              ) : (
                '-'
              )
            }
          />
        </SimpleGrid>
      }
    >
      {children}
    </DetailsCard>
  );
};
