import { Id, PathParams, QueryParams } from '../../types';
import { DataType, DataTypeToEntity, UnknownEntity } from '../../types/data';
import { ListResponse } from '../../types/responses';
import { get, post, put, del as baseDel } from '../../client';
import { allProperties } from '../../util/promises';

import { ActionType, HttpMethod } from './types';
import { dataTypeActions } from './config';
import {
  getRelationship,
  getRelatedIds,
  getEntityRelatedData,
  EntityWithRelationships,
} from './relationships';

// Re-export for use in existing sagas until they are removed.
export {
  getRelationship,
  getRelatedIds,
  getEntityRelatedData,
  type EntityWithRelationships,
};

// CRUD actions on data types.

export interface BaseOptions<DT extends DataType> {
  dataType: DT;
  actionName?: string;
  pathParams?: PathParams;
  queryParams?: QueryParams;
}

export interface RelationshipOptions<DT extends DataType>
  extends BaseOptions<DT> {
  relationships?: Array<string>;
}

// Checks the options has an array of at least one string in `relationships`.
type HasRelationships<O extends RelationshipOptions<any>> = O & {
  relationships: [string, ...string[]];
};

export interface ListOptions<DT extends DataType>
  extends RelationshipOptions<DT> {}

export interface ListAllOptions<DT extends DataType> extends BaseOptions<DT> {}

export interface RetrieveOptions<DT extends DataType>
  extends RelationshipOptions<DT> {
  id?: Id; // ID is optional as singletons don't have IDs.
}

export interface CreateOptions<DT extends DataType> extends BaseOptions<DT> {
  data: any;
}

export interface UpdateOptions<DT extends DataType> extends BaseOptions<DT> {
  id?: Id; // ID is optional as singletons don't have IDs.
  data?: any; // Data is optional because some actions are data-less (e.g. accepting terms)
}

export interface DeleteOptions<DT extends DataType> extends BaseOptions<DT> {
  id: Id;
}

const apiMethodsByType = {
  [HttpMethod.Get]: get,
  [HttpMethod.Post]: post,
  [HttpMethod.Put]: put,
  [HttpMethod.Delete]: baseDel,
};

export const isSearchable = (dataType: DataType): boolean => {
  const dataTypeActionConfig = dataTypeActions[dataType];

  return !!dataTypeActionConfig.actions.search;
};

interface PerformDataActionOptions<DT extends DataType>
  extends BaseOptions<DT> {
  actionName: string;
  data?: any;
  enforceType?: ActionType;
}

// Deliberately not exporting this as consumers should be able to use
// standard CRUD action functions or specific endpoint functions.
const performDataAction = async <DT extends DataType>({
  dataType,
  actionName,
  enforceType,
  pathParams = {},
  queryParams = {},
  data,
}: PerformDataActionOptions<DT>) => {
  const dataTypeActionConfig = dataTypeActions[dataType];

  if (!dataTypeActionConfig) {
    throw new Error(`No actions fround for for data type ${dataType}`);
  }

  const action = dataTypeActionConfig.actions[actionName];

  if (!action) {
    throw new Error(
      `Action '${actionName}' not found for data type ${dataType}`
    );
  }

  if (enforceType && action.type !== enforceType) {
    throw new Error(
      `Action '${actionName}' is of type ${action.type}, expected ${enforceType}`
    );
  }

  return apiMethodsByType[action.method]({
    path: `${dataTypeActionConfig.path}${action.path}`,
    pathParams,
    queryParams,
    body: data,
  });
};

const loadAndMergeRelatedData = async <DT extends DataType>(
  dataType: DT,
  data: Array<DataTypeToEntity[DT]>,
  relationships: Array<string>,
  pathParams?: PathParams
) => {
  // Get all the unique foreign key (id) values and load the data.
  const relatedResponses = await allProperties(
    // Create a map of relationship name to `call` effect, if there is data to load.
    relationships.reduce<Record<string, Promise<any>>>(
      (acc, relationshipName) => {
        const relationship = getRelationship(dataType, relationshipName);
        const relatedIds = getRelatedIds(data, relationship.foreignKey);

        // Only add the list call if there are ids to load.
        if (relatedIds.length > 0) {
          acc[relationshipName] = performDataAction({
            dataType: relationship.dataType,
            actionName: 'list',
            queryParams: {
              ids: relatedIds,
            },
            pathParams,
          });
        }
        return acc;
      },
      {}
    )
  );

  const relatedData: Record<string, Array<UnknownEntity>> = {};
  relationships.forEach((relationshipName) => {
    relatedData[relationshipName] = relatedResponses[relationshipName]
      ? relatedResponses[relationshipName].data
      : [];
  });

  const mergedData = data.map((entity) => {
    const relationshipEntities = getEntityRelatedData(
      dataType,
      entity,
      relatedData
    );
    return { ...entity, ...relationshipEntities };
  });

  return mergedData;
};

type ListFn = {
  <DT extends DataType>(options: HasRelationships<ListOptions<DT>>): Promise<
    ListResponse<EntityWithRelationships<DT>>
  >;
  <DT extends DataType>(options: ListOptions<DT>): Promise<
    ListResponse<DataTypeToEntity[DT]>
  >;
};
export const list: ListFn = async <DT extends DataType>({
  dataType,
  actionName = 'list',
  pathParams,
  queryParams,
  relationships = [],
}: ListOptions<DT>) => {
  const response = (await performDataAction({
    dataType,
    actionName,
    pathParams,
    queryParams,
  })) as ListResponse<DataTypeToEntity[DT]>;

  return relationships.length === 0 || response.data.length === 0
    ? response
    : {
        ...response,
        data: await loadAndMergeRelatedData(
          dataType,
          response.data,
          relationships,
          pathParams
        ),
      };
};

export const listAll = async <DT extends DataType>(
  options: ListAllOptions<DT>
) => {
  const data: Array<DataTypeToEntity[DT]> = [];

  let continueFetching = true;
  let nextToken: string | undefined;

  while (continueFetching) {
    // We can't execute the API requests in parallel because we need the nextToken from one
    // request for the next one.
    // eslint-disable-next-line no-await-in-loop
    const response = (await performDataAction({
      ...options,
      actionName: options.actionName ?? 'list',
      queryParams: {
        ...options.queryParams,
        nextToken,
      },
    })) as ListResponse<DataTypeToEntity[DT]>;

    data.push(...response.data);
    if (response.nextToken) {
      nextToken = response.nextToken;
    } else {
      continueFetching = false;
    }
  }

  return { data } as ListResponse<DataTypeToEntity[DT]>;
};

type RetrieveFn = {
  <DT extends DataType>(
    options: HasRelationships<RetrieveOptions<DT>>
  ): Promise<EntityWithRelationships<DT>>;
  <DT extends DataType>(options: RetrieveOptions<DT>): Promise<
    DataTypeToEntity[DT]
  >;
};
export const retrieve: RetrieveFn = async <DT extends DataType>({
  dataType,
  actionName = 'retrieve',
  id,
  pathParams,
  queryParams,
  relationships = [],
}: RetrieveOptions<DT>) => {
  const response = (await performDataAction({
    dataType,
    actionName,
    pathParams: { ...pathParams, id },
    queryParams,
  })) as DataTypeToEntity[DT];

  return relationships.length === 0
    ? response
    : (
        await loadAndMergeRelatedData(
          dataType,
          [response],
          relationships,
          pathParams
        )
      )[0];
};

export const create = async <DT extends DataType>({
  dataType,
  actionName = 'create',
  pathParams,
  queryParams,
  data,
}: CreateOptions<DT>) => {
  return performDataAction({
    dataType,
    actionName,
    pathParams,
    queryParams,
    data,
  }) as Promise<DataTypeToEntity[DT]>;
};

export const update = async <DT extends DataType>({
  dataType,
  actionName = 'update',
  id,
  pathParams,
  queryParams,
  data,
}: UpdateOptions<DT>) => {
  return performDataAction({
    dataType,
    actionName,
    pathParams: { ...pathParams, id },
    queryParams,
    data,
  }) as Promise<DataTypeToEntity[DT]>;
};

export const del = async <DT extends DataType>({
  dataType,
  actionName = 'delete',
  id,
  pathParams,
  queryParams,
}: DeleteOptions<DT>) => {
  return performDataAction({
    dataType,
    actionName,
    pathParams: { ...pathParams, id },
    queryParams,
  }) as Promise<DataTypeToEntity[DT]>;
};
