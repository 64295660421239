/* eslint-disable import/no-import-module-exports */
// Use of module.hot causes the import rules to assume this is a CJS file.
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { captureException } from '@sentry/react';

import { isLocal, isSentryEnabled } from '@/config';

import createRootReducer from './rootReducer';
import rootSaga, { Context } from './rootSaga';

const setupStore = (sagaContext: Context) => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error, { sagaStack }) => {
      if (isLocal()) {
        console.error(error); // eslint-disable-line no-console
        console.error(sagaStack); // eslint-disable-line no-console
      }
      if (isSentryEnabled()) {
        captureException(error, (scope) => {
          scope.addBreadcrumb({
            type: 'error',
            level: 'error',
            category: 'exception',
            message: sagaStack,
          });
          return scope;
        });
      }
    },
  });

  const store = configureStore({
    reducer: createRootReducer(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware),
  });

  sagaMiddleware.run(rootSaga, sagaContext);

  if (isLocal() && module.hot) {
    module.hot.accept('./rootReducer', () => {
      const newCreateRootReducer = require('./rootReducer').default; // eslint-disable-line global-require
      store.replaceReducer(newCreateRootReducer());
    });
  }

  return store;
};

export default setupStore;
