import React from 'react';

import { Trans } from 'react-i18next';
import { Heading, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { TriangleAlertIcon } from 'lucide-react';

import { DataType, Account } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import type { TFunction } from 'i18next';

import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import { NamedLink } from '@/components/common/navigation/NamedLink';
import { AccountPlansList } from '@/components/features/accounts/AccountPlansList';
import { AccountPlansListActions } from '@/components/features/accounts/AccountPlanListActions';
import useAccountPlans from '@/hooks/features/accounts/useAccountPlans';

export interface AccountPlansProps {
  account: Account;
}

export const AccountPlans: React.FC<AccountPlansProps> = ({ account }) => {
  const { t } = useTranslation();
  const {
    isLoading,
    error,
    activeAndPendingPlanAccountPlans,
    activeAndPendingPlanGroupAccountPlans,
    previousPlanAccountPlans,
    previousPlanGroupAccountPlans,
  } = useAccountPlans(account);

  return (
    <VStack spacing={4} alignItems="stretch">
      {!!error && <ErrorAlert error={error} />}
      <AccountPlansList
        showActiveStatusBadge
        planAccountPlanRows={activeAndPendingPlanAccountPlans}
        planGroupAccountPlans={activeAndPendingPlanGroupAccountPlans}
        isLoading={isLoading}
        header={
          <HStack justifyContent="space-between">
            <Heading size="md">
              {t('features:account.activeAndPendingPlans')}
            </Heading>
            <AccountPlansListActions />
          </HStack>
        }
        noDataContent={
          <HStack justifyContent="center" alignItems="center">
            <Icon as={TriangleAlertIcon} w={8} h={8} mr={4} />
            <VStack justifyContent="flex-start" alignItems="flex-start">
              <Text whiteSpace="pre-wrap">
                <Trans
                  t={t as TFunction}
                  i18nKey="features:account.noActiveOrPendingPlans"
                >
                  no-active-or-pending-plans
                  <Link
                    as={CrudCreateLink}
                    addReturnPath
                    dataType={DataType.AccountPlan}
                  >
                    attach-plan-or-plan-group
                  </Link>
                  alternatively
                  <Link as={NamedLink} name="create-custom-plan">
                    create-custom-plan
                  </Link>
                  or
                  <Link as={NamedLink} name="create-custom-plan-group">
                    create-custom-plan-group
                  </Link>
                </Trans>
              </Text>
            </VStack>
          </HStack>
        }
      />
      <AccountPlansList
        planAccountPlanRows={previousPlanAccountPlans}
        planGroupAccountPlans={previousPlanGroupAccountPlans}
        isLoading={isLoading}
        header={
          <Heading size="md" py={1}>
            {t('features:account.previousPlans')}
          </Heading>
        }
        noDataContent={
          <Text textAlign="center">
            {t('features:account.noPreviousPlans')}
          </Text>
        }
      />
    </VStack>
  );
};
