import React from 'react';

import { Box, FormControl, FormLabel, StyleProps } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { NumericSelect, NumericSelectOption } from '@m3ter-com/ui-components';

import { ListSize } from '@/types/lists';

import { useCrudListContext } from './CrudListContext';

export interface CrudListSizeSelectProps extends StyleProps {}

const listSizeOptions: Array<NumericSelectOption> = [
  5, 10, 20, 50, 100, 200,
].map((value) => ({
  label: `${value}`,
  value,
}));

export const CrudListSizeSelect: React.FC<CrudListSizeSelectProps> = ({
  ...styleProps
}) => {
  const { t } = useTranslation();

  const { listSize, onListSizeChange } = useCrudListContext();

  return (
    <FormControl
      alignItems="center"
      display="flex"
      flexFlow="row nowrap"
      width="max-content"
      {...styleProps}
    >
      <FormLabel fontWeight="normal" marginBottom="none" whiteSpace="nowrap">
        {t('common:itemsPerPage')}
      </FormLabel>
      <Box width="150px">
        <NumericSelect
          onChange={onListSizeChange as (newListSize: ListSize | null) => void} // We can safely cast here since we aren't making the <Select> clearable
          options={listSizeOptions}
          value={listSize}
          menuPlacement="top"
        />
      </Box>
    </FormControl>
  );
};
