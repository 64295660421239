import React, { useMemo } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, VStack } from '@chakra-ui/react';
import { XIcon } from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useFormError, useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack, IconButton, SelectOption } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormAddRowButton,
  FormField,
  FormMultiRowGridWrapper,
  FormMultiRowWrapper,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import {
  integrationAccessor,
  integrationDetailsAccessor,
} from '@/util/integrations';
import { createStepTwo } from '@/validation/externalMapping';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import { ExternalMappingM3terIdField } from '@/components/features/external-mappings/ExternalMappingM3terIdField';
import { ExternalMappingExternalIdField } from '@/components/features/external-mappings/ExternalMappingExternalIdField';

import {
  ExternalMappingCreateFormStepOneValues,
  ExternalMappingCreateFormStepTwoValues,
  ExternalMappingCreateMode,
} from './types';
import { ExternalMappingFormPrerequisiteFieldsDisplay } from './ExternalMappingFormPrerequisiteFieldsDisplay';

interface ExternalMappingCreateFormStepTwoFieldsProps {
  isSaving: boolean;
  stepOneChoices: ExternalMappingCreateFormStepOneValues;
  onCancel?: () => void;
  onBack: () => void;
}

export interface ExternalMappingCreateFormStepTwoProps
  extends BaseFormProps<ExternalMappingCreateFormStepTwoValues> {
  mode: ExternalMappingCreateMode;
  stepOneChoices?: ExternalMappingCreateFormStepOneValues;
  onBack: () => void;
}

const MAX_MAPPINGS = 20;

const ExternalMappingCreateFormStepTwoMultiMappingFields: React.FC<
  ExternalMappingCreateFormStepTwoFieldsProps
> = ({ isSaving, onBack, onCancel, stepOneChoices }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.ExternalMapping);

  const { control } = useFormContext<ExternalMappingCreateFormStepTwoValues>();
  const mappingsFieldName = 'mappings';
  const { fields, append, remove } =
    useFieldArray<ExternalMappingCreateFormStepTwoValues>({
      control,
      name: mappingsFieldName,
    });
  const { isInvalid: isRootFieldInvalid, message: rootFieldErrorMessage } =
    useFormError(mappingsFieldName);

  return (
    <VStack alignItems="flex-start" justifyContent="flex-start" spacing={6}>
      <ExternalMappingFormPrerequisiteFieldsDisplay
        externalSystem={stepOneChoices.externalSystem}
        externalTable={stepOneChoices.externalTable}
        m3terEntity={stepOneChoices.m3terEntity}
      />
      <FormSection
        heading={`${t('common:externalMappings')} ${t(
          'forms:labels.maximumWithNumber',
          {
            max: MAX_MAPPINGS,
          }
        )}`}
      >
        <FormMultiRowWrapper
          hasFields={fields.length > 0}
          emptyContentMessage={t('features:integrations.noExternalMappings')}
        >
          {fields.map((field, fieldIndex) => {
            const isFirstField = fieldIndex === 0;
            const canRemove = !isFirstField;
            const usedM3terIds = fields
              .map((f) => f.m3terId)
              .filter((m3terId, i) => !!m3terId && i !== fieldIndex);
            const m3terIdOptionsFilter = (option: SelectOption) =>
              !usedM3terIds.includes(option.value);

            return (
              <FormMultiRowGridWrapper
                key={field.id}
                alignItems="end"
                columnCount={3}
              >
                <ExternalMappingM3terIdField
                  entityType={stepOneChoices.m3terEntity}
                  externalSystem={stepOneChoices.externalSystem}
                  externalTable={stepOneChoices.externalTable}
                  hideLabel={canRemove}
                  name={`mappings.${fieldIndex}.m3terId`}
                  optionsFilter={m3terIdOptionsFilter}
                />
                <ExternalMappingExternalIdField
                  externalSystem={stepOneChoices.externalSystem}
                  m3terEntityType={stepOneChoices.m3terEntity}
                  name={`mappings.${fieldIndex}.externalId`}
                  hideLabel={canRemove}
                />
                <FormField
                  name={`mappings.${fieldIndex}.integrationConfigId`}
                  label={t('features:externalMappings.parentIntegration')}
                  helpText={
                    canRemove
                      ? undefined
                      : t('forms:helpText.externalMappingParentIntegration')
                  }
                  hideLabel={canRemove}
                  control={
                    FormEntitySelect as DataTypeFormEntitySelect<DataType.Integration>
                  }
                  isClearable
                  accessor={integrationAccessor}
                  dataType={DataType.Integration}
                  detailAccessor={integrationDetailsAccessor}
                />
                <IconButton
                  aria-label={t('common:remove')}
                  icon={<XIcon />}
                  onClick={() => remove(fieldIndex)}
                  gridRow="1"
                  gridColumn="4"
                />
              </FormMultiRowGridWrapper>
            );
          })}
        </FormMultiRowWrapper>
        <FormAddRowButton
          maxRows={MAX_MAPPINGS}
          isDisabled={fields.length >= MAX_MAPPINGS}
          onAdd={() => {
            append({});
          }}
        />
        {isRootFieldInvalid && (
          <FormControl isInvalid={isRootFieldInvalid}>
            <FormErrorMessage>{rootFieldErrorMessage}</FormErrorMessage>
          </FormControl>
        )}
      </FormSection>
      <FormActions
        backText={t('common:back')}
        cancelText={t('common:cancel')}
        isSaving={isSaving}
        onBack={onBack}
        onCancel={onCancel}
        submitText={t('forms:buttons.createEntity', {
          entityName: entityNamings.singularLower,
        })}
      />
    </VStack>
  );
};

const ExternalMappingCreateFormStepTwoSingleMappingFields: React.FC<
  ExternalMappingCreateFormStepTwoFieldsProps
> = ({ isSaving, onBack, onCancel, stepOneChoices }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.ExternalMapping);

  return (
    <FormStack>
      <ExternalMappingFormPrerequisiteFieldsDisplay
        externalSystem={stepOneChoices.externalSystem}
        externalTable={stepOneChoices.externalTable}
        m3terEntity={stepOneChoices.m3terEntity}
      />
      <FormSection
        heading={t('common:entityDetails', {
          entityName: entityNamings.singular,
        })}
      >
        <ExternalMappingM3terIdField
          entityType={stepOneChoices.m3terEntity}
          externalSystem={stepOneChoices.externalSystem}
          externalTable={stepOneChoices.externalTable}
          name="mappings.0.m3terId"
        />
        <ExternalMappingExternalIdField
          externalSystem={stepOneChoices.externalSystem}
          m3terEntityType={stepOneChoices.m3terEntity}
          name="mappings.0.externalId"
        />
        <FormField
          name="mappings.0.integrationConfigId"
          label={t('features:externalMappings.parentIntegration')}
          helpText={t('forms:helpText.externalMappingParentIntegration')}
          control={
            FormEntitySelect as DataTypeFormEntitySelect<DataType.Integration>
          }
          isClearable
          accessor={integrationAccessor}
          dataType={DataType.Integration}
          detailAccessor={integrationDetailsAccessor}
        />
      </FormSection>
      <FormActions
        backText={t('common:back')}
        cancelText={t('common:cancel')}
        isSaving={isSaving}
        onBack={onBack}
        onCancel={onCancel}
        submitText={t('forms:buttons.createEntity', {
          entityName: entityNamings.singularLower,
        })}
      />
    </FormStack>
  );
};

export const ExternalMappingCreateFormStepTwo: React.FC<
  ExternalMappingCreateFormStepTwoProps
> = ({
  initialValues,
  isSaving,
  mode,
  onBack,
  onCancel,
  onSave,
  stepOneChoices,
}) => {
  const defaultedInitialValues = useMemo(
    () => ({
      mappings: initialValues?.mappings || [],
    }),
    [initialValues]
  );

  return (
    <Form
      initialValues={defaultedInitialValues}
      onSubmit={onSave}
      validationSchema={createStepTwo}
    >
      {!!stepOneChoices && mode === ExternalMappingCreateMode.Multiple && (
        <ExternalMappingCreateFormStepTwoMultiMappingFields
          isSaving={isSaving}
          onBack={onBack}
          onCancel={onCancel}
          stepOneChoices={stepOneChoices}
        />
      )}
      {!!stepOneChoices && mode === ExternalMappingCreateMode.Single && (
        <ExternalMappingCreateFormStepTwoSingleMappingFields
          isSaving={isSaving}
          onBack={onBack}
          onCancel={onCancel}
          stepOneChoices={stepOneChoices}
        />
      )}
    </Form>
  );
};
