import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRoute } from '@/components/common/crud/CrudRoute';
import { ServiceUserCredentialsContextProvider } from '@/components/features/access/service-user/ServiceUserCredentialsContext';

export const ServiceUsersRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ServiceUserCredentialsContextProvider>
      <CrudRoute
        dataType={DataType.ServiceUser}
        title={t('common:serviceUsers')}
      />
    </ServiceUserCredentialsContextProvider>
  );
};
