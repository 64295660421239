import React, { useMemo } from 'react';

import {
  AggregationFrequency,
  DataExportTimePeriodType,
  ExportSchedule,
  ScheduleType,
  SourceType,
} from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate';
import { ExportScheduleForm } from '@/components/features/data-exports/ExportScheduleForm';

export const ExportSchedulesCreateRoute: React.FC = () => {
  const initialValues = useMemo(
    () => ({
      aggregationFrequency: AggregationFrequency.Original,
      sourceType: SourceType.Usage,
      timePeriod: DataExportTimePeriodType.Today,
      scheduleType: ScheduleType.Hourly,
      period: 1,
    }),
    []
  );

  return (
    <CrudCreate<ExportSchedule>
      form={ExportScheduleForm}
      initialValues={initialValues}
    />
  );
};
