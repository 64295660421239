import { create, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import { CompoundAggregation } from '@m3ter-com/m3ter-api';

import { validateCustomFields } from './customFields';

const suite = create(
  'compoundAggregation',
  (data: Partial<CompoundAggregation>) => {
    test('name', i18next.t('forms:validations.common.nameRequired'), () => {
      enforce(data.name).isNotEmpty();
    });

    test('name', i18next.t('forms:validations.common.nameMaxLength'), () => {
      enforce(data.name).shorterThanOrEquals(200);
    });

    test('code', i18next.t('forms:validations.common.codeRequired'), () => {
      enforce(data.code).isNotEmpty();
    });

    test(
      'calculation',
      i18next.t('forms:validations.common.calculationRequired'),
      () => {
        enforce(data.calculation).isNotEmpty();
      }
    );

    test('unit', i18next.t('forms:validations.common.unitRequired'), () => {
      enforce(data.unit).isNotEmpty();
    });

    test(
      'rounding',
      i18next.t('forms:validations.aggregation.roundingRequired'),
      () => {
        enforce(data.rounding).isNotEmpty();
      }
    );

    test(
      'quantityPerUnit',
      i18next.t('forms:validations.aggregation.quantityRequired'),
      () => {
        enforce(data.rounding).isNotEmpty();
      }
    );

    omitWhen(data.quantityPerUnit === undefined, () => {
      test(
        'quantityPerUnit',
        i18next.t('forms:validations.aggregation.quantityPositive'),
        () => {
          // Seems to be a strange error with vests isPositive method expecting an argument that's not in the docs...
          enforce(data.quantityPerUnit!).greaterThan(0);
        }
      );
    });

    validateCustomFields('customFields', data.customFields);
  }
);

export default suite;
