import { ExportAdhoc, Id, UnsavedEntity } from '../types';

import { getOrganizationPath } from '../util/path';
import { post } from '../client';

export const createAdhocDataExport = (
  organizationId: Id,
  body: UnsavedEntity<ExportAdhoc>
) =>
  post({
    body,
    path: getOrganizationPath('/dataexports/adhoc'),
    pathParams: {
      organizationId,
    },
  }) as Promise<ExportAdhoc>;
