import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';

import { useBreadcrumbsState } from '@/state/breadcrumbs';

export const Breadcrumbs: React.FC = () => {
  const breadcrumbs = useBreadcrumbsState();

  const breadcrumbsToShow = useMemo(
    // Remove hidden and final breadcrumb.
    () => breadcrumbs.filter(({ hideInTrail }) => !hideInTrail).slice(0, -1),
    [breadcrumbs]
  );

  return breadcrumbsToShow.length > 0 ? (
    <Breadcrumb>
      {breadcrumbsToShow.map(({ to, title }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <BreadcrumbItem key={index}>
          {to ? (
            <BreadcrumbLink as={Link} to={to}>
              {title}
            </BreadcrumbLink>
          ) : (
            <Text>{title}</Text>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  ) : null;
};
