import { useQuery } from '@tanstack/react-query';

import { DataType } from '@m3ter-com/m3ter-api';

import { dataTypeListAllQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';

const usePlanTemplates = () => {
  const pathParams = useOrgPathParams();
  const {
    data = [],
    isLoading,
    isSuccess,
    error,
  } = useQuery(
    dataTypeListAllQuery({
      dataType: DataType.PlanTemplate,
      pathParams,
    })
  );

  return {
    error,
    isLoaded: isSuccess,
    isLoading,
    planTemplates: data,
  };
};

export default usePlanTemplates;
