import React from 'react';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Card,
  CardBody,
  SimpleGrid,
} from '@chakra-ui/react';
import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';
import { DollarSignIcon, HeartPulseIcon } from 'lucide-react';

import { AlertLink } from '@m3ter-com/ui-components';
import { AnalyticsJobType } from '@m3ter-com/console-core/types';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { NamedLink } from '@/components/common/navigation/NamedLink';
import { ReportCard } from '@/components/features/reports/ReportCard';

export const AccountsReportsListRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        <SimpleGrid columns={3} gridGap={4} mb={4}>
          <ReportCard
            description={t('features:reports.prepaymentsStatus.summary')}
            icon={HeartPulseIcon}
            reportType={AnalyticsJobType.PrepaymentsStatusReport}
            title={t('features:reports.prepaymentsStatus.title')}
          />
          <ReportCard
            description={t('features:reports.totalContractValue.summary')}
            icon={DollarSignIcon}
            reportType={AnalyticsJobType.TotalContractValueReport}
            title={t('features:reports.totalContractValue.title')}
          />
        </SimpleGrid>
        <Alert status="info">
          <AlertIcon />
          <AlertDescription>
            <Trans
              t={t as TFunction}
              i18nKey="features:reports.lookingForBillingReports"
            >
              Looking for…? Head to
              <AlertLink as={NamedLink} name="billing.reports">
                billing reports
              </AlertLink>
            </Trans>
          </AlertDescription>
        </Alert>
      </CardBody>
    </Card>
  );
};
