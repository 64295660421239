import React, { useMemo } from 'react';

import { DataType, Destination } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
  FormTextarea,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { BaseFormProps, InitialValues } from '@/types/forms';

import destinationSchema from '@/validation/integrationDestination';
import useEntityNamings from '@/hooks/util/useEntityNamings';

const defaultInitialValues: InitialValues<Destination> = {};

interface DestinationFormProps extends BaseFormProps<Destination> {}

export const DestinationForm: React.FC<DestinationFormProps> = ({
  isEdit,
  isSaving,
  initialValues = defaultInitialValues,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Destination);

  // These will never change, and should not be edited by the user as it is a fixed value required for credentials.
  const initialValuesWithCredentials = useMemo(
    () => ({
      ...initialValues,
      credentials: {
        ...initialValues.credentials,
        type: 'M3TER_SIGNED_REQUEST',
      },
    }),
    [initialValues]
  );

  return (
    <Form
      initialValues={initialValuesWithCredentials}
      onSubmit={onSave}
      validationSchema={destinationSchema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <NameCodeFields />
          <FormField
            isRequired
            name="description"
            label={t('forms:labels.description')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="url"
            label={t('forms:labels.url')}
            control={FormTextarea}
          />
        </FormSection>
        <FormSection heading={t('features:integrations.credentials')}>
          <FormField
            isReadOnly
            name="credentials.type"
            label={t('features:integrations.credentialType')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="credentials.apiKey"
            label={t('features:integrations.apiKey')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="credentials.secret"
            label={t('features:integrations.apiSecret')}
            control={FormInput}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', {
                  entityName: entityNamings.singularLower,
                })
              : t('forms:buttons.createEntity', {
                  entityName: entityNamings.singularLower,
                })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
