import React, { ComponentProps, useCallback, useMemo } from 'react';

import { addDays, subDays } from 'date-fns';

import { DateRange } from '@m3ter-com/console-core/types';
import { getDateValuesForDateRange } from '@m3ter-com/console-core/utils';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  DateRangePicker,
  DateStringRange,
  PredefinedRange,
} from '@m3ter-com/ui-components';

import useDateFormatter from '@/hooks/util/useDateFormatter';

export interface ExclusiveDatePickerProps
  extends Omit<ComponentProps<typeof DateRangePicker>, 'ranges'> {
  dateRanges?: Array<DateRange>;
}

export const ExclusiveDateRangePicker: React.FC<ExclusiveDatePickerProps> = ({
  onChange,
  value,
  dateRanges = [],
  ...rangePickerProps
}) => {
  const { t } = useTranslation();
  const { timeZone } = useDateFormatter();

  const convertedValue = useMemo<DateStringRange | null>(
    () =>
      value
        ? {
            start: value.start,
            end: subDays(new Date(value.end), 1).toISOString(),
          }
        : null,
    [value]
  );

  const handleChange = useCallback(
    (newValue: DateStringRange | null) => {
      onChange(
        newValue
          ? {
              start: newValue.start,
              end: addDays(new Date(newValue.end), 1).toISOString(),
            }
          : null
      );
    },
    [onChange]
  );

  // Convert date range enums to ranges, removing exclusive end dates.
  const ranges = useMemo<Array<PredefinedRange>>(
    () =>
      dateRanges.map((range) => {
        const values = getDateValuesForDateRange(range, timeZone);
        return {
          label: t(`common:dateRanges.${range}`),
          start: values.start,
          end: subDays(new Date(values.end), 1).toISOString(),
        };
      }),
    [dateRanges, timeZone, t]
  );

  return (
    <DateRangePicker
      {...rangePickerProps}
      value={convertedValue}
      onChange={handleChange}
      ranges={ranges}
    />
  );
};
