import React from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert } from '@m3ter-com/ui-components';

import { WidgetBody } from '@/components/widgets/WidgetBody';

export const LazyLoadFailureWidget: React.FC = () => {
  const { t } = useTranslation();

  return (
    <WidgetBody>
      <Alert status="error" width="100%">
        {t('features:widgets.componentLoadFailure')}
      </Alert>
    </WidgetBody>
  );
};
