import { useCallback } from 'react';

import { useNavigate, useResolvedPath } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import {
  DataType,
  Id,
  SupportAccess,
  update,
  updateEntityPermissionPolicies,
} from '@m3ter-com/m3ter-api';

import { SupportAccessUpdateFormValues } from '@/components/features/access/support-access/SupportAccessForm';
import useOrg from '@/hooks/data/crud/useOrg';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import { ids } from '@/util/data';
import { dataUpdated } from '@/store/data/data';

import useEntityPermissionPolicyList from './useEntityPermissionPolicyList';

export const updateSupportAccessAndPermissionPolicies = async (
  { permissionPolicyIds, ...supportAccess }: SupportAccessUpdateFormValues,
  organizationId: Id,
  currentPermissionPolicyIds: Array<Id>
): Promise<SupportAccess> => {
  const updatedSupportAccess = await update({
    data: supportAccess,
    dataType: DataType.SupportAccess,
    pathParams: { organizationId },
  });

  const permissionPolicyIdsToAdd = permissionPolicyIds.filter(
    (id) => !currentPermissionPolicyIds.includes(id)
  );
  if (permissionPolicyIdsToAdd.length > 0) {
    await updateEntityPermissionPolicies(
      'addtosupportusers',
      organizationId,
      permissionPolicyIdsToAdd
    );
  }

  const permissionPolicyIdsToRemove = currentPermissionPolicyIds.filter(
    (id) => !permissionPolicyIds.includes(id)
  );
  if (permissionPolicyIdsToRemove.length > 0) {
    await updateEntityPermissionPolicies(
      'removefromsupportusers',
      organizationId,
      permissionPolicyIdsToRemove
    );
  }

  return updatedSupportAccess;
};

const useSupportAccessUpdate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useResolvedPath('..');
  const { currentOrgId: organizationId } = useOrg();

  const queryClient = useQueryClient();

  const {
    data: supportAccess,
    isLoading: isLoadingSupportAccess,
    error: errorLoadingSupportAccess,
  } = useQuery(
    dataTypeRetrieveQuery({
      dataType: DataType.SupportAccess,
      pathParams: { organizationId },
    })
  );

  const {
    permissionPolicies,
    permissionPoliciesLoading,
    permissionPoliciesError,
  } = useEntityPermissionPolicyList({ dataType: DataType.SupportAccess });

  const {
    error: updateError,
    isPending: isSaving,
    mutate: updateSupportAccess,
  } = useMutation({
    mutationFn: async (data: SupportAccessUpdateFormValues) =>
      updateSupportAccessAndPermissionPolicies(
        data,
        organizationId,
        ids(permissionPolicies)
      ),
    onSuccess: (updatedSupportAccess) => {
      dispatch(dataUpdated(DataType.SupportAccess, [updatedSupportAccess]));
      queryClient.invalidateQueries({
        queryKey: [DataType.SupportAccess],
      });
      navigate(pathname);
    },
  });

  const onSave = useCallback(
    (data: SupportAccessUpdateFormValues) => {
      updateSupportAccess(data);
    },
    [updateSupportAccess]
  );

  const onCancel = useCallback(() => {
    navigate(pathname);
  }, [navigate, pathname]);

  const isLoading = isLoadingSupportAccess || permissionPoliciesLoading;
  const loadError = errorLoadingSupportAccess || permissionPoliciesError;

  return {
    supportAccess,
    updateError,
    loadError,
    isLoading,
    isSaving,
    onCancel,
    onSave,
    permissionPolicyIds: ids(permissionPolicies),
  };
};

export default useSupportAccessUpdate;
