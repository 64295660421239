import React from 'react';

import { PlanTemplate } from '@m3ter-com/m3ter-api';

import usePlanDetails from '@/hooks/features/plans/usePlanDetails';
import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { PlanTemplateDetails } from '@/components/features/pricing/PlanTemplateDetails';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { PlanDetailsPricingGrid } from '@/components/features/pricing/PlanDetailsPricingGrid';

export const PlanTemplateDetailsRouteComponent: React.FC<{
  data: PlanTemplate;
}> = ({ data }) => {
  const {
    error,
    extraPricingAggregations,
    extraPricingCompoundAggregations,
    extraPricingItemCounters,
    isLoading,
    isLoadingExtraUsageEntities,
    pricingData,
  } = usePlanDetails(data.id);

  return (
    <PlanTemplateDetails showEditButton planTemplate={data}>
      {!!error && <ErrorAlert error={error} />}
      <PlanDetailsPricingGrid
        canEditPricing
        isLoading={isLoading || isLoadingExtraUsageEntities}
        extraPricingAggregations={extraPricingAggregations}
        extraPricingCompoundAggregations={extraPricingCompoundAggregations}
        extraPricingItemCounters={extraPricingItemCounters}
        pricingData={pricingData}
      />
    </PlanTemplateDetails>
  );
};

export const PlanTemplatesDetailsRoute: React.FC = () => (
  <CrudDetails<PlanTemplate> component={PlanTemplateDetailsRouteComponent} />
);
