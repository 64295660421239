import { useMemo } from 'react';

import { CustomFields, DataType, Entity } from '@m3ter-com/m3ter-api';

import { isEntityWithCustomFields } from '@/util/data';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useEntityExternalMappings from '@/hooks/features/externalMappings/useEntityExternalMappings';

const useEntityMetadata = (data: Entity, dataType: DataType) => {
  const entityNamings = useEntityNamings(dataType);

  const customFields = useMemo<CustomFields>(
    () =>
      isEntityWithCustomFields(data) && data.customFields
        ? data.customFields
        : {},
    [data]
  );
  const customFieldsKeys = useMemo<Array<string>>(() => {
    const keys = Object.keys(customFields);
    keys.sort();
    return keys;
  }, [customFields]);

  const {
    entityExternalMappings,
    entityExternalMappingsError,
    isLoadingExternalMappings,
  } = useEntityExternalMappings(dataType, data.id);

  return {
    customFields,
    customFieldsKeys,
    entityExternalMappings,
    entityExternalMappingsError,
    entityNamings,
    isLoadingExternalMappings,
  };
};

export default useEntityMetadata;
