import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { PlanGroup } from '@m3ter-com/m3ter-api';

import { validateCustomFields } from './customFields';

const suite = create('planGroup', (data: Partial<PlanGroup>) => {
  test('name', i18next.t('forms:validations.common.nameRequired'), () => {
    enforce(data.name).isNotEmpty();
  });

  test('code', i18next.t('forms:validations.common.codeRequired'), () => {
    enforce(data.code).isNotEmpty();
  });

  test(
    'currency',
    i18next.t('forms:validations.common.currencyRequired'),
    () => {
      enforce(data.currency).isNotEmpty();
    }
  );

  validateCustomFields('customFields', data.customFields);
});

export default suite;
