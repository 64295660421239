import {
  all,
  call,
  put,
  takeEvery,
  StrictEffect,
  select,
  ForkEffect,
} from 'redux-saga/effects';
import { AnyAction } from '@reduxjs/toolkit';

import {
  Account,
  AccountPlan,
  Commitment,
  DataType,
  Destination,
  updateEntityPermissionPolicy,
  Id,
  create,
  removeUserFromUserGroup,
  addUserToUserGroup,
} from '@m3ter-com/m3ter-api';

import { EntityRouteListIds, OtherListIds } from '@/types/lists';
import {
  PermissionDataType,
  permissionDataTypeConfigMap,
} from '@/types/data/permissions';

import { extractErrorMessage } from '@/util/error';
import type { NotificationDefinition } from '@/store/store';
import { refreshList } from '@/store/crud';
import {
  createData,
  listAllData,
  retrieveData,
  updateData,
} from '@/store/data/data.saga';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';
import { loadNotificationDestinations } from '@/store/features/events-notifications/notifications';

import {
  addEntityLink,
  addEntityLinkFailure,
  addEntityLinkSuccess,
  AddEntityLinkAction,
  removeEntityLink,
  removeEntityLinkFailure,
  RemoveEntityLinkAction,
  removeEntityLinkSuccess,
} from './linkEntity';

function* entityLinkFailureSaga(
  parentDataType: DataType,
  childDataType: DataType,
  error: unknown
): Generator<StrictEffect, void, any> {
  const errorMessage = extractErrorMessage(error);
  const failureNotification: NotificationDefinition | undefined = errorMessage
    ? {
        type: 'error',
        message: errorMessage,
      }
    : undefined;
  yield put(
    addEntityLinkFailure(parentDataType, childDataType, {
      notification: failureNotification,
    })
  );
}

function* entityUnlinkFailureSaga(
  parentDataType: DataType,
  childDataType: DataType,
  error: unknown
): Generator<StrictEffect, void, any> {
  const errorMessage = extractErrorMessage(error);
  const failureNotification: NotificationDefinition | undefined = errorMessage
    ? {
        type: 'error',
        message: errorMessage,
      }
    : undefined;
  yield put(
    removeEntityLinkFailure(parentDataType, childDataType, {
      notification: failureNotification,
    })
  );
}

export function* addAccountChildrenSaga(
  action: AddEntityLinkAction
): Generator<StrictEffect, void, any> {
  const { parentId: parentAccountId, childIds: childAccountIds } =
    action.payload;
  const { onSuccess = {} } = action.meta;

  try {
    // Load all the new child accounts
    const response: Array<Account> = yield call(listAllData, DataType.Account, {
      ids: childAccountIds,
    });

    // Update the parent account (staticEntityId), making requests in parallel.
    if (response.length > 0 && parentAccountId) {
      yield all(
        response.map((account) =>
          call(updateData, DataType.Account, account.id, {
            ...account,
            parentAccountId,
          })
        )
      );
    }
    yield put(
      addEntityLinkSuccess(DataType.Account, DataType.Account, onSuccess)
    );
    yield put(refreshList(DataType.Account, OtherListIds.AccountChildren));
  } catch (error) {
    yield call(
      entityLinkFailureSaga,
      DataType.Account,
      DataType.Account,
      error
    );
  }
}

export function* removeAccountChildSaga(
  action: RemoveEntityLinkAction
): Generator<StrictEffect, void, any> {
  const { childId: childAccountId } = action.payload;
  const { onSuccess = {} } = action.meta;

  try {
    if (childAccountId) {
      const account: Account = yield call(
        retrieveData,
        DataType.Account,
        childAccountId
      );

      yield call(updateData, DataType.Account, account.id, {
        ...account,
        parentAccountId: null,
      });
    }
    yield put(
      removeEntityLinkSuccess(DataType.Account, DataType.Account, onSuccess)
    );
    yield put(refreshList(DataType.Account, OtherListIds.AccountChildren));
  } catch (error) {
    yield call(
      entityUnlinkFailureSaga,
      DataType.Account,
      DataType.Account,
      error
    );
  }
}

export function* addContractAccountPlanLinkSaga(
  action: AddEntityLinkAction
): Generator<StrictEffect, void, any> {
  const { parentId: contractId, childIds: accountPlanIds } = action.payload;
  const { onSuccess = {} } = action.meta;

  try {
    const accountPlans: Array<AccountPlan> = yield call(
      listAllData,
      DataType.AccountPlan,
      { ids: accountPlanIds }
    );

    if (accountPlans.length > 0 && contractId) {
      const accountPlanUpdateCalls = accountPlans.map((accountPlan) =>
        call(updateData, DataType.AccountPlan, accountPlan.id, {
          ...accountPlan,
          contractId,
        })
      );
      yield all(accountPlanUpdateCalls);
    }

    yield put(
      addEntityLinkSuccess(DataType.Contract, DataType.AccountPlan, onSuccess)
    );
  } catch (error) {
    yield call(
      entityLinkFailureSaga,
      DataType.Contract,
      DataType.AccountPlan,
      error
    );
  }
}

export function* addContractCommitmentLinkSaga(
  action: AddEntityLinkAction
): Generator<StrictEffect, void, any> {
  const { parentId: contractId, childIds: commitmentIds } = action.payload;
  const { onSuccess = {} } = action.meta;

  try {
    const commitments: Array<Commitment> = yield call(
      listAllData,
      DataType.Commitment,
      { ids: commitmentIds }
    );

    if (commitments.length > 0 && contractId) {
      const commitmentUpdateCalls = commitments.map((commitment) =>
        call(updateData, DataType.Commitment, commitment.id, {
          ...commitment,
          contractId,
        })
      );
      yield all(commitmentUpdateCalls);
    }

    yield put(
      addEntityLinkSuccess(DataType.Contract, DataType.Commitment, onSuccess)
    );
  } catch (error) {
    yield call(
      entityLinkFailureSaga,
      DataType.Contract,
      DataType.Commitment,
      error
    );
  }
}

export function* addPlanGroupLinksSaga(
  action: AddEntityLinkAction
): Generator<StrictEffect, void, any> {
  const { parentId: planGroupId, childIds: planIds } = action.payload;
  const { onSuccess = {} } = action.meta;

  try {
    if (planIds.length > 0 && planGroupId) {
      yield all(
        planIds.map((planId) =>
          call(createData, DataType.PlanGroupLink, { planId, planGroupId })
        )
      );
    }
    yield put(
      addEntityLinkSuccess(DataType.PlanGroup, DataType.Plan, onSuccess)
    );
    yield put(
      refreshList(DataType.PlanGroupLink, EntityRouteListIds.PlanGroupLink)
    );
  } catch (error) {
    yield call(entityLinkFailureSaga, DataType.PlanGroup, DataType.Plan, error);
  }
}

export function* addNotificationRuleDestinationSaga(
  action: AddEntityLinkAction
): Generator<StrictEffect, void, any> {
  const { parentId: notificationRuleId, childIds: destinationIds } =
    action.payload;
  const { onSuccess = {} } = action.meta;
  const organizationId = yield select(selectCurrentOrgId);

  try {
    if (destinationIds.length > 0 && notificationRuleId) {
      const destinationsToLink: Array<Destination> = yield call(
        listAllData,
        DataType.Destination,
        { ids: destinationIds }
      );

      const createNotificationRuleToDestinationLinkCalls =
        destinationsToLink.map((destination) =>
          call(create, {
            dataType: DataType.Integration,
            pathParams: { organizationId },
            data: {
              entityType: 'Notification',
              entityId: notificationRuleId,
              destination: 'Webhook',
              destinationId: destination.id,
            },
          })
        );

      yield all(createNotificationRuleToDestinationLinkCalls);
      yield put(loadNotificationDestinations(notificationRuleId));
    }

    yield put(
      addEntityLinkSuccess(
        DataType.NotificationRule,
        DataType.Destination,
        onSuccess
      )
    );
  } catch (error) {
    yield call(
      entityLinkFailureSaga,
      DataType.NotificationRule,
      DataType.Destination,
      error
    );
  }
}

export function* addOrgUserSaga(
  action: AddEntityLinkAction
): Generator<StrictEffect, void, any> {
  const { parentId: organizationId, childIds: userIds } = action.payload;
  const { onSuccess = {} } = action.meta;

  try {
    if (userIds.length > 0 && organizationId) {
      yield all(
        userIds.map((userId) =>
          call(create, {
            dataType: DataType.User,
            pathParams: { organizationId },
            data: { id: userId },
          })
        )
      );
    }
    yield put(
      addEntityLinkSuccess(
        DataType.OrganizationAdmin,
        DataType.UserAdmin,
        onSuccess
      )
    );
  } catch (error) {
    yield call(
      entityLinkFailureSaga,
      DataType.OrganizationAdmin,
      DataType.UserAdmin,
      error
    );
  }
}

export function* addOrgUserPermissionsSaga(
  action: AddEntityLinkAction
): Generator<StrictEffect, void, any> {
  const {
    parentId: userId,
    childIds: permissionIds,
    orgId: organizationId,
  } = action.payload;
  const { onSuccess = {} } = action.meta;

  try {
    if (permissionIds.length > 0 && userId && organizationId) {
      yield all(
        permissionIds.map((permissionPolicyId) =>
          call(
            updateEntityPermissionPolicy,
            'addtouser/admin',
            organizationId,
            permissionPolicyId,
            { principalId: userId }
          )
        )
      );
    }
    yield put(
      addEntityLinkSuccess(
        DataType.UserAdmin,
        DataType.PermissionPolicy,
        onSuccess
      )
    );
  } catch (error) {
    yield call(
      entityLinkFailureSaga,
      DataType.UserAdmin,
      DataType.PermissionPolicy,
      error
    );
  }
}
export function* addPermissionsToEntitySaga(
  action: AddEntityLinkAction
): Generator<StrictEffect, void, any> {
  const {
    parentDataType,
    parentId: principalId,
    childIds: permissionPolicyIds,
  } = action.payload;
  const { onSuccess = {} } = action.meta;
  const { addAction, listId } =
    permissionDataTypeConfigMap[parentDataType as PermissionDataType];

  // When assigning permissions to support access entity we don't have a principal Id.
  const data =
    parentDataType !== DataType.SupportAccess && principalId
      ? { principalId }
      : undefined;

  try {
    const organizationId: Id = yield select(selectCurrentOrgId);

    if (permissionPolicyIds.length > 0) {
      yield all(
        permissionPolicyIds.map((permissionPolicyId) =>
          call(
            updateEntityPermissionPolicy,
            addAction,
            organizationId,
            permissionPolicyId,
            data
          )
        )
      );
    }

    yield put(
      addEntityLinkSuccess(parentDataType, DataType.PermissionPolicy, onSuccess)
    );
    yield put(refreshList(DataType.PermissionPolicy, listId));
  } catch (error) {
    yield call(
      entityLinkFailureSaga,
      parentDataType,
      DataType.PermissionPolicy,
      error
    );
  }
}

export function* removePermissionsFromEntitySaga(
  action: RemoveEntityLinkAction
): Generator<StrictEffect, void, any> {
  const {
    parentIds: permissionPolicyIds,
    childDataType,
    childId: principalId,
  } = action.payload;
  const { onSuccess = {} } = action.meta;
  const { removeAction, listId } =
    permissionDataTypeConfigMap[childDataType as PermissionDataType];

  // When assigning permissions to support access entity we don't have a principal Id.
  const data =
    childDataType !== DataType.SupportAccess && principalId
      ? { principalId }
      : undefined;

  try {
    const organizationId: Id = yield select(selectCurrentOrgId);

    if (permissionPolicyIds && permissionPolicyIds.length > 0) {
      yield all(
        permissionPolicyIds.map((permissionPolicyId) =>
          call(
            updateEntityPermissionPolicy,
            removeAction,
            organizationId,
            permissionPolicyId,
            data
          )
        )
      );
    }

    yield put(
      removeEntityLinkSuccess(
        DataType.PermissionPolicy,
        childDataType,
        onSuccess
      )
    );
    yield put(refreshList(DataType.PermissionPolicy, listId));
  } catch (error) {
    yield call(
      entityUnlinkFailureSaga,
      DataType.PermissionPolicy,
      childDataType,
      error
    );
  }
}

export function* addUserToUserGroupsSaga(
  action: AddEntityLinkAction
): Generator<StrictEffect, void, any> {
  const { parentId: userId, childIds: userGroupIds } = action.payload;
  const { onSuccess = {} } = action.meta;

  try {
    const organizationId: Id = yield select(selectCurrentOrgId);

    if (userGroupIds.length > 0 && userId) {
      yield all(
        userGroupIds.map((id) =>
          call(addUserToUserGroup, organizationId, id, userId)
        )
      );
    }

    yield put(
      addEntityLinkSuccess(DataType.User, DataType.UserGroup, onSuccess)
    );
    yield put(refreshList(DataType.UserGroup, OtherListIds.UsersUserGroups));
  } catch (error) {
    yield call(entityLinkFailureSaga, DataType.User, DataType.UserGroup, error);
  }
}

export function* removeUserFromUserGroupsSaga(
  action: RemoveEntityLinkAction
): Generator<StrictEffect, void, any> {
  const { parentIds: userGroupIds, childId: userId } = action.payload;
  const { onSuccess = {} } = action.meta;

  try {
    const organizationId: Id = yield select(selectCurrentOrgId);

    if (userGroupIds && userGroupIds.length > 0 && userId) {
      yield all(
        userGroupIds.map((id) =>
          call(removeUserFromUserGroup, organizationId, id, userId)
        )
      );
    }

    yield put(
      removeEntityLinkSuccess(DataType.UserGroup, DataType.User, onSuccess)
    );
    yield put(refreshList(DataType.UserGroup, OtherListIds.UsersUserGroups));
  } catch (error) {
    yield call(
      entityUnlinkFailureSaga,
      DataType.UserGroup,
      DataType.User,
      error
    );
  }
}

function* watchPermissionLinkEntityActions(
  parentDataType: DataType,
  childDataType: DataType
): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(
    (action: AnyAction) =>
      action.type === addEntityLink.type &&
      action.payload.parentDataType === parentDataType &&
      action.payload.childDataType === childDataType,
    addPermissionsToEntitySaga
  );
  yield takeEvery(
    (action: AnyAction) =>
      action.type === removeEntityLink.type &&
      action.payload.parentDataType === childDataType &&
      action.payload.childDataType === parentDataType,
    removePermissionsFromEntitySaga
  );
}

export default function* linkEntitySaga() {
  yield takeEvery(
    (action: AnyAction) =>
      action.type === addEntityLink.type &&
      action.payload.parentDataType === DataType.Account &&
      action.payload.childDataType === DataType.Account,
    addAccountChildrenSaga
  );
  yield takeEvery(
    (action: AnyAction) =>
      action.type === removeEntityLink.type &&
      action.payload.parentDataType === DataType.Account &&
      action.payload.childDataType === DataType.Account,
    removeAccountChildSaga
  );
  yield takeEvery(
    (action: AnyAction) =>
      action.type === addEntityLink.type &&
      action.payload.parentDataType === DataType.Contract &&
      action.payload.childDataType === DataType.AccountPlan,
    addContractAccountPlanLinkSaga
  );
  yield takeEvery(
    (action: AnyAction) =>
      action.type === addEntityLink.type &&
      action.payload.parentDataType === DataType.Contract &&
      action.payload.childDataType === DataType.Commitment,
    addContractCommitmentLinkSaga
  );
  yield takeEvery(
    (action: AnyAction) =>
      action.type === addEntityLink.type &&
      action.payload.parentDataType === DataType.NotificationRule &&
      action.payload.childDataType === DataType.Destination,
    addNotificationRuleDestinationSaga
  );
  yield takeEvery(
    (action: AnyAction) =>
      action.type === addEntityLink.type &&
      action.payload.parentDataType === DataType.PlanGroup &&
      action.payload.childDataType === DataType.Plan,
    addPlanGroupLinksSaga
  );
  yield takeEvery(
    (action: AnyAction) =>
      action.type === addEntityLink.type &&
      action.payload.parentDataType === DataType.OrganizationAdmin &&
      action.payload.childDataType === DataType.UserAdmin,
    addOrgUserSaga
  );
  yield takeEvery(
    (action: AnyAction) =>
      action.type === addEntityLink.type &&
      action.payload.parentDataType === DataType.UserAdmin &&
      action.payload.childDataType === DataType.PermissionPolicy,
    addOrgUserPermissionsSaga
  );
  yield takeEvery(
    (action: AnyAction) =>
      action.type === addEntityLink.type &&
      action.payload.parentDataType === DataType.User &&
      action.payload.childDataType === DataType.UserGroup,
    addUserToUserGroupsSaga
  );
  yield takeEvery(
    (action: AnyAction) =>
      action.type === removeEntityLink.type &&
      action.payload.parentDataType === DataType.UserGroup &&
      action.payload.childDataType === DataType.User,
    removeUserFromUserGroupsSaga
  );

  /**
   * We call the same addPermissionsToEntity / removePermissionsFromEntity saga when adding
   * or removing permissions to entities. The sagas will determine the api to call based
   * on the parent and/or child data types passed to them via the action payload.
   */

  // User permissions.
  yield* watchPermissionLinkEntityActions(
    DataType.User,
    DataType.PermissionPolicy
  );

  // Service User permissions.
  yield* watchPermissionLinkEntityActions(
    DataType.ServiceUser,
    DataType.PermissionPolicy
  );

  // User Group permissions.
  yield* watchPermissionLinkEntityActions(
    DataType.UserGroup,
    DataType.PermissionPolicy
  );

  // Support Access permissions.
  yield* watchPermissionLinkEntityActions(
    DataType.SupportAccess,
    DataType.PermissionPolicy
  );
}
