import React from 'react';

import {
  Box,
  Heading,
  Link,
  Text,
  Stack,
  StackDivider,
  useColorModeValue,
} from '@chakra-ui/react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useRecentlyViewed from '@/hooks/navigation/useRecentlyViewed';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';

export interface RecentAccountsProps {}

export const RecentAccounts: React.FC<RecentAccountsProps> = () => {
  const { t } = useTranslation();
  const {
    isLoadingRecentlyViewed: isLoading,
    loadingRecentlyViewedError: error,
    recentlyViewed: recentAccounts,
  } = useRecentlyViewed(DataType.Account, true);

  const dividerColor = useColorModeValue('gray.300', 'gray.700');

  return (
    <Box>
      <Heading size="sm" mb={2}>
        {t('features:widgets.recentAccounts.header')}
      </Heading>
      <LoadingErrorContentSwitch isLoading={isLoading} error={error}>
        {recentAccounts.length > 0 ? (
          <Stack
            spacing={1}
            divider={<StackDivider borderColor={dividerColor} />}
          >
            {recentAccounts.slice(0, 5).map((account) => (
              <Link
                key={account.id}
                as={CrudDetailsLink}
                dataType={DataType.Account}
                id={account.id}
              >
                {account.name}
              </Link>
            ))}
          </Stack>
        ) : (
          <Text>{t('common:noRecentX', { x: t('common:accounts') })}</Text>
        )}
      </LoadingErrorContentSwitch>
    </Box>
  );
};
