import { useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { DataType, DataTypeToEntity, Id } from '@m3ter-com/m3ter-api';

import { dataTypeListQuery } from '@/queries/crud';
import {
  useRecentEntitiesState,
  useRecentlyViewedActions,
} from '@/state/recentlyViewed';
import useOrg from '@/hooks/data/crud/useOrg';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';

const useRecentlyViewed = <DT extends DataType>(
  dataType: DT,
  loadRecentlyViewed = false
) => {
  const pathParams = useOrgPathParams();
  const { currentOrgId: organizationId } = useOrg();

  const recentEntities = useRecentEntitiesState();
  const { addRecentEntity } = useRecentlyViewedActions();

  const addToRecentlyViewed = useCallback(
    (id: Id) => {
      addRecentEntity(organizationId, dataType, id);
    },
    [addRecentEntity, organizationId, dataType]
  );

  const recentEntityIds = useMemo<Array<Id>>(
    () => recentEntities[organizationId]?.[dataType] || [],
    [recentEntities, organizationId, dataType]
  );

  const {
    data: recentlyViewed = [],
    error: loadingRecentlyViewedError,
    isLoading: isLoadingRecentlyViewed,
  } = useQuery(
    dataTypeListQuery(
      {
        dataType,
        pathParams,
        queryParams: { ids: recentEntityIds },
      },
      {
        enabled: recentEntityIds.length > 0 && loadRecentlyViewed,
        keepPreviousData: true,
      }
    )
  );

  const sortedRecentlyViewed = useMemo<Array<DataTypeToEntity[DT]>>(
    () =>
      recentlyViewed.sort(
        (a, b) => recentEntityIds.indexOf(a.id) - recentEntityIds.indexOf(b.id)
      ),
    [recentEntityIds, recentlyViewed]
  );

  return {
    addToRecentlyViewed,
    isLoadingRecentlyViewed,
    lastViewed: sortedRecentlyViewed[0] as DataTypeToEntity[DT] | undefined,
    loadingRecentlyViewedError,
    recentlyViewed: sortedRecentlyViewed,
    recentEntityIds,
  };
};

export default useRecentlyViewed;
