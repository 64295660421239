import React, { useCallback, useMemo } from 'react';

import { PermissionPolicy } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';

const searchFields = ['name'];

export const PermissionsListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columns = useMemo<Array<ColumnDefinition<PermissionPolicy>>>(
    () => [
      { id: 'name', header: t('forms:labels.name'), accessor: 'name' },
      {
        id: 'type',
        header: t('forms:labels.type'),
        accessor: (item) =>
          item.managedPolicy
            ? t('features:access.managed')
            : t('common:custom'),
      },
      {
        id: 'statements',
        header: t('forms:labels.statements'),
        accessor: (item) => item.permissionPolicy.length,
      },
    ],
    [t]
  );

  const isNotManaged = useCallback(
    (item: PermissionPolicy) => !item.managedPolicy,
    []
  );

  return (
    <CrudList<PermissionPolicy>
      listId={EntityRouteListIds.PermissionPolicy}
      columns={columns}
      isItemEditable={isNotManaged}
      isItemDeletable={isNotManaged}
      searchFields={searchFields}
    />
  );
};
