import {
  call,
  put,
  select,
  spawn,
  takeEvery,
  StrictEffect,
} from 'redux-saga/effects';

import {
  DataType,
  Bill,
  BillStatus,
  approveBillsById,
  update,
} from '@m3ter-com/m3ter-api';

import { EntityRouteListIds } from '@/types/lists';

import { ids } from '@/util/data';
import { extractError } from '@/util/error';
import { refreshList } from '@/store/crud';
import { dataUpdated, selectByIds } from '@/store/data/data';
import { selectCurrentOrgId } from '@/store/app/bootstrap/bootstrap';

import {
  approveBill,
  approveBills,
  approveBillsFailure,
  approveBillsSuccess,
  lockBills,
  lockBillsFailure,
  lockBillsSuccess,
  ApproveBillAction,
  ApproveBillsAction,
  LockBillsAction,
} from './billing';
import billJobsSaga from './billJobs.saga';
import billsListSaga from './billsList.saga';
import billLineItemsSaga from './billLineItems.saga';
import statementJobsSaga from './statementJobs.saga';

export function* approveBillSaga(
  action: ApproveBillAction
): Generator<StrictEffect, void, any> {
  const { billId } = action.payload;

  try {
    const organizationId = yield select(selectCurrentOrgId);
    const updatedBill: Bill = yield call(update, {
      dataType: DataType.Bill,
      actionName: 'updateBillStatus',
      id: billId,
      pathParams: { organizationId },
      data: { status: BillStatus.Approved },
    });
    yield put(dataUpdated(DataType.Bill, [updatedBill]));
    yield put(approveBillsSuccess([billId]));
  } catch (error) {
    yield put(approveBillsFailure(extractError(error), [billId]));
  }
}

export function* approveBillsSaga(
  action: ApproveBillsAction
): Generator<StrictEffect, void, any> {
  const { billIds } = action.payload;

  const billsSelector = yield call(selectByIds, DataType.Bill, billIds);
  const bills: Array<Bill> = yield select(billsSelector);

  try {
    const organizationId = yield select(selectCurrentOrgId);
    const unapprovedBills = bills.filter(
      (bill) => bill.status === BillStatus.Pending
    );

    yield call(approveBillsById, organizationId, ids(unapprovedBills));

    yield put(approveBillsSuccess(billIds));
    yield put(refreshList(DataType.Bill, EntityRouteListIds.Bill));
  } catch (error) {
    yield put(approveBillsFailure(extractError(error), billIds));
  }
}

export function* lockBillsSaga(
  action: LockBillsAction
): Generator<StrictEffect, void, any> {
  const { billIds } = action.payload;

  const billsSelector = yield call(selectByIds, DataType.Bill, billIds);
  const bills: Array<Bill> = yield select(billsSelector);

  const organizationId = yield select(selectCurrentOrgId);

  try {
    // While we could technically make these requests concurrently, the number
    // of bills to lock is only bounded by the number shown per page, which is
    // 200. We don't want to make 200 concurrent requests to the billing service.
    const updatedBills: Array<Bill> = [];
    for (let i = 0; i < bills.length; i += 1) {
      const updatedBill: Bill = yield call(update, {
        dataType: DataType.Bill,
        id: bills[i].id,
        pathParams: { organizationId },
        actionName: 'lockBill',
      });
      updatedBills.push(updatedBill);
    }
    yield put(dataUpdated(DataType.Bill, updatedBills));
    yield put(lockBillsSuccess(billIds));
  } catch (error) {
    yield put(lockBillsFailure(extractError(error), billIds));
  }
}

export default function* billingSaga() {
  yield takeEvery(approveBill.type, approveBillSaga);
  yield takeEvery(approveBills.type, approveBillsSaga);
  yield takeEvery(lockBills.type, lockBillsSaga);

  yield spawn(billJobsSaga);
  yield spawn(billsListSaga);
  yield spawn(billLineItemsSaga);
  yield spawn(statementJobsSaga);
}
