import React from 'react';

import { Table, Text, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

import { CurrencyConversion } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

export interface CurrencyConversionsSummaryProps {
  currencyConversions: Array<CurrencyConversion>;
}

export const CurrencyConversionsSummary: React.FC<
  CurrencyConversionsSummaryProps
> = ({ currencyConversions }) => {
  const { t } = useTranslation();

  if (currencyConversions.length === 0)
    return <Text>{t('errors:organizations.noCurrencyConversions')}</Text>;

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>{t('common:from')}</Th>
          <Th>{t('common:to')}</Th>
          <Th>{t('features:billing.conversionRate')}</Th>
        </Tr>
      </Thead>
      <Tbody>
        {currencyConversions.map((conversion, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Tr key={`conversion-${index}`}>
            <Td>{conversion.from}</Td>
            <Td>{conversion.to}</Td>
            <Td>{conversion.multiplier}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
