import { useCallback } from 'react';

import { useNavigate, useResolvedPath } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import {
  ExportAdhoc,
  UnsavedEntity,
  createAdhocDataExport,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useOrg from '@/hooks/data/crud/useOrg';
import useNotification from '@/hooks/util/useNotification';

const useAdhocExport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useNotification();
  const { pathname: basePath } = useResolvedPath('../..');
  const { currentOrgId: organizationId } = useOrg();

  const {
    error,
    isPending: isSaving,
    mutate: adhocExport,
  } = useMutation({
    mutationFn: async (data: UnsavedEntity<ExportAdhoc>) =>
      createAdhocDataExport(organizationId, data),
    onSuccess: () => {
      toast({
        status: 'success',
        description: t('notifications:adhocExportSuccess'),
        duration: 5000,
      });
      navigate(basePath);
    },
  });

  const onAdhocExport = useCallback(
    (data: UnsavedEntity<ExportAdhoc>) => {
      adhocExport(data);
    },
    [adhocExport]
  );

  const onCancel = useCallback(() => {
    navigate(basePath);
  }, [basePath, navigate]);

  return {
    error,
    isSaving,
    onCancel,
    onAdhocExport,
  };
};

export default useAdhocExport;
