import React, { useCallback } from 'react';

import { IconButton, HStack } from '@chakra-ui/react';
import { XIcon } from 'lucide-react';

import {
  Aggregation,
  CompoundAggregation,
  Counter,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { PricingUsageEntity } from '@/types/data';

import { PricingGridAggregationDetails } from './PricingGridAggregationDetails';
import { PricingGridCompoundAggregationDetails } from './PricingGridCompoundAggregationDetails';
import { PricingGridItemCounterDetails } from './PricingGridItemCounterDetails';

export type PricingGridRowHeaderCallback = (
  pricingUsageEntity: PricingUsageEntity,
  isCompound?: boolean
) => void;

export interface PricingGridRowHeaderProps {
  canRemove: boolean;
  onRemovePricingUsageEntity: PricingGridRowHeaderCallback;
  aggregation?: Aggregation;
  compoundAggregation?: CompoundAggregation;
  itemCounter?: Counter;
}

export const PricingGridRowHeader: React.FC<PricingGridRowHeaderProps> = ({
  aggregation,
  compoundAggregation,
  itemCounter,
  canRemove,
  onRemovePricingUsageEntity,
}) => {
  const { t } = useTranslation();

  const onRemove = useCallback(() => {
    if (!onRemovePricingUsageEntity) return;

    if (aggregation) {
      onRemovePricingUsageEntity(aggregation, false);
    } else if (compoundAggregation) {
      onRemovePricingUsageEntity(compoundAggregation, true);
    } else if (itemCounter) {
      onRemovePricingUsageEntity(itemCounter, undefined);
    }
  }, [
    aggregation,
    compoundAggregation,
    itemCounter,
    onRemovePricingUsageEntity,
  ]);

  // At least one of these should always exist.
  if (!aggregation && !compoundAggregation && !itemCounter) {
    return null;
  }

  return (
    <HStack width="100%" justifyContent="space-between" alignItems="flex-start">
      {aggregation && (
        <PricingGridAggregationDetails aggregation={aggregation} />
      )}
      {compoundAggregation && (
        <PricingGridCompoundAggregationDetails
          compoundAggregation={compoundAggregation}
        />
      )}
      {itemCounter && (
        <PricingGridItemCounterDetails itemCounter={itemCounter} />
      )}
      {canRemove && (
        <IconButton
          data-testid="remove"
          aria-label={t('common:remove')}
          size="sm"
          icon={<XIcon size={16} />}
          onClick={onRemove}
        />
      )}
    </HStack>
  );
};
