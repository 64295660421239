import React, { useMemo } from 'react';

import { useResolvedPath, Params } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from '@chakra-ui/react';

import { DataType, Bill } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  InteractiveColumnDefinition,
  InteractiveTable,
  InteractiveTableBaseProps,
  InteractiveTableRowSelectionProps,
} from '@m3ter-com/ui-components';

import { getReference, isBillLocked } from '@/util/billing';
import { selectBillConfig } from '@/store/app/bootstrap/bootstrap';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useCurrencies from '@/hooks/util/useCurrencies';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import {
  CrudListEmptyTableContent,
  useCrudListContext,
} from '@/components/common/crud/CrudList';

export type AccountBillsTableProps = Omit<
  InteractiveTableBaseProps<Bill>,
  'columnDefinitions' | 'items' | 'footer' | 'loadingRows'
> &
  InteractiveTableRowSelectionProps & {};

export const AccountBillsTable: React.FC<AccountBillsTableProps> = ({
  ...tableProps
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDate } = useDateFormatter();
  const billConfig = useSelector(selectBillConfig);

  const path = useResolvedPath('');
  const billLinkQueryParams = useMemo<Params>(
    () => ({
      returnPath: path.pathname,
    }),
    [path]
  );

  const columnDefinitions = useMemo<Array<InteractiveColumnDefinition<Bill>>>(
    () => [
      {
        id: 'billRef',
        accessor: (bill) => (
          <Link
            as={CrudDetailsLink}
            dataType={DataType.Bill}
            id={bill.id}
            queryParams={billLinkQueryParams}
          >
            {getReference(bill)}
          </Link>
        ),
        header: t('forms:labels.reference'),
        sortType: 'string',
        rawValueAccessor: getReference,
      },
      {
        id: 'billExternalRef',
        accessor: 'externalInvoiceReference',
        header: t('features:billing.externalReference'),
        sortType: 'string',
        rawValueAccessor: 'externalInvoiceReference',
      },
      {
        id: 'externalInvoiceDate',
        accessor: (bill) => toLongDate(bill.externalInvoiceDate),
        header: t('features:billing.externalInvoiceDate'),
        sortType: 'date',
        rawValueAccessor: 'externalInvoiceDate',
      },
      {
        id: 'billTotal',
        accessor: (bill) => formatCurrency(bill.billTotal, bill.currency),
        header: t('common:total'),
        sortType: 'number',
        rawValueAccessor: 'billTotal',
      },
      {
        id: 'billStatus',
        accessor: (bill) =>
          isBillLocked(bill, billConfig)
            ? t('forms:labels.locked')
            : t(`features:billing.status.${bill.status}`),
        header: t('forms:labels.status'),
        sortType: 'string',
        rawValueAccessor: 'status',
      },
    ],
    [billConfig, billLinkQueryParams, t, toLongDate, formatCurrency]
  );

  const { currentPageEntities, isLoading } = useCrudListContext<Bill>();

  return (
    <InteractiveTable<Bill>
      enableColumnOrdering
      columnDefinitions={columnDefinitions}
      emptyContent={<CrudListEmptyTableContent />}
      isLoading={isLoading}
      items={currentPageEntities}
      {...tableProps}
    />
  );
};
