import React from 'react';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyIntegrationEventsList = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationEventsList" */ '@/components/features/integrations/IntegrationEventsList'
  ).then((module) => ({ default: module.IntegrationEventsList }))
);

export default (): NamedRoute => ({
  path: 'integration-events',
  name: 'integration.events',
  element: <LazyIntegrationEventsList />,
});
