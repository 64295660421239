import React from 'react';

import { ExportSchedule } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { ExportScheduleDetails } from '@/components/features/data-exports/ExportScheduleDetails';

export const ExportSchedulesDetailsRoute: React.FC = () => (
  <CrudDetails<ExportSchedule> component={ExportScheduleDetails} />
);
