import { useCallback, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { DateStringRange } from '@m3ter-com/ui-components';

const useExportJobsList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedStatus = searchParams.get('status');
  const dateCreatedStart = searchParams.get('dateCreatedStart');
  const dateCreatedEnd = searchParams.get('dateCreatedEnd');

  const selectedDateRange = useMemo<DateStringRange | null>(
    () =>
      dateCreatedStart && dateCreatedEnd
        ? { start: dateCreatedStart, end: dateCreatedEnd }
        : null,
    [dateCreatedEnd, dateCreatedStart]
  );

  const onSelectedStatusChange = useCallback(
    (newStatus: string | null) => {
      const updatedSearchParams = new URLSearchParams(searchParams);
      if (newStatus) {
        updatedSearchParams.set('status', newStatus);
      } else {
        updatedSearchParams.delete('status');
      }
      setSearchParams(updatedSearchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  const onSelectedDateRangeChange = useCallback(
    (newRange: DateStringRange | null) => {
      const updatedSearchParams = new URLSearchParams(searchParams);
      if (newRange) {
        updatedSearchParams.set('dateCreatedStart', newRange.start);
        updatedSearchParams.set('dateCreatedEnd', newRange.end);
      } else {
        updatedSearchParams.delete('dateCreatedStart');
        updatedSearchParams.delete('dateCreatedEnd');
      }
      setSearchParams(updatedSearchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  return {
    onSelectedDateRangeChange,
    onSelectedStatusChange,
    selectedDateRange,
    selectedStatus,
  };
};

export default useExportJobsList;
