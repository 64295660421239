import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useMemo,
} from 'react';

import { Box, Spacer, useMultiStyleConfig } from '@chakra-ui/react';
import { PanelLeftOpenIcon, PanelLeftCloseIcon } from 'lucide-react';

import { IconButton } from '../../controls/Button/IconButton';

interface NavigationPanelContextValue {
  isCollapsed: boolean;
}

const NavigationPanelContext = createContext<NavigationPanelContextValue>({
  isCollapsed: false,
});

export const useNavigationPanelContext = () =>
  useContext(NavigationPanelContext);

export interface NavigationPanelProps {
  actions?: ReactNode;
  isCollapsed?: boolean;
  onCollapseToggle?: () => void;
}

export const NavigationPanel: React.FC<
  PropsWithChildren<NavigationPanelProps>
> = ({ children, actions, isCollapsed = false, onCollapseToggle }) => {
  const contextValue = useMemo(() => ({ isCollapsed }), [isCollapsed]);
  const styles = useMultiStyleConfig('NavigationPanel', { isCollapsed });

  return (
    <NavigationPanelContext.Provider value={contextValue}>
      <Box sx={styles.panel}>
        {children}
        <Spacer />
        {actions && <Box sx={styles.actions}>{actions}</Box>}
        {onCollapseToggle && (
          <IconButton
            isRound
            sx={styles.toggle}
            onClick={onCollapseToggle}
            aria-label="Toggle navigation"
            icon={isCollapsed ? <PanelLeftOpenIcon /> : <PanelLeftCloseIcon />}
          />
        )}
      </Box>
    </NavigationPanelContext.Provider>
  );
};
