export enum ActionType {
  List = 'list',
  Search = 'search',
  Retrieve = 'retrieve',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DEL',
}
