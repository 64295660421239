import React from 'react';

export const CaretUpIcon: React.FC = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      d="M9.01536 8.25H2.98433C2.56672 8.25 2.35759 7.74509 2.65288 7.4498L5.66839 4.43428C5.85144 4.25124 6.14825 4.25124 6.3313 4.43428L9.34682 7.4498C9.64213 7.74509 9.43297 8.25 9.01536 8.25Z"
      fill="currentColor"
    />
  </svg>
);
