import React, { useMemo } from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { Tab, TabbedRoute } from '@/components/common/navigation/TabbedRoute';

export const OrganizationSettingsRoute: React.FC = () => {
  const { t } = useTranslation();

  const tabs = useMemo<Array<Tab>>(
    () => [
      { to: 'configuration', text: t('common:configuration') },
      { to: 'custom-fields', text: t('common:customFields') },
      { to: 'credit-reasons', text: t('common:creditReasons') },
      { to: 'debit-reasons', text: t('common:debitReasons') },
      { to: 'transaction-types', text: t('common:transactionTypes') },
      { to: 'currencies', text: t('common:currencys') },
    ],
    [t]
  );

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('common:organization')} />
      <TabbedRoute tabs={tabs} />
    </React.Fragment>
  );
};
