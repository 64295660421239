import React from 'react';

import { Flex, Heading, VStack } from '@chakra-ui/react';

import { DataType, Account } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import { AccountContractsList } from '@/components/features/accounts/AccountContractsList';
import useAccountContracts from '@/hooks/features/accounts/useAccountContracts';

export interface AccountContractsProps {
  account: Account;
}

export const AccountContracts: React.FC<AccountContractsProps> = ({
  account,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Contract);

  const { isLoading, error, activeAndPendingContracts, previousContracts } =
    useAccountContracts(account.id);

  return (
    <VStack spacing={4} alignItems="stretch">
      {!!error && <ErrorAlert error={error} />}
      <AccountContractsList
        showActiveStatusBadge
        contracts={activeAndPendingContracts}
        isLoading={isLoading}
        noDataMessage={t('features:account.noActiveOrPendingContracts')}
        header={
          <Flex alignItems="center" gap={4} justifyContent="space-between">
            <Heading size="md">
              {t('features:account.activeAndPendingContracts')}
            </Heading>
            <Button
              size="sm"
              as={CrudCreateLink}
              addReturnPath
              dataType={DataType.Contract}
            >
              {t('forms:buttons.createEntity', {
                entityName: entityNamings.singularLower,
              })}
            </Button>
          </Flex>
        }
      />
      <AccountContractsList
        contracts={previousContracts}
        isLoading={isLoading}
        noDataMessage={t('features:account.noPreviousContracts')}
        header={
          <Heading size="md" py={1}>
            {t('features:account.previousContracts')}
          </Heading>
        }
      />
    </VStack>
  );
};
