import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField } from '@m3ter-com/console-core/components';

import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect';
import { StatementGenerateModeField } from '@/components/features/pricing/StatementGenerateModeField';

interface BillStatementConfigFieldsProps {
  definitionIdFieldName: string;
  generateModeHelpText: string;
  definitionIdHelpText?: string;
  includeOrgConfig?: boolean;
  defaultLabel?: string;
}

export const BillStatementConfigFields: React.FC<
  BillStatementConfigFieldsProps
> = ({
  definitionIdHelpText,
  definitionIdFieldName,
  generateModeHelpText,
  defaultLabel,
  includeOrgConfig = false,
}) => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();

  return isFeatureEnabled(Feature.Statements) ? (
    <React.Fragment>
      <FormField
        name={definitionIdFieldName}
        label={t('forms:labels.statementDefinition')}
        helpText={definitionIdHelpText}
        control={
          FormEntitySelect as DataTypeFormEntitySelect<DataType.StatementDefinition>
        }
        isClearable
        dataType={DataType.StatementDefinition}
        accessor="name"
      />
      <FormField
        name="autoGenerateStatementMode"
        label={t('forms:labels.autoGenerateStatements')}
        helpText={generateModeHelpText}
        control={StatementGenerateModeField}
        defaultLabel={defaultLabel}
        includeOrgConfig={includeOrgConfig}
      />
    </React.Fragment>
  ) : null;
};
