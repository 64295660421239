import React, { useMemo } from 'react';

import { List, ListItem } from '@chakra-ui/react';

import { DataType, Invitation } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { EntityRouteListIds } from '@/types/lists';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { ReferenceLink } from '@/components/common/data/ReferenceLink';

const searchFields = ['email'];

export const InvitationsListRoute: React.FC = () => {
  const { t } = useTranslation();
  const { toLongDate } = useDateFormatter();

  const columns = useMemo<Array<ColumnDefinition<Invitation>>>(
    () => [
      {
        id: 'email',
        header: t('forms:labels.emailAddress'),
        accessor: 'email',
      },
      {
        id: 'access-end-date',
        header: t('forms:labels.accessEndDate'),
        accessor: (invite) =>
          invite.dtEndAccess ? toLongDate(invite.dtEndAccess) : '-',
      },
      {
        id: 'invite-expiry-date',
        header: t('forms:labels.inviteExpiryDate'),
        accessor: (invite) => toLongDate(invite.dtExpiry),
      },
      {
        id: 'accepted',
        header: t('features:invitations.accepted'),
        accessor: (invite) =>
          invite.accepted ? t('common:yes') : t('common:no'),
      },
      {
        id: 'permission-policies',
        header: t('common:permissionPolicys'),
        accessor: (invitation: Invitation) =>
          invitation.permissionPolicyIds &&
          invitation.permissionPolicyIds.length > 0 ? (
            <List>
              {invitation.permissionPolicyIds.map((policyId) => (
                <ListItem key={policyId}>
                  <ReferenceLink
                    dataType={DataType.PermissionPolicy}
                    id={policyId}
                    accessor="name"
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            '-'
          ),
      },
    ],
    [t, toLongDate]
  );

  return (
    <CrudList<Invitation>
      columns={columns}
      listId={EntityRouteListIds.Invitation}
      searchFields={searchFields}
      createRouteLabel={t('features:invitations.inviteUser')}
    />
  );
};
