import { combineReducers, AnyAction, Reducer } from 'redux';

import { DataType } from '@m3ter-com/m3ter-api';

import appReducer, { AppState } from '@/store/app/app';
import { changeOrg } from '@/store/app/bootstrap/bootstrap';
import crudReducer, { CrudState } from '@/store/crud';
import dataReducer, { DataState, resetExcluding } from '@/store/data/data';
import featuresReducer, {
  FeaturesState,
} from '@/store/features/featuresReducer';
import notificationsReducer, {
  NotificationsState,
} from '@/store/notifications/notifications';
import productsReducer, { ProductsState } from '@/store/products/products';
import singletonsReducer, {
  SingletonsState,
} from '@/store/singletons/singletons';
import tasksReducer, { TasksState } from '@/store/tasks/tasks';
import utilsReducer, { UtilsState } from '@/store/utils/utils';

export interface RootState {
  app: AppState;
  crud: CrudState;
  data: DataState;
  features: FeaturesState;
  notifications: NotificationsState;
  products: ProductsState;
  singletons: SingletonsState;
  tasks: TasksState;
  utils: UtilsState;
}

const createRootReducer = (): Reducer<RootState> => {
  const rootReducer = combineReducers({
    app: appReducer,
    crud: crudReducer,
    data: dataReducer,
    features: featuresReducer,
    notifications: notificationsReducer,
    products: productsReducer,
    singletons: singletonsReducer,
    tasks: tasksReducer,
    utils: utilsReducer,
  });

  return (currentState: RootState | undefined, action: AnyAction) => {
    // When we are changing org, we want to do a mass reset of all slices, with a
    // couple of exceptions, so that users don't see any cross-org data.
    if (currentState && action.type === changeOrg.type) {
      const initialState = rootReducer(undefined, action);
      return {
        ...initialState,
        // We want to keep the app state the same, but apply the `changeOrg` action so it
        // can handle its own resetting as needed.
        app: appReducer(currentState.app, action),
        // We want to clear out the data slice, except for the organizations / current user as they never change.
        // We can achieve this by applying a `resetExcluding` action to the current state.
        data: dataReducer(
          currentState.data,
          resetExcluding([
            DataType.Organization,
            DataType.SupportOrganization,
            DataType.CurrentUser,
          ])
        ),
      };
    }

    return rootReducer(currentState, action);
  };
};

export default createRootReducer;
