import i18next from 'i18next';

import { Id, ScheduleType } from '@m3ter-com/m3ter-api';

export const getScheduleFrequencyDescription = (
  count: number,
  frequency: ScheduleType
): string => {
  return count === 1
    ? i18next.t('common:everyPeriod', {
        period: i18next.t(
          `features:dataExports.scheduleTypePeriods.singular.${frequency}`
        ),
      })
    : i18next.t('common:everyCountPeriod', {
        count,
        period: i18next.t(
          `features:dataExports.scheduleTypePeriods.plural.${frequency}`
        ),
      });
};

export const getDestinationTrustPolicy = (externalId: Id) => ({
  Version: '2012-10-17',
  Statement: [
    {
      Effect: 'Allow',
      Action: 'sts:AssumeRole',
      Principal: {
        AWS: 'arn:aws:iam::816069165829:role/m3terDataExports',
      },
      Condition: {
        StringEquals: {
          'sts:ExternalId': externalId,
        },
      },
    },
  ],
});

export const getDestinationPermissionPolicy = (
  bucketName: string,
  prefix?: string
) => ({
  Version: '2012-10-17',
  Statement: [
    {
      Effect: 'Allow',
      Action: [
        's3:PutObject',
        's3:ListMultipartUploadParts',
        's3:AbortMultipartUpload',
      ],
      Resource: `arn:aws:s3:::${bucketName}/${prefix ? `${prefix}/*` : '*'}`,
    },
  ],
});
