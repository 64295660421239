import React, { useCallback, useMemo } from 'react';

import { HStack, IconButton, Text } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

import { Button } from '../Button/Button';

import { calculatePages, DOTS } from './utils';

export interface PaginationProps {
  currentPage: number;
  pageCount: number;
  hasMore?: boolean;
  isDisabled?: boolean;
  backLabel?: string;
  nextLabel?: string;
  onChange: (page: number) => void;
  onNext?: () => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  hasMore = false,
  pageCount,
  isDisabled = false,
  backLabel = 'Back',
  nextLabel = 'Next',
  onChange,
  onNext,
}) => {
  const pages = useMemo(
    () => calculatePages(currentPage, pageCount, hasMore),
    [currentPage, pageCount, hasMore]
  );

  const onPreviousClick = useCallback(() => {
    if (currentPage > 1) {
      onChange(currentPage - 1);
    }
  }, [currentPage, onChange]);

  const onNextClick = useCallback(() => {
    if (currentPage < pageCount) {
      onChange(currentPage + 1);
    } else if (hasMore && onNext) {
      onNext();
    }
  }, [currentPage, pageCount, hasMore, onChange, onNext]);

  return (
    <HStack>
      <IconButton
        isDisabled={isDisabled || currentPage === 1}
        aria-label={backLabel}
        icon={<ChevronLeftIcon />}
        onClick={onPreviousClick}
        data-testid="previous"
      />
      {pages.map((page, index) =>
        page === DOTS ? (
          // eslint-disable-next-line react/no-array-index-key
          <Text w={6} textAlign="center" key={index}>
            …
          </Text>
        ) : (
          <Button
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            isActive={currentPage === page}
            isDisabled={isDisabled}
            onClick={() => {
              onChange(page);
            }}
            aria-current={currentPage === page}
            data-testid="page-button"
          >
            {page}
          </Button>
        )
      )}
      <IconButton
        isDisabled={isDisabled || (!hasMore && currentPage === pageCount)}
        aria-label={nextLabel}
        icon={<ChevronRightIcon />}
        onClick={onNextClick}
        data-testid="next"
      />
    </HStack>
  );
};
