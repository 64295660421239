import React from 'react';

import { DataType, Customer } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyCustomersAdminRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CustomersAdminRoute" */ '@/components/routes/admin/customers/CustomersAdminRoute'
  ).then((module) => ({ default: module.CustomersAdminRoute }))
);

const LazyCustomersAdminDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CustomersAdminDetailsRoute" */ '@/components/routes/admin/customers/CustomersAdminDetailsRoute'
  ).then((module) => ({ default: module.CustomersAdminDetailsRoute }))
);

const LazyCustomersAdminListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CustomersAdminListRoute" */ '@/components/routes/admin/customers/CustomersAdminListRoute'
  ).then((module) => ({ default: module.CustomersAdminListRoute }))
);

const LazyCustomersAdminCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CustomersAdminCreateRoute" */ '@/components/routes/admin/customers/CustomersAdminCreateRoute'
  ).then((module) => ({ default: module.CustomersAdminCreateRoute }))
);

const LazyCustomersAdminEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CustomersAdminEditRoute" */ '@/components/routes/admin/customers/CustomersAdminEditRoute'
  ).then((module) => ({ default: module.CustomersAdminEditRoute }))
);

export default () =>
  createCrudRoute<Customer>({
    path: 'customers',
    dataType: DataType.Customer,
    element: <LazyCustomersAdminRoute />,
    list: <LazyCustomersAdminListRoute />,
    details: <LazyCustomersAdminDetailsRoute />,
    create: <LazyCustomersAdminCreateRoute />,
    edit: <LazyCustomersAdminEditRoute />,
  });
