import React from 'react';

import { Icon, Text, VStack } from '@chakra-ui/react';
import { TriangleAlertIcon } from 'lucide-react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

export const NoUsageEntitiesWarning: React.FC = () => {
  const { t } = useTranslation();

  return (
    <VStack
      width="100%"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
    >
      <Icon as={TriangleAlertIcon} boxSize={6} />
      <Text fontSize="md">{t('features:pricing.noPricingConfigured')}</Text>
      <Text fontSize="md">{t('features:pricing.noUsageEntitiesSelected')}</Text>
    </VStack>
  );
};
