import React, { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import snakeCase from 'lodash/snakeCase';

import { AccountPlan, ChildBillingMode, DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudQueryParams } from '@/types/routes';

import {
  createCustomPlan,
  selectError,
  selectIsSaving,
} from '@/store/features/accounts/customPlanForm';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { useOrgDates } from '@/hooks/util/useOrgDates';
import useQueryString from '@/hooks/navigation/useQueryString';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { useCrudRetrieveContext } from '@/components/common/crud/CrudRetrieveContext';
import { useCrudContext } from '@/components/common/crud/CrudContext';
import {
  AccountCustomPlanForm,
  AccountCustomPlanFormValues,
} from '@/components/features/accounts/AccountCustomPlanForm';

export const CreateCustomPlanRoute: React.FC = () => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Plan);
  const dispatch = useDispatch();

  const isSaving = useSelector(selectIsSaving);
  const error = useSelector(selectError);

  const { account } = useCrudRetrieveContext();

  const { getStartOfToday } = useOrgDates();

  const initialValues = useMemo(() => {
    const planName = `${account?.name} ${t('common:plan')}`;

    return {
      accountId: account?.id,
      startDate: getStartOfToday().toISOString(),
      name: planName,
      code: snakeCase(planName),
      childBillingMode: ChildBillingMode.ParentBreakdown,
    };
  }, [account, getStartOfToday, t]);

  const { basePath, onCancel } = useCrudContext<AccountPlan>();
  const { returnPath = `${basePath}/:id` } = useQueryString<CrudQueryParams>();

  const onSave = useCallback(
    (data: AccountCustomPlanFormValues) => {
      dispatch(createCustomPlan(data, returnPath));
    },
    [dispatch, returnPath]
  );

  return (
    <React.Fragment>
      {error && (
        <ErrorAlert
          title={t('errors:generic.problemSavingData', {
            entityName: entityNamings.singularLower,
          })}
          error={error}
          mb={4}
        />
      )}
      <BreadcrumbItem title={t('common:create')} />
      <AccountCustomPlanForm
        isSaving={isSaving}
        initialValues={initialValues}
        onSave={onSave}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};
