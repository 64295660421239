import React from 'react';

import { CompoundAggregation } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails';
import { CompoundAggregationDetails } from '@/components/features/aggregations/CompoundAggregationDetails';

const CompoundAggregationsDetailsRouteComponent: React.FC<{
  data: CompoundAggregation;
}> = ({ data }) => <CompoundAggregationDetails compoundAggregation={data} />;

export const CompoundAggregationsDetailsRoute: React.FC = () => (
  <CrudDetails<CompoundAggregation>
    component={CompoundAggregationsDetailsRouteComponent}
  />
);
