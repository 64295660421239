import React from 'react';

export const SortDownIcon: React.FC = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      d="M3.21101 6.75H8.78914C9.2907 6.75 9.54148 7.35703 9.18758 7.71094L6.39851 10.5C6.1782 10.7203 5.82195 10.7203 5.60398 10.5L2.81257 7.71094C2.45867 7.35703 2.70945 6.75 3.21101 6.75Z"
      fill="currentColor"
    />
  </svg>
);
