import { create, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import { NotificationRule } from '@m3ter-com/m3ter-api';

const suite = create('notificationRule', (data: Partial<NotificationRule>) => {
  test('name', i18next.t('forms:validations.common.nameRequired'), () => {
    enforce(data.name).isNotEmpty();
  });

  test('code', i18next.t('forms:validations.common.codeRequired'), () => {
    enforce(data.code).isNotEmpty();
  });

  test(
    'description',
    i18next.t('forms:validations.common.descriptionRequired'),
    () => {
      enforce(data.description).isNotEmpty();
    }
  );

  test(
    'eventName',
    i18next.t('forms:validations.common.eventNameRequired'),
    () => {
      enforce(data.eventName).isNotEmpty();
    }
  );

  omitWhen(!!data.alwaysFireEvent, () => {
    test(
      'calculation',
      i18next.t('forms:validations.common.calculationRequired'),
      () => {
        enforce(data.calculation).isNotEmpty();
      }
    );
  });
});

export default suite;
