import React from 'react';

import { DataType, Product } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Form,
  FormActions,
  FormSection,
  NameCodeFields,
} from '@m3ter-com/console-core/components';
import { FormStack } from '@m3ter-com/ui-components';

import productSchema from '@/validation/product';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { FormCustomFieldsEditorDefaults } from '@/components/forms/FormCustomFieldsEditorDefaults';

export interface ProductFormProps {
  onSave: (item: Product) => void;
  onCancel: () => void;
  isSaving?: boolean;
  isEdit?: boolean;
  initialValues?: Product;
}

export const ProductForm: React.FC<ProductFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  initialValues = {},
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Product);

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={productSchema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <NameCodeFields />
        </FormSection>
        <FormCustomFieldsEditorDefaults dataType={DataType.Product} />
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', {
                  entityName: entityNamings.singularLower,
                })
              : t('forms:buttons.createEntity', {
                  entityName: entityNamings.singularLower,
                })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
