import React from 'react';

import {
  Badge,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  IconButton,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { PlusIcon, XIcon } from 'lucide-react';

import { DataType, Plan, PlanTemplate } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useCurrencies from '@/hooks/util/useCurrencies';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';

export type PricingGridColumnHeaderCallback = (
  planOrTemplate: Plan | PlanTemplate,
  isTemplate: boolean
) => void;

export interface PricingGridColumnHeaderProps {
  plan?: Plan;
  planTemplate?: PlanTemplate;
  showEdit?: boolean;
  onAddPlanOrTemplate?: PricingGridColumnHeaderCallback;
  onDuplicate?: PricingGridColumnHeaderCallback;
  onRemove?: PricingGridColumnHeaderCallback;
}

export const PricingGridColumnHeader: React.FC<
  PricingGridColumnHeaderProps
> = ({
  plan,
  planTemplate,
  showEdit = true,
  onAddPlanOrTemplate,
  onDuplicate,
  onRemove,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();

  // At least one of these needs to exist for us to show anything
  if (!plan && !planTemplate) {
    return null;
  }

  return (
    <VStack justifyContent="flex-start" alignItems="flex-start" spacing={2}>
      <HStack
        width="100%"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <VStack alignItems="flex-start" spacing={1}>
          {plan ? (
            <HStack>
              <Badge colorScheme="blue">{t('common:plan')}</Badge>
              {plan.accountId && (
                <Badge colorScheme="green">{t('common:custom')}</Badge>
              )}
            </HStack>
          ) : (
            <Badge colorScheme="blue" variant="outline">
              {t('common:planTemplate')}
            </Badge>
          )}
          <Heading
            as="h3"
            size="sm"
            maxW="300px"
            fontWeight="bold"
            wordBreak="break-word"
          >
            {plan?.name || planTemplate?.name}
          </Heading>
        </VStack>
        {!!onRemove && (
          <IconButton
            data-testid="remove-column"
            aria-label={t('common:remove')}
            ml={2}
            size="sm"
            icon={<XIcon size={16} />}
            onClick={() => {
              onRemove((plan || planTemplate)!, !plan);
            }}
          />
        )}
      </HStack>
      <VStack
        w="100%"
        spacing={2}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {plan && planTemplate && (
          <HStack justifyContent="space-between" alignItems="center">
            <Text maxW="300px" wordBreak="break-word">
              {`${t('common:uses')}: `}
              <Link
                as={CrudDetailsLink}
                dataType={DataType.PlanTemplate}
                id={planTemplate.id}
              >
                {planTemplate.name}
              </Link>
            </Text>
            {onAddPlanOrTemplate && (
              <IconButton
                size="xs"
                icon={<PlusIcon size={16} />}
                aria-label={t('features:pricing.addToGrid')}
                onClick={() => {
                  onAddPlanOrTemplate(planTemplate, true);
                }}
              />
            )}
          </HStack>
        )}
        <Text size="xs">
          {t('features:pricing.standingChargeAmount')}:{' '}
          {formatCurrency(
            plan?.standingCharge ?? planTemplate?.standingCharge ?? 0,
            planTemplate?.currency ?? ''
          )}
        </Text>
      </VStack>
      <ButtonGroup>
        {showEdit && (
          <Button
            size="sm"
            as={CrudEditLink}
            addReturnPath
            dataType={plan ? DataType.Plan : DataType.PlanTemplate}
            id={plan?.id ?? planTemplate?.id}
          >
            {t('common:edit')}
          </Button>
        )}
        {!!onDuplicate && (
          <Button
            data-testid="duplicate-column"
            size="sm"
            onClick={() => {
              onDuplicate((plan || planTemplate)!, !plan);
            }}
          >
            {t('common:duplicate')}
          </Button>
        )}
      </ButtonGroup>
    </VStack>
  );
};
