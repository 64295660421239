import React, { useCallback } from 'react';

import { Stack, useDisclosure } from '@chakra-ui/react';
import { TimerIcon } from 'lucide-react';

import { AggregationFrequency } from '@m3ter-com/m3ter-api';
import { Button } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { UsageQueryBuilderSection } from '@/components/features/usage/query-builder/UsageQueryBuilderSection';
import {
  UsageQueryBuilderMenu,
  UsageQueryBuilderMenuButton,
} from '@/components/features/usage/query-builder/UsageQueryBuilderMenu';

const standardAggregationFrequencies: Array<AggregationFrequency> =
  Object.values(AggregationFrequency);

export interface UsageQueryBuilderAggregationFrequencyProps {
  value: AggregationFrequency;
  onChange: (value: AggregationFrequency) => void;
  aggregationFrequencies?: Array<AggregationFrequency>;
}

export const UsageQueryBuilderAggregationFrequency: React.FC<
  UsageQueryBuilderAggregationFrequencyProps
> = ({
  value,
  onChange,
  aggregationFrequencies = standardAggregationFrequencies,
}) => {
  const { t } = useTranslation();

  const { isOpen, onClose, onToggle } = useDisclosure();

  const onSelect = useCallback(
    (frequency: AggregationFrequency) => {
      onChange(frequency);
      onClose();
    },
    [onChange, onClose]
  );

  return (
    <UsageQueryBuilderSection
      colorScheme="blue"
      title={t('forms:labels.aggregationFrequency')}
      icon={TimerIcon}
    >
      <UsageQueryBuilderMenu
        isOpen={isOpen}
        onClose={onClose}
        trigger={
          <Button
            size="sm"
            colorScheme="blue"
            variant="subtle"
            onClick={onToggle}
          >
            {t(`features:dataExports.aggregationFrequency.${value}`)}
          </Button>
        }
      >
        <Stack>
          {aggregationFrequencies.map((frequency) => (
            <UsageQueryBuilderMenuButton
              key={frequency}
              onClick={() => onSelect(frequency)}
            >
              {t(`features:dataExports.aggregationFrequency.${frequency}`)}
            </UsageQueryBuilderMenuButton>
          ))}
        </Stack>
      </UsageQueryBuilderMenu>
    </UsageQueryBuilderSection>
  );
};
