import React from 'react';

import { InputGroup, InputRightAddon, forwardRef } from '@chakra-ui/react';

import {
  AdvancedNumberInput,
  AdvancedNumberInputProps,
} from '../AdvancedNumberInput/AdvancedNumberInput';

export interface CurrencyInputProps extends AdvancedNumberInputProps {
  code: string;
  useSymbol?: boolean;
}

const getCurrencySymbol = (currencyCode: string): string | undefined => {
  try {
    const locale = navigator.language;
    const numberFormatParts = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
    }).formatToParts();
    return numberFormatParts.find((part) => part.type === 'currency')?.value;
  } catch (_error) {
    return undefined;
  }
};

export const CurrencyInput = forwardRef<CurrencyInputProps, 'input'>(
  ({ code, useSymbol = false, ...rest }, ref) => {
    // If useSymbol is true and the symbol exists, use that. Otherwise, use the code
    const currencyText = (useSymbol ? getCurrencySymbol(code) : code) || code;

    return (
      <InputGroup>
        <AdvancedNumberInput data-testid="currency-input" ref={ref} {...rest} />
        <InputRightAddon>{currencyText}</InputRightAddon>
      </InputGroup>
    );
  }
);
