import React, { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import {
  Flex,
  Grid,
  Spinner,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { Account, DataType, Id, Meter } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { dataTypeListQuery } from '@/queries/crud';

interface QueryDetailsSectionProps {
  data: Array<Meter | Account | string>;
  colorScheme: string;
  title: string;
  emptyContentMessage?: string;
}

export const QueryDetailsSection: React.FC<QueryDetailsSectionProps> = ({
  data,
  colorScheme,
  title,
  emptyContentMessage,
}) => {
  const color = useColorModeValue(`${colorScheme}.700`, `${colorScheme}.200`);

  return (
    <Grid templateColumns="auto 1fr" gap={4} alignItems="start">
      <Text whiteSpace="nowrap">{`${title}: `}</Text>
      <Flex gap={4} wrap="wrap" color={color}>
        {data.length > 0
          ? data.map((item) => (
              <Tag
                key={typeof item === 'string' ? item : item.id}
                whiteSpace="nowrap"
                colorScheme={colorScheme}
              >
                {typeof item === 'string' ? item : item.name}
              </Tag>
            ))
          : emptyContentMessage && (
              <Tag whiteSpace="nowrap" colorScheme={colorScheme}>
                {emptyContentMessage}
              </Tag>
            )}
      </Flex>
    </Grid>
  );
};

interface QueryDetailsProps {
  dataType: DataType.Account | DataType.Meter;
  ids?: Array<Id>;
}

const emptyArray = new Array<Id>();

export const QueryDetails: React.FC<QueryDetailsProps> = ({
  dataType,
  ids = emptyArray,
}) => {
  const { t } = useTranslation();
  const pathParams = useOrgPathParams();
  const colorScheme = dataType === DataType.Meter ? 'indigo' : 'cyan';
  const entityNamings = useEntityNamings(dataType);

  const { data = [], isLoading } = useQuery(
    dataTypeListQuery(
      {
        dataType,
        pathParams,
        queryParams: { ids },
      },
      { enabled: ids.length > 0 }
    )
  );

  const sortedData = useMemo(
    () => data.sort((a, b) => a.name.localeCompare(b.name)),
    [data]
  );

  if (isLoading) {
    return <Spinner size="sm" />;
  }

  return (
    <QueryDetailsSection
      data={sortedData}
      title={entityNamings.plural}
      colorScheme={colorScheme}
      emptyContentMessage={t('features:dataExports.allEntities', {
        entityName: entityNamings.pluralLower,
      })}
    />
  );
};
