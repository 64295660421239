import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import {
  Box,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  Heading,
  Link,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { PencilIcon } from 'lucide-react';

import {
  Button,
  CardActionsHeader,
  IconButton,
  KeyValueList,
  KeyValueListItem,
} from '@m3ter-com/ui-components';
import { DataType, ExportDestination } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityDeleteMutation from '@/hooks/data/useEntityDeleteMutation';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { EntityDeleteButton } from '@/components/common/data/EntityCrudActions';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink';
import { dataTypeListAllQuery } from '@/queries/crud';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import useEntityNamings from '@/hooks/util/useEntityNamings';

export const ExportDestinationsList: React.FC = () => {
  const { t } = useTranslation();
  const pathParams = useOrgPathParams();
  const entityNamings = useEntityNamings(DataType.ExportDestination);

  const {
    data: exportDestinations = [],
    isLoading: isLoadingExportDestinations,
    error: errorLoadingExportDestinations,
  } = useQuery(
    dataTypeListAllQuery({
      dataType: DataType.ExportDestination,
      pathParams,
    })
  );

  const sortedExportDestinations = useMemo(
    () => exportDestinations.sort((a, b) => a.name.localeCompare(b.name)),
    [exportDestinations]
  );

  const { deleteEntity: deleteExportDestination } = useEntityDeleteMutation(
    DataType.ExportDestination
  );
  const onDeleteExportDestination = useCallback(
    (exportDestination: ExportDestination) => {
      deleteExportDestination({ entity: exportDestination });
    },
    [deleteExportDestination]
  );

  return (
    <Card maxH="fit-content">
      <CardActionsHeader
        actions={
          <Button
            addReturnPath
            size="sm"
            as={CrudCreateLink}
            dataType={DataType.ExportDestination}
          >
            {t('forms:buttons.createEntity', {
              entityName: entityNamings.singularLower,
            })}
          </Button>
        }
      >
        <Heading size="md">{entityNamings.plural}</Heading>
      </CardActionsHeader>
      <CardBody>
        <LoadingErrorContentSwitch
          isLoading={isLoadingExportDestinations}
          error={errorLoadingExportDestinations}
        >
          <Stack spacing={4} divider={<StackDivider />}>
            {sortedExportDestinations.map((exportDestination) => (
              <Box key={exportDestination.id}>
                <Flex
                  gap={4}
                  mb={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Heading
                    size="sm"
                    overflow="hidden"
                    overflowWrap="break-word"
                  >
                    <Link
                      as={CrudDetailsLink}
                      dataType={DataType.ExportDestination}
                      id={exportDestination.id}
                    >
                      {exportDestination.name}
                    </Link>
                  </Heading>
                  <ButtonGroup>
                    <IconButton
                      size="xs"
                      addReturnPath
                      icon={<PencilIcon size={16} />}
                      aria-label={t('common:edit')}
                      as={CrudEditLink}
                      dataType={DataType.ExportDestination}
                      id={exportDestination.id}
                    />
                    <EntityDeleteButton
                      size="xs"
                      onConfirm={() => {
                        onDeleteExportDestination(exportDestination);
                      }}
                    />
                  </ButtonGroup>
                </Flex>
                <KeyValueList>
                  <KeyValueListItem
                    label={t('forms:labels.code')}
                    value={exportDestination.code}
                  />
                  <KeyValueListItem
                    label={t('forms:labels.bucketName')}
                    value={exportDestination.bucketName}
                  />
                  <KeyValueListItem
                    label={t('forms:labels.prefix')}
                    value={exportDestination.prefix || '-'}
                  />
                </KeyValueList>
              </Box>
            ))}
            {exportDestinations.length === 0 && (
              <Text textAlign="center">
                {t('errors:generic.noDataToDisplay', {
                  entityName: entityNamings.pluralLower,
                })}
              </Text>
            )}
          </Stack>
        </LoadingErrorContentSwitch>
      </CardBody>
    </Card>
  );
};
