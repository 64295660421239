import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';
import { SimpleGrid } from '@chakra-ui/react';

import { DataType, SupportAccess } from '@m3ter-com/m3ter-api';
import { Button, KeyValue } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useSingleton from '@/hooks/data/useSingleton';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { DetailsCard } from '@/components/common/data/DetailsCard';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch';
import { PermissionPolicyList } from '@/components/features/access/permission-policy/PermissionPolicyList';

export const SupportAccessDetails: React.FC = () => {
  const { t } = useTranslation();
  const { toLongDate } = useDateFormatter();

  const {
    error,
    isLoading,
    data: supportAccess,
  } = useSingleton<SupportAccess>(DataType.SupportAccess);

  const supportAccessActive = useMemo(
    () =>
      !!supportAccess?.dtEndSupportUsersAccess &&
      new Date(supportAccess?.dtEndSupportUsersAccess) > new Date(Date.now()),
    [supportAccess?.dtEndSupportUsersAccess]
  );

  return (
    <LoadingErrorContentSwitch error={error} isLoading={isLoading}>
      {!!supportAccess && (
        <DetailsCard
          data={supportAccess}
          dataType={DataType.SupportAccess}
          showEditors={false}
          showId={false}
          showMetadata={false}
          header={
            <Button size="sm" as={Link} to="edit" intent="primary">
              {t('common:edit')}
            </Button>
          }
          details={
            <SimpleGrid columns={2} gap={6} w="100%">
              <KeyValue
                label={t('features:access.supportAccessStatus')}
                value={
                  <ActiveStatusBadge ml={0} active={supportAccessActive} />
                }
              />
              <KeyValue
                label={t('forms:labels.supportAccessEndDate')}
                value={
                  supportAccess?.dtEndSupportUsersAccess
                    ? toLongDate(supportAccess.dtEndSupportUsersAccess)
                    : '-'
                }
              />
            </SimpleGrid>
          }
        >
          <PermissionPolicyList dataType={DataType.SupportAccess} />
        </DetailsCard>
      )}
    </LoadingErrorContentSwitch>
  );
};
