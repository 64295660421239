import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Icon,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { TriangleAlertIcon } from 'lucide-react';

import {
  Aggregation,
  CompoundAggregation,
  Counter,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { PricingUsageEntity } from '@/types/data';

import {
  removePricingUsageEntity,
  PlanDetailsPricingDataEntities,
} from '@/store/features/pricing/planDetails';
import { PricingGrid } from '@/components/features/pricing/grid/PricingGrid';
import { PlanDetailsPricingGridActions } from '@/components/features/pricing/PlanDetailsPricingGridActions';
import usePlanPricingQueryParams from '@/hooks/features/pricing/usePlanPricingQueryParams';

interface PlanDetailsPricingGridProps {
  canEditPricing: boolean;
  extraPricingAggregations: Array<Aggregation>;
  extraPricingCompoundAggregations: Array<CompoundAggregation>;
  extraPricingItemCounters: Array<Counter>;
  isLoading: boolean;
  canEditTemplatePricing?: boolean;
  pricingData?: PlanDetailsPricingDataEntities;
}

export const PlanDetailsPricingGrid: React.FC<PlanDetailsPricingGridProps> = ({
  canEditPricing,
  canEditTemplatePricing = true,
  extraPricingAggregations,
  extraPricingCompoundAggregations,
  extraPricingItemCounters,
  isLoading,
  pricingData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { removeUsageEntitiesFromQueryParams } = usePlanPricingQueryParams();

  const selectedAggregations = useMemo<Array<Aggregation>>(
    () => [...(pricingData?.aggregations || []), ...extraPricingAggregations],
    [extraPricingAggregations, pricingData]
  );

  const selectedCompoundAggregations = useMemo<Array<CompoundAggregation>>(
    () => [
      ...(pricingData?.compoundAggregations || []),
      ...extraPricingCompoundAggregations,
    ],
    [extraPricingCompoundAggregations, pricingData]
  );

  const selectedItemCounters = useMemo<Array<Counter>>(
    () => [...(pricingData?.itemCounters || []), ...extraPricingItemCounters],
    [extraPricingItemCounters, pricingData]
  );

  const onRemovePricingUsageEntity = useCallback(
    (pricingUsageEntity: PricingUsageEntity, isCompound?: boolean) => {
      dispatch(removePricingUsageEntity(pricingUsageEntity.id, isCompound));
      removeUsageEntitiesFromQueryParams(pricingUsageEntity.id, isCompound);
    },
    [dispatch, removeUsageEntitiesFromQueryParams]
  );

  const productId =
    pricingData?.plans[0]?.productId ??
    pricingData?.planTemplates[0]?.productId;

  const canShowPricingGrid =
    !!pricingData &&
    (pricingData.compoundAggregations.length > 0 ||
      pricingData.aggregations.length > 0 ||
      extraPricingAggregations.length > 0 ||
      extraPricingCompoundAggregations.length > 0 ||
      pricingData.itemCounters.length > 0 ||
      extraPricingItemCounters.length > 0);

  return (
    <Card>
      <CardHeader>
        <Heading size="md">{t('features:pricing.associatedPricing')}</Heading>
      </CardHeader>
      <VStack as={CardBody} alignItems="flex-start" spacing={4}>
        {isLoading ? (
          <Spinner data-testid="plan-details-and-pricing-loading" />
        ) : (
          <React.Fragment>
            {!canShowPricingGrid && (
              <HStack w="100%" spacing={4}>
                <Icon as={TriangleAlertIcon} boxSize={6} />
                <Text fontSize="lg">
                  {t('features:pricing.planDetailsNoPricingsMessage')}
                </Text>
              </HStack>
            )}
            <PlanDetailsPricingGridActions
              productId={productId}
              selectedAggregations={selectedAggregations}
              selectedCompoundAggregations={selectedCompoundAggregations}
              selectedItemCounters={selectedItemCounters}
            />
            {canShowPricingGrid && (
              <PricingGrid
                canEditPlanOrTemplate={false}
                canEditPricing={canEditPricing}
                canEditTemplatePricing={canEditTemplatePricing}
                onRemovePricingUsageEntity={onRemovePricingUsageEntity}
                linkedPlanTemplates={pricingData.planTemplates}
                selectedAggregations={selectedAggregations}
                selectedCompoundAggregations={selectedCompoundAggregations}
                selectedItemCounters={selectedItemCounters}
                selectedPlans={pricingData.plans}
                selectedPlanTemplates={pricingData.planTemplates}
                pricings={pricingData!.pricings}
                itemCounterPricings={pricingData!.itemCounterPricings}
              />
            )}
          </React.Fragment>
        )}
      </VStack>
    </Card>
  );
};
