import React, { useMemo } from 'react';

import { Heading } from '@chakra-ui/react';

import { useBreadcrumbsState } from '@/state/breadcrumbs';

export const PageTitle: React.FC = () => {
  const breadcrumbs = useBreadcrumbsState();
  const title = useMemo(() => {
    // Filter out hidden breadcrumbs because we don't want to show them as the title either.
    const breadcrumbsToShow = breadcrumbs.filter(
      ({ hideInTrail }) => !hideInTrail
    );
    return breadcrumbsToShow.length > 0
      ? breadcrumbsToShow[breadcrumbsToShow.length - 1].title
      : undefined;
  }, [breadcrumbs]);

  return title ? <Heading size="lg">{title}</Heading> : null;
};
