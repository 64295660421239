import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useDisclosure } from '@chakra-ui/react';

import { DataType, Id, Plan, QueryParams } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import { selectIsLinkingEntity } from '@/store/utils/linkEntity';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import {
  ColumnDefinition,
  EntityMultiSelectModal,
} from '@/components/common/data/EntitySelectModal';

export interface PlanGroupAddPlansButtonProps {
  onAddPlans: (planIds: Array<string>) => void;
  size?: string;
  accountId?: Id;
}

const planModalSearchFields = ['code', 'name'];
const emptyArray = new Array<any>();

export const PlanGroupAddPlansButton: React.FC<
  PlanGroupAddPlansButtonProps
> = ({ onAddPlans, accountId, size = 'sm' }) => {
  const { t } = useTranslation();
  const planNamings = useEntityNamings(DataType.Plan);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleOnAddPlans = useCallback(
    (ids: Array<Id>) => {
      onClose();
      if (ids.length > 0) {
        onAddPlans(ids);
      }
    },
    [onAddPlans, onClose]
  );
  const isAddingPlanGroupLinksSelector = useMemo(
    () => selectIsLinkingEntity(DataType.PlanGroup, DataType.Plan),
    []
  );
  const isAddingPlanGroupLinks = useSelector(isAddingPlanGroupLinksSelector);

  // If there is a specific account ID we need global plans or plans with that accountId
  // If not, we need to only show global.
  const planModalQueryParams = useMemo<QueryParams>(
    () => (accountId ? { accountId: ['', accountId] } : { accountId: '' }),
    [accountId]
  );
  const planModalColumns = useMemo<Array<ColumnDefinition<Plan>>>(
    () => [
      { id: 'name', accessor: 'name', header: t('forms:labels.name') },
      { id: 'code', accessor: 'code', header: t('forms:labels.code') },
    ],
    [t]
  );

  return (
    <React.Fragment>
      <Button size={size} onClick={onOpen} isLoading={isAddingPlanGroupLinks}>
        {t('forms:buttons.addEntity', {
          entityName: planNamings.singularLower,
        })}
      </Button>
      <EntityMultiSelectModal<Plan>
        columns={planModalColumns}
        dataType={DataType.Plan}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleOnAddPlans}
        params={planModalQueryParams}
        searchFields={planModalSearchFields}
        selected={emptyArray}
      />
    </React.Fragment>
  );
};
