import {
  queryOptions,
  keepPreviousData as keepPreviousDataFn,
} from '@tanstack/react-query';

import {
  DataType,
  list,
  listAll,
  ListAllOptions,
  ListOptions,
  retrieve,
  RetrieveOptions,
} from '@m3ter-com/m3ter-api';

interface ExtraOptions {
  enabled?: boolean;
  refetchInterval?: number;
  keepPreviousData?: boolean;
}

const getExtraOptions = (options: ExtraOptions = {}) => ({
  enabled: options.enabled,
  refetchInterval: options.refetchInterval,
  placeholderData: options.keepPreviousData ? keepPreviousDataFn : undefined,
});

// Generic query creator to list all of the specified data type.
export const dataTypeListAllQuery = <DT extends DataType>(
  { dataType, actionName, pathParams, queryParams }: ListAllOptions<DT>,
  extraOptions?: ExtraOptions
) =>
  queryOptions({
    queryKey: [dataType, 'listAll', actionName, pathParams, queryParams],
    queryFn: () => listAll({ dataType, actionName, pathParams, queryParams }),
    select: (response) => response.data,
    ...getExtraOptions(extraOptions),
  });

export const dataTypeListQuery = <DT extends DataType>(
  {
    dataType,
    actionName,
    pathParams,
    queryParams,
    relationships,
  }: ListOptions<DT>,
  extraOptions?: ExtraOptions
) =>
  queryOptions({
    queryKey: [
      dataType,
      'list',
      actionName,
      pathParams,
      queryParams,
      relationships,
    ],
    queryFn: () =>
      list({ dataType, actionName, pathParams, queryParams, relationships }),
    select: (response) => response.data,
    ...getExtraOptions(extraOptions),
  });

export const dataTypeRetrieveQuery = <DT extends DataType>(
  { dataType, id, actionName, pathParams, queryParams }: RetrieveOptions<DT>,
  extraOptions?: ExtraOptions
) =>
  queryOptions({
    queryKey: [dataType, 'retrieve', id, actionName, pathParams, queryParams],
    queryFn: () =>
      retrieve({ dataType, id, actionName, pathParams, queryParams }),
    ...getExtraOptions(extraOptions),
  });
