import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ChangeEvent,
  FocusEventHandler,
  KeyboardEventHandler,
} from 'react';

import { useMultiStyleConfig } from '@chakra-ui/react';
import { format, isValid } from 'date-fns';

import { Input, InputProps } from '../Input/Input';

export interface DateInputProps extends Omit<InputProps, 'onChange' | 'value'> {
  value: Date | null;
  onChange: (newDate: Date | null) => void;
  includeTime?: boolean;
  isFocussed?: boolean;
}

const getPlaceholder = (locale: string, includeTime: boolean): string => {
  // Need to lowercase the locale because some browsers capitalise the US and others don't
  if (locale.toLowerCase() === 'en-us') {
    return includeTime ? 'MM / DD / YYYY HH:mm' : 'MM / DD / YYYY';
  }

  return includeTime ? 'DD / MM / YYYY HH:mm' : 'DD / MM / YYYY';
};

const getDateFormatKey = (locale: string, includeTime: boolean): string => {
  // Need to lowercase the locale because some browsers capitalise the US and others don't
  if (locale.toLowerCase() === 'en-us') {
    return includeTime ? 'MM / dd / yyyy HH:mm' : 'MM / dd / yyyy';
  }

  return includeTime ? 'dd / MM / yyyy HH:mm' : 'dd / MM / yyyy';
};

const TYPED_SHORT_DATE_REGEX =
  /^(\d{1,2})\s*\/\s*(\d{1,2})\s*(\/?\s*(\d{2,4}))?(\s+\d+:\d+)?$/;

export const DateInput: React.FC<DateInputProps> = ({
  value,
  onChange,
  includeTime = false,
  isFocussed = false,
  ...inputProps
}) => {
  const locale = navigator.language;
  const placeholder = useMemo(
    () => getPlaceholder(locale, includeTime),
    [locale, includeTime]
  );
  const dateFormatKey = useMemo(
    () => getDateFormatKey(locale, includeTime),
    [locale, includeTime]
  );

  const [inputValue, setInputValue] = useState<string>('');
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    if (value === null || !isValid(value)) {
      setInputValue('');
      setIsDirty(false);
    } else {
      setInputValue(format(value, dateFormatKey));
      setIsDirty(false);
    }
  }, [dateFormatKey, value]);

  const onTypeDate = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setIsDirty(true);
  }, []);

  const validateTypedDate = useCallback(() => {
    try {
      const localeIsUs = locale.toLowerCase() === 'en-us';
      const typedDateIsInShortDateFormat =
        TYPED_SHORT_DATE_REGEX.test(inputValue);

      let newDate: Date;
      if (!typedDateIsInShortDateFormat) {
        // If the user is trying to enter a date in some format other than DD/MM/YYYY or
        // MM/DD/YYYY, the safest / easiest option is to use Date.parse
        newDate = new Date(Date.parse(inputValue));
      } else {
        // We don't want to use `Date.parse` because we want to handle DD/MM/YYYY dates
        // (and Date.parse would convert something like "01/02/2022" to a Date instance
        // with a value of "January 2nd 2022") and have control over how we default any
        // missing or shortened values. For example, a year of 22 should be 2022.
        const [
          _fullMatch,
          firstPartString,
          secondPartString,
          _optionalYearPartMatch,
          yearString,
          timeString,
        ] = TYPED_SHORT_DATE_REGEX.exec(inputValue)!;

        // Handle MM/DD when in a US locale.
        const dayString = localeIsUs ? secondPartString : firstPartString;
        const monthString = localeIsUs ? firstPartString : secondPartString;

        const years = yearString
          ? parseInt(yearString, 10)
          : new Date().getFullYear();

        const [hoursString, minutesString] = timeString
          ? timeString.replace(/\s*/g, '').split(':')
          : ['00', '00'];
        newDate = new Date(
          years < 100 ? 2000 + years : years, // Handle 2-digit years
          parseInt(monthString, 10) - 1,
          parseInt(dayString, 10),
          parseInt(hoursString || '00', 10),
          parseInt(minutesString || '00', 10)
        );
      }

      if (!isValid(newDate)) {
        throw new Error('Could not parse typed date.');
      }

      onChange(newDate);
    } catch {
      if (value === null) {
        setInputValue('');
      } else {
        onChange(null);
      }
    }
  }, [inputValue, locale, value, onChange]);

  const inputRef = useRef<HTMLInputElement>(null);
  const onInputKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key !== 'Enter') {
        return;
      }
      event.preventDefault();
      if (!isDirty) {
        return;
      }
      validateTypedDate();
    },
    [isDirty, validateTypedDate]
  );
  const onInputBlur = useCallback<FocusEventHandler<HTMLInputElement>>(() => {
    if (!isDirty) {
      return;
    }
    validateTypedDate();
  }, [isDirty, validateTypedDate]);

  const styles = useMultiStyleConfig('DatePicker', { isFocussed });

  return (
    <Input
      ref={inputRef}
      autoComplete="off"
      type="text"
      value={inputValue}
      placeholder={placeholder}
      onBlur={onInputBlur}
      onChange={onTypeDate}
      onKeyDown={onInputKeyDown}
      sx={styles.dateInput}
      {...inputProps}
    />
  );
};
