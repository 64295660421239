import React from 'react';

import { HStack, Icon, List, ListItem, Text } from '@chakra-ui/react';
import { CheckIcon, MinusIcon, XIcon } from 'lucide-react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { PasswordValidationErrors } from '@/hooks/auth/usePasswordValidator';

interface PasswordValidationListProps {
  passwordValidationErrors: PasswordValidationErrors;
}

interface PasswordValidationErrorProps {
  validator?: boolean;
  helpText: string;
}

const PasswordValidationError: React.FC<PasswordValidationErrorProps> = ({
  validator,
  helpText,
}) => {
  return (
    <ListItem>
      <HStack w="100%" alignItems="center" spacing={2}>
        {validator === undefined ? (
          <Icon as={MinusIcon} />
        ) : (
          <Icon
            as={validator ? CheckIcon : XIcon}
            color={validator ? 'green.500' : 'red.500'}
            data-testid={
              validator
                ? 'valid-password-requirement'
                : 'invalid-password-requirement'
            }
          />
        )}

        <Text>{helpText}</Text>
      </HStack>
    </ListItem>
  );
};

export const PasswordValidationList: React.FC<PasswordValidationListProps> = ({
  passwordValidationErrors,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Text>{t('features:auth.passwordRequirements')}</Text>
      <List>
        <PasswordValidationError
          validator={passwordValidationErrors.isLengthValid}
          helpText={t('features:auth.passwordValidation.validLength')}
        />
        <PasswordValidationError
          validator={passwordValidationErrors.hasUpperCase}
          helpText={t('features:auth.passwordValidation.validUppercase')}
        />
        <PasswordValidationError
          validator={passwordValidationErrors.hasLowerCase}
          helpText={t('features:auth.passwordValidation.validLowercase')}
        />
        <PasswordValidationError
          validator={passwordValidationErrors.hasNumber}
          helpText={t('features:auth.passwordValidation.validNumber')}
        />
        <PasswordValidationError
          validator={passwordValidationErrors.hasSpecialCharacterOrSpace}
          helpText={t('features:auth.passwordValidation.validSpecialCharacter')}
        />
        <PasswordValidationError
          validator={passwordValidationErrors.hasNoLeadingOrTrailingSpace}
          helpText={t('features:auth.passwordValidation.validWhiteSpace')}
        />
      </List>
    </React.Fragment>
  );
};
