type Promises = Record<string, Promise<unknown>>;

type Result<Input extends Promises> = {
  [P in keyof Input]: Awaited<Input[P]>;
};

export const allProperties = <Input extends Promises>(
  input: Input
): Promise<Result<Input>> => {
  const keys = Object.keys(input);

  return Promise.all(Object.values(input)).then((values) => {
    const result: any = {};
    values.forEach((v, i) => {
      result[keys[i]] = v;
    });
    return result;
  });
};
