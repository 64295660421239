import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { StatementDefinitionsRoute } from '@/components/routes/billing/StatementDefinitionsRoute';
import { StatementDefinitionsListRoute } from '@/components/routes/billing/StatementDefinitionsListRoute';
import { StatementDefinitionsCreateRoute } from '@/components/routes/billing/StatementDefinitionsCreateRoute';
import { StatementDefinitionsEditRoute } from '@/components/routes/billing/StatementDefinitionsEditRoute';
import { StatementDefinitionsDetailsRoute } from '@/components/routes/billing/StatementDefinitionsDetailsRoute';

export default (): NamedRoute =>
  createCrudRoute({
    path: 'statement-definitions',
    dataType: DataType.StatementDefinition,
    element: <StatementDefinitionsRoute />,
    list: <StatementDefinitionsListRoute />,
    create: <StatementDefinitionsCreateRoute />,
    edit: <StatementDefinitionsEditRoute />,
    details: <StatementDefinitionsDetailsRoute />,
  });
