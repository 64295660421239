import React from 'react';

export const SortUpIcon: React.FC = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      d="M9.18758 4.28901L6.39851 1.49995C6.1782 1.27964 5.82195 1.27964 5.60398 1.49995L2.81257 4.28901C2.45867 4.64292 2.70945 5.24995 3.21101 5.24995H8.78914C9.2907 5.24995 9.54148 4.64292 9.18758 4.28901Z"
      fill="currentColor"
    />
  </svg>
);
