import React, { useCallback } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import snakeCase from 'lodash/snakeCase';

import { useTranslation } from '../../../hooks/useTranslation';
import { FormField } from '../FormField/FormField';
import { FormInput } from '../FormInput/FormInput';

export interface NameCodeFieldsProps {
  codeFieldPrefix?: string;
  nameFieldPrefix?: string;
  isCodeFieldReadOnly?: boolean;
  isCodeFieldRequired?: boolean;
  autoFillCodeField?: boolean;
  hideLabels?: boolean;
  isDisabled?: boolean;
}

export const NameCodeFields: React.FC<NameCodeFieldsProps> = ({
  codeFieldPrefix,
  nameFieldPrefix,
  isCodeFieldReadOnly = false,
  isCodeFieldRequired = true,
  autoFillCodeField = true,
  hideLabels = false,
  isDisabled = false,
}) => {
  const { t } = useTranslation();

  const codeFieldName = `${codeFieldPrefix ?? ''}code`;
  const nameFieldName = `${nameFieldPrefix ?? ''}name`;

  const { setValue } = useFormContext();
  const name = useWatch({ name: nameFieldName });
  const code = useWatch({ name: codeFieldName });

  const onNameBlur = useCallback(() => {
    if ((code === undefined || code === '') && autoFillCodeField) {
      setValue(codeFieldName, snakeCase(name));
    }
  }, [code, autoFillCodeField, setValue, codeFieldName, name]);

  return (
    <React.Fragment>
      <FormField
        isRequired
        isDisabled={isDisabled}
        name={nameFieldName}
        label={t('forms:labels.name')}
        control={FormInput}
        onBlur={onNameBlur}
        hideLabel={hideLabels}
      />
      <FormField
        isDisabled={isDisabled}
        isReadOnly={isCodeFieldReadOnly}
        isRequired={isCodeFieldRequired}
        name={codeFieldName}
        label={t('forms:labels.code')}
        control={FormInput}
        hideLabel={hideLabels}
      />
    </React.Fragment>
  );
};
