type PageItem = number | typeof DOTS;

export const DOTS = 'dots';

const range = (min: number, max: number) =>
  Array.from({ length: max - min + 1 }).map((_, i) => i + min);

// Total maximum number of pages and dots. Using an odd number because then you
// get a good balance around the current page.
const MAX_CONTROLS = 7;

export const calculatePages = (
  currentPage: number,
  pageCount: number,
  hasMore: boolean = false
) => {
  const siblings = Math.floor((MAX_CONTROLS - 2) / 2);

  // Left and right page number range based on siblings.
  let leftPageNumber = currentPage - siblings;
  let rightPageNumber = currentPage + siblings + (hasMore ? 1 : 0); // Add 1 when hasMore is true because we don't add the extra last page

  // Calculate the range of possible pages, based on always showing page 1 and showing
  // the last page if hasMore is false.
  const lowerLimit = 2;
  const upperLimit = hasMore ? pageCount + 1 : pageCount - 1;

  // If the left page is outside the limit, adjust by increasing both pages.
  if (leftPageNumber < lowerLimit) {
    rightPageNumber += lowerLimit - leftPageNumber;
    leftPageNumber = lowerLimit;
  }

  // If the right page isoutside limit, adjust by decreasing both pages.
  if (rightPageNumber > upperLimit) {
    leftPageNumber -= rightPageNumber - upperLimit;
    rightPageNumber = upperLimit;
  }

  // Finally, limit to the page range.
  leftPageNumber = Math.max(leftPageNumber, lowerLimit);
  rightPageNumber = Math.min(rightPageNumber, upperLimit);

  // Work out whether to display dots.
  const leftDots = leftPageNumber > lowerLimit; // Avoid displaying 1 … 2
  const rightDots = hasMore || rightPageNumber < upperLimit;

  // Adjust first / last page to account for being replaced by dots.
  if (leftDots) {
    leftPageNumber += 1;
  }
  if (rightDots) {
    rightPageNumber -= 1;
  }

  const pages: Array<PageItem> = [1]; // Always include page 1.

  if (leftDots) {
    pages.push(DOTS);
  }

  pages.push(...range(leftPageNumber, rightPageNumber));

  if (rightDots) {
    pages.push(DOTS);
  }

  // Always add the last page if hasMore is false and there isn't only 1 page.
  if (!hasMore && pageCount > 1) {
    pages.push(pageCount);
  }

  return pages;
};
