import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { AccountPlan } from '@m3ter-com/m3ter-api';

import { validateCustomFields } from './customFields';

const suite = create('accountPlan', (data: AccountPlan) => {
  test(
    'startDate',
    i18next.t('forms:validations.common.startDateRequired'),
    () => {
      enforce(data.startDate).isNotEmpty();
    }
  );

  validateCustomFields('customFields', data.customFields);
});

export default suite;
