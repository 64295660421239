import React, { PropsWithChildren } from 'react';

import { Box, Spinner } from '@chakra-ui/react';

import { AppError } from '@/types/errors';

import { ErrorAlert } from '@/components/common/errors/ErrorAlert';

export interface LoadingErrorContentSwitchProps {
  isLoading: boolean;
  error?: AppError | null;
  errorTitle?: string;
  renderLazily?: boolean;
  showContent?: boolean;
  showContentOnError?: boolean;
}

export const LoadingErrorContentSwitch: React.FC<
  PropsWithChildren<LoadingErrorContentSwitchProps>
> = ({
  isLoading,
  error,
  errorTitle,
  renderLazily = true,
  showContent = true,
  showContentOnError = false,
  children,
}) => {
  const renderSpinner = isLoading;
  const renderError = !isLoading && !!error;
  const renderContent =
    !isLoading && !!showContent && (showContentOnError ? true : !error);

  return (
    <React.Fragment>
      {renderSpinner && <Spinner data-testid="lecs-spinner" />}
      {renderError && (
        <ErrorAlert data-testid="lecs-error" error={error} title={errorTitle} />
      )}
      {renderLazily && renderContent && children}
      {!renderLazily && (
        <Box display={renderContent ? 'contents' : 'none'}>{children}</Box>
      )}
    </React.Fragment>
  );
};
