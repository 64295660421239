import { all, call, StrictEffect } from 'redux-saga/effects';

import {
  DataType,
  Aggregation,
  CompoundAggregation,
  Id,
  Counter,
  CounterPricing,
  Plan,
  PlanGroupLink,
  PlanTemplate,
  Pricing,
  QueryParams,
  PricingCommon,
} from '@m3ter-com/m3ter-api';

import { uniq } from '@/util/array';
import { ids } from '@/util/data';
import { listAllData, retrieveData } from '@/store/data/data.saga';

export interface LoadAllPricingDataByPlanGroupReturn
  extends LoadAllPricingDataByPlansReturn {
  planGroupId: string;
}

export interface LoadAllPricingDataByPlansReturn {
  planIds: Array<string>;
  linkedPlanTemplateIds: Array<string>;
  aggregationIds: Array<string>;
  compoundAggregationIds: Array<string>;
  itemCounterIds: Array<string>;
  pricingIds: Array<string>;
  itemCounterPricingIds: Array<string>;
}

export interface LoadAllPricingDataByPlanTemplatesReturn {
  planTemplateIds: Array<string>;
  aggregationIds: Array<string>;
  compoundAggregationIds: Array<string>;
  itemCounterIds: Array<string>;
  pricingIds: Array<string>;
  itemCounterPricingIds: Array<string>;
}

interface LoadAggregationsForPricingsReturn {
  aggregations: Array<Aggregation>;
  compoundAggregations: Array<CompoundAggregation>;
}

/*
 * This function batches requests to avoid excessively large requests when loading pricing data
 * for multiple plans or plan templates. It combines the results to ensure efficient data loading.
 * This approach is necessary because we load all pricing data for all plans or plan templates
 * within a plan group on the account plan details page, where there could be many plans.
 * Ideally, a more efficient method would be used to load pricing data for many plans or plan templates,
 * such as when a specific plan within a plan group is selected to view its pricing data. However,
 * the current logic is too tightly coupled to other functionality and would require a significant refactor.
 * Consider exploring more efficient methods for handling large data loads in the future.
 */
export function* batchLoadPricingRequests<P extends PricingCommon>(
  loadPricingDataFn: (
    params: QueryParams
  ) => Generator<StrictEffect, Array<P>, any>,
  paramsSets: Array<QueryParams>,
  batchSize: number = 10
): Generator<StrictEffect, Array<P>, any> {
  const pricingData: Array<P> = [];

  for (let i = 0; i < paramsSets.length; i += batchSize) {
    const paramsBatch = paramsSets.slice(i, i + batchSize);
    const batchLoadedPricingData = yield all(
      paramsBatch.map((params) => call(loadPricingDataFn, params))
    );
    pricingData.push(...batchLoadedPricingData.flat());
  }

  return pricingData;
}

export function* loadPricingEntities(
  params: QueryParams
): Generator<StrictEffect, Array<Pricing>, any> {
  const pricings: Array<Pricing> = yield call(
    listAllData,
    DataType.Pricing,
    params
  );
  return pricings;
}

export function* loadItemCounterPricingEntities(
  params: QueryParams
): Generator<StrictEffect, Array<CounterPricing>, any> {
  const itemCounterPricings: Array<CounterPricing> = yield call(
    listAllData,
    DataType.CounterPricing,
    params
  );
  return itemCounterPricings;
}

export function* loadPricingsForPlans(
  planIds: Array<string>
): Generator<StrictEffect, Array<Pricing>, any> {
  const data: Array<Pricing> = yield call(
    batchLoadPricingRequests,
    loadPricingEntities,
    planIds.map((planId) => ({ planId }))
  );
  return data;
}

export function* loadItemCounterPricingsForPlans(
  planIds: Array<string>
): Generator<StrictEffect, Array<CounterPricing>, any> {
  const data: Array<CounterPricing> = yield call(
    batchLoadPricingRequests,
    loadItemCounterPricingEntities,
    planIds.map((planId) => ({ planId }))
  );
  return data;
}

export function* loadPricingsForPlanTemplates(
  planTemplateIds: Array<string>
): Generator<StrictEffect, Array<Pricing>, any> {
  const data: Array<Pricing> = yield call(
    batchLoadPricingRequests,
    loadPricingEntities,
    planTemplateIds.map((planTemplateId) => ({ planTemplateId }))
  );
  return data;
}

export function* loadItemCounterPricingsForPlanTemplates(
  planTemplateIds: Array<string>
): Generator<StrictEffect, Array<CounterPricing>, any> {
  const data: Array<CounterPricing> = yield call(
    batchLoadPricingRequests,
    loadItemCounterPricingEntities,
    planTemplateIds.map((planTemplateId) => ({ planTemplateId }))
  );
  return data;
}

export function* loadAggregationsForPricings(
  pricings: Array<Pricing>
): Generator<StrictEffect, LoadAggregationsForPricingsReturn, any> {
  const aggregationIdsSet = new Set<string>();
  const compoundAggregationIdsSet = new Set<string>();
  pricings.forEach((pricing) => {
    if (pricing.aggregationId) {
      aggregationIdsSet.add(pricing.aggregationId);
    }

    if (pricing.compoundAggregationId) {
      compoundAggregationIdsSet.add(pricing.compoundAggregationId);
    }
  });
  const aggregationIds = Array.from(aggregationIdsSet);
  const compoundAggregationIds = Array.from(compoundAggregationIdsSet);

  let aggregations: Array<Aggregation>;
  if (aggregationIds.length === 0) {
    aggregations = [];
  } else {
    aggregations = yield call(listAllData, DataType.Aggregation, {
      ids: aggregationIds,
    });
  }

  let compoundAggregations: Array<CompoundAggregation>;
  if (compoundAggregationIds.length === 0) {
    compoundAggregations = [];
  } else {
    compoundAggregations = yield call(
      listAllData,
      DataType.CompoundAggregation,
      {
        ids: compoundAggregationIds,
      }
    );
  }

  return {
    aggregations,
    compoundAggregations,
  };
}

export function* loadItemCountersForItemCounterPricings(
  itemCounterPricings: Array<CounterPricing>
): Generator<StrictEffect, Array<Counter>, any> {
  const itemCounterIdsSet = new Set<Id>();
  itemCounterPricings.forEach((itemCounterPricing) => {
    itemCounterIdsSet.add(itemCounterPricing.counterId);
  });
  const itemCounterIds = Array.from(itemCounterIdsSet);

  let itemCounters: Array<Counter>;
  if (itemCounterIds.length === 0) {
    itemCounters = [];
  } else {
    itemCounters = yield call(listAllData, DataType.Counter, {
      ids: itemCounterIds,
    });
  }

  return itemCounters;
}

export function* loadAllPricingDataByPlanGroup(
  planGroupId: string
): Generator<StrictEffect, LoadAllPricingDataByPlanGroupReturn, any> {
  yield call(retrieveData, DataType.PlanGroup, planGroupId);
  const planGroupLinks: Array<PlanGroupLink> = yield call(
    listAllData,
    DataType.PlanGroupLink,
    { planGroup: planGroupId }
  );
  if (planGroupLinks.length === 0) {
    return {
      planGroupId,
      planIds: [],
      linkedPlanTemplateIds: [],
      aggregationIds: [],
      compoundAggregationIds: [],
      itemCounterIds: [],
      pricingIds: [],
      itemCounterPricingIds: [],
    };
  }

  const planIds = planGroupLinks.map((link) => link.planId);
  const plansPricingData = yield call(loadAllPricingDataByPlans, planIds);
  return {
    ...plansPricingData,
    planGroupId,
  };
}

export function* loadAllPricingDataByPlans(
  planIds: Array<string>
): Generator<StrictEffect, LoadAllPricingDataByPlansReturn, any> {
  if (planIds.length > 0) {
    // Load all the plans.
    const plans: Array<Plan> = yield call(listAllData, DataType.Plan, {
      ids: planIds,
    });

    if (plans.length > 0) {
      // Create a unique list of plan templates that any of them use.
      const linkedPlanTemplateIds: Array<string> = uniq(
        plans.map(({ planTemplateId }) => planTemplateId)
      );

      // Load the linked plan templates.
      const linkedPlanTemplates: Array<PlanTemplate> = yield call(
        listAllData,
        DataType.PlanTemplate,
        {
          ids: linkedPlanTemplateIds,
        }
      );

      // Load all the pricings for all the plans.
      const allPlanPricings: Array<Pricing> = yield call(
        loadPricingsForPlans,
        ids(plans)
      );
      // Load all the pricings for all the linked plan templates.
      const allPlanTemplatePricings: Array<Pricing> = yield call(
        loadPricingsForPlanTemplates,
        linkedPlanTemplateIds
      );
      // Load all the item counter pricings for all the plans.
      const allPlanItemCounterPricings: Array<CounterPricing> = yield call(
        loadItemCounterPricingsForPlans,
        ids(plans)
      );
      // Load all the item counter pricings for all the linked plan templates.
      const allPlanTemplateItemCounterPricings: Array<CounterPricing> =
        yield call(
          loadItemCounterPricingsForPlanTemplates,
          linkedPlanTemplateIds
        );

      // Combine all pricings.
      const allPricings: Array<Pricing> = [
        ...allPlanPricings,
        ...allPlanTemplatePricings,
      ];

      // Combine all item counter pricings.
      const allItemCounterPricings: Array<CounterPricing> = [
        ...allPlanItemCounterPricings,
        ...allPlanTemplateItemCounterPricings,
      ];

      // Load all aggregations used in any pricing.
      const { aggregations, compoundAggregations } = yield call(
        loadAggregationsForPricings,
        allPricings
      );

      const itemCounters = yield call(
        loadItemCountersForItemCounterPricings,
        allItemCounterPricings
      );

      return {
        planIds: ids(plans),
        linkedPlanTemplateIds: ids(linkedPlanTemplates),
        aggregationIds: ids(aggregations),
        compoundAggregationIds: ids(compoundAggregations),
        itemCounterIds: ids(itemCounters),
        pricingIds: ids(allPricings),
        itemCounterPricingIds: ids(allItemCounterPricings),
      };
    }
  }

  return {
    planIds: [],
    linkedPlanTemplateIds: [],
    aggregationIds: [],
    compoundAggregationIds: [],
    itemCounterIds: [],
    pricingIds: [],
    itemCounterPricingIds: [],
  };
}

export function* loadAllPricingDataByPlanTemplates(
  planTemplateIds: Array<string>
): Generator<StrictEffect, LoadAllPricingDataByPlanTemplatesReturn, any> {
  if (planTemplateIds.length > 0) {
    // Load all the plan templates.
    const planTemplates: Array<PlanTemplate> = yield call(
      listAllData,
      DataType.PlanTemplate,
      {
        ids: planTemplateIds,
      }
    );

    if (planTemplates.length > 0) {
      // Load all pricing related to the plan templates.
      const pricings = yield call(
        loadPricingsForPlanTemplates,
        ids(planTemplates)
      );
      const itemCounterPricings = yield call(
        loadItemCounterPricingsForPlanTemplates,
        ids(planTemplates)
      );

      // Load all aggregations used in any pricing.
      const { aggregations, compoundAggregations } = yield call(
        loadAggregationsForPricings,
        pricings
      );

      // Load all item counters used in any item counter pricing.
      const itemCounters = yield call(
        loadItemCountersForItemCounterPricings,
        itemCounterPricings
      );

      return {
        planTemplateIds: ids(planTemplates),
        aggregationIds: ids(aggregations),
        compoundAggregationIds: ids(compoundAggregations),
        itemCounterIds: ids(itemCounters),
        pricingIds: ids(pricings),
        itemCounterPricingIds: ids(itemCounterPricings),
      };
    }
  }

  return {
    planTemplateIds: [],
    aggregationIds: [],
    compoundAggregationIds: [],
    itemCounterIds: [],
    pricingIds: [],
    itemCounterPricingIds: [],
  };
}
