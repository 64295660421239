import { create, each, omitWhen } from 'vest';

import { CustomFieldDefaults } from '@m3ter-com/m3ter-api';

import { validateCustomFields } from './customFields';

const suite = create(
  'customFieldDefaults',
  (data: Partial<CustomFieldDefaults>) => {
    omitWhen(data === undefined, () => {
      each(
        Object.keys(data).filter(
          (key) => typeof data[key as keyof CustomFieldDefaults] === 'object'
        ),
        (key) => {
          const fieldValue = data[key as keyof CustomFieldDefaults];
          if (typeof fieldValue === 'object') {
            validateCustomFields(key, fieldValue);
          }
        }
      );
    });
  }
);

export default suite;
