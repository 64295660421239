import { useState, useCallback } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  Id,
  listUserGroupItems,
  User,
  addUsersToUserGroup,
  removeUsersFromUserGroup,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useNotification from '@/hooks/util/useNotification';
import { extractError } from '@/util/error';
import useOrg from '@/hooks/data/crud/useOrg';

const useUserGroupManagement = (userGroupId: Id) => {
  const { t } = useTranslation();
  const { toast } = useNotification();
  const queryClient = useQueryClient();
  const { currentOrgId: organizationId } = useOrg();

  const [selectedUserIds, setSelectedUserIds] = useState<Array<Id>>([]);

  const {
    onOpen: onOpenUsersModal,
    onClose: onCloseUsersModal,
    isOpen: isUsersModalOpen,
  } = useDisclosure();

  const {
    data: userGroupItems = [],
    isPending: isLoadingUserGroupItems,
    error: errorLoadingUserGroupItems,
  } = useQuery({
    queryFn: () => listUserGroupItems(organizationId, userGroupId),
    queryKey: ['listUserGroupItems', organizationId, userGroupId],
    select: (response) => response.data,
  });

  const isUserDisabled = useCallback(
    ({ id }: User) => userGroupItems.some(({ targetId }) => targetId === id),
    [userGroupItems]
  );

  const { isPending: isAddingUsersToUserGroup, mutate: addToUserGroup } =
    useMutation({
      mutationFn: async (userIds: Array<Id>) =>
        addUsersToUserGroup(organizationId, userGroupId, userIds),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['listUserGroupItems', organizationId, userGroupId],
        });
        toast({
          status: 'success',
          description: t('notifications:usersAddedToUserGroupSuccess'),
          duration: 5000,
        });
      },
      onError: (error) => {
        toast({ status: 'error', description: extractError(error).message });
      },
    });

  const {
    isPending: isRemovingUsersFromUserGroup,
    mutate: removeFromUserGroup,
  } = useMutation({
    mutationFn: async (userIds: Array<Id>) =>
      removeUsersFromUserGroup(organizationId, userGroupId, userIds),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['listUserGroupItems', organizationId, userGroupId],
      });
      toast({
        status: 'success',
        description: t('notifications:usersRemovedFromUserGroupSuccess'),
        duration: 5000,
      });
    },
    onError: (error) => {
      toast({ status: 'error', description: extractError(error).message });
    },
  });

  const onAddUsersToUserGroup = useCallback(
    (userIds: Array<Id>) => {
      addToUserGroup(userIds, {
        onSuccess: () => {
          onCloseUsersModal();
        },
      });
    },
    [addToUserGroup, onCloseUsersModal]
  );

  const onRemoveUsersFromUserGroup = useCallback(() => {
    removeFromUserGroup(selectedUserIds, {
      onSuccess: () => {
        setSelectedUserIds([]);
      },
    });
  }, [removeFromUserGroup, selectedUserIds]);

  return {
    errorLoadingUserGroupItems,
    isAddingUsersToUserGroup,
    isLoadingUserGroupItems,
    isRemovingUsersFromUserGroup,
    isUserDisabled,
    isUsersModalOpen,
    onAddUsersToUserGroup,
    onCloseUsersModal,
    onOpenUsersModal,
    onRemoveUsersFromUserGroup,
    selectedUserIds,
    setSelectedUserIds,
    userGroupItems,
  };
};

export default useUserGroupManagement;
