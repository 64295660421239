import React, { useEffect } from 'react';

import { useBreadcrumbsState } from '@/state/breadcrumbs';
import useOrg from '@/hooks/data/crud/useOrg';

export interface DocumentTitleProps {
  siteTitle: string;
}

export const DocumentTitle: React.FC<DocumentTitleProps> = ({ siteTitle }) => {
  const breadcrumbs = useBreadcrumbsState();
  const { currentOrg } = useOrg();

  useEffect(() => {
    const parts = [siteTitle];
    // 'currentOrg' will be undefined before the app is bootstrapped so we need this check here.
    if (currentOrg?.organizationName) {
      parts.unshift(currentOrg.organizationName);
    }
    breadcrumbs.forEach((breadcrumb) => {
      parts.unshift(breadcrumb.title);
    });

    document.title = parts.join(' - ');
  }, [siteTitle, breadcrumbs, currentOrg]);

  return null;
};
