import React from 'react';

import { GridItem, List, ListItem, SimpleGrid } from '@chakra-ui/react';

import { DataType, Aggregation } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';
import { formatNumber } from '@m3ter-com/console-core/utils';

import { isAggregationSegmented } from '@/util/aggregation';
import { formatEntityUnit } from '@/util/data';
import { DetailsCard } from '@/components/common/data/DetailsCard';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink';

export interface AggregationDetailsProps {
  aggregation: Aggregation;
  showEditButton?: boolean;
  showMetadata?: boolean;
  showSegments?: boolean;
}

export const AggregationDetails: React.FC<AggregationDetailsProps> = ({
  aggregation,
  showEditButton,
  showMetadata,
  showSegments = false,
}) => {
  const { t } = useTranslation();
  const isSegmented = isAggregationSegmented(aggregation);

  return (
    <DetailsCard
      data={aggregation}
      dataType={DataType.Aggregation}
      showEditButton={showEditButton}
      showMetadata={showMetadata}
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue
            label={t('forms:labels.code')}
            value={aggregation.code}
            data-testid="code"
          />
          <KeyValue label={t('forms:labels.name')} value={aggregation.name} />
          <KeyValueReferenceLink
            label={t('common:meter')}
            dataType={DataType.Meter}
            id={aggregation.meterId}
            accessor="name"
          />
          <KeyValue
            label={t('forms:labels.targetField')}
            value={aggregation.targetField}
          />
          <KeyValue
            label={t('common:aggregation')}
            value={t(
              `features:usage.aggregationType.${aggregation.aggregation}`
            )}
          />
          <KeyValue
            label={t('forms:labels.unit')}
            value={formatEntityUnit(aggregation.unit)}
          />
          <KeyValue
            label={t('forms:labels.quantityPerUnit')}
            value={formatNumber(aggregation.quantityPerUnit)}
          />
          <KeyValue
            label={t('forms:labels.rounding')}
            value={t(`common:rounding.${aggregation.rounding}`)}
          />
          {showSegments && isSegmented && (
            <React.Fragment>
              <KeyValue
                label={t('features:aggregation.segmentedFields')}
                value={aggregation.segmentedFields!.join(', ')}
              />
              <GridItem colSpan={3}>
                <KeyValue
                  label={t('features:aggregation.segments')}
                  value={
                    <List styleType="circle">
                      {aggregation.segments!.map((segment, segmentIndex) =>
                        Object.keys(segment).length > 0 ? (
                          // eslint-disable-next-line react/no-array-index-key
                          <ListItem key={`segment-${segmentIndex}`}>
                            {Object.entries(segment)
                              .map(
                                ([fieldName, value]) =>
                                  `${fieldName} = ${value}`
                              )
                              .join(' & ')}
                          </ListItem>
                        ) : (
                          // eslint-disable-next-line react/no-array-index-key
                          <ListItem key={`segment-${segmentIndex}`}>
                            ({t('common:any')})
                          </ListItem>
                        )
                      )}
                    </List>
                  }
                />
              </GridItem>
            </React.Fragment>
          )}
        </SimpleGrid>
      }
    />
  );
};
