import React from 'react';

import { DataType, IntegrationConfig } from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Form, FormActions } from '@m3ter-com/console-core/components';
import { FormStack } from '@m3ter-com/ui-components';

import { BaseFormProps } from '@/types/forms';

import validationSchema from '@/validation/integrationConfig';
import useEntityNamings from '@/hooks/util/useEntityNamings';

import { IntegrationConfigFormConfigureFields } from './IntegrationConfigFormConfigureFields';

const defaultInitialValues: DeepPartial<IntegrationConfig> = {
  configData: {},
};

interface IntegrationConfigEditFormProps
  extends BaseFormProps<IntegrationConfig> {}

export const IntegrationConfigForm: React.FC<
  IntegrationConfigEditFormProps
> = ({
  isEdit,
  isSaving,
  initialValues = defaultInitialValues,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Integration);

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <FormStack>
        <IntegrationConfigFormConfigureFields
          destination={initialValues.destination ?? ''}
          entityType={initialValues.entityType ?? ''}
        />
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t(
            isEdit
              ? 'forms:buttons.updateEntity'
              : 'forms:buttons.createEntity',
            {
              entityName: entityNamings.singularLower,
            }
          )}
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
