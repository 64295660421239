import React from 'react';

import { NamedRoute } from '@/types/routes';

import { DataExportRootRoute } from '@/components/routes/data-exports/DataExportRootRoute';
import { DataExportOverviewRoute } from '@/components/routes/data-exports/DataExportOverviewRoute';
import { AdhocExportCreateRoute } from '@/components/routes/data-exports/export-schedules/AdhocExportCreateRoute';

import setupExportDesinationsRoute from './export-destinations';
import setupExportSchedulesRoute from './export-schedules';

export default (): NamedRoute => ({
  path: 'export',
  name: 'data.export',
  element: <DataExportRootRoute />,
  children: [
    { index: true, element: <DataExportOverviewRoute /> },
    setupExportDesinationsRoute(),
    setupExportSchedulesRoute(),
    {
      path: 'ad-hoc-export',
      name: 'ad-hoc-export',
      element: <AdhocExportCreateRoute />,
    },
  ],
});
