import React from 'react';

import { HStack } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  FormAdvancedNumberInput,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BillInAdvanceField } from '@/components/features/pricing/BillInAdvanceField';
import useOrg from '@/hooks/data/crud/useOrg';
import { getBillInAdvanceDescription } from '@/util/billing';

export const PlanTemplateFormFields: React.FC = () => {
  const { t } = useTranslation();
  const { orgConfig } = useOrg();

  return (
    <React.Fragment>
      <FormSection isOptional heading={t('forms:labels.standingCharge')}>
        <FormField
          isRequired
          name="standingCharge"
          label={t('forms:labels.standingCharge')}
          control={FormAdvancedNumberInput}
        />
        <FormField
          isRequired
          name="standingChargeBillInAdvance"
          helpText={t('forms:helpText.standingChargeBilling')}
          label={t('forms:labels.standingChargeBilling')}
          control={BillInAdvanceField}
          defaultLabel={t('forms:labels.useConfigFromOrgConfig', {
            default: getBillInAdvanceDescription(
              orgConfig.standingChargeBillInAdvance
            ),
          })}
        />
        <HStack spacing={4} alignSelf="stretch">
          <FormField
            isRequired
            name="standingChargeInterval"
            label={t('forms:labels.standingChargeInterval')}
            control={FormInput}
            type="number"
          />
          <FormField
            isRequired
            name="standingChargeOffset"
            label={t('forms:labels.standingChargeOffset')}
            control={FormInput}
            type="number"
          />
        </HStack>
        <FormField
          name="standingChargeDescription"
          helpText={t('features:pricing.displayedBillLineItem')}
          label={t('forms:labels.standingChargeDescription')}
          control={FormInput}
        />
      </FormSection>
      <FormSection isOptional heading={t('forms:labels.minimumSpend')}>
        <FormField
          name="minimumSpend"
          label={t('forms:labels.productMinimumSpend')}
          control={FormAdvancedNumberInput}
        />
        <FormField
          isRequired
          name="minimumSpendBillInAdvance"
          helpText={t('forms:helpText.minimumSpendBilling')}
          label={t('forms:labels.minimumSpendBilling')}
          control={BillInAdvanceField}
          defaultLabel={t('forms:labels.useConfigFromOrgConfig', {
            default: getBillInAdvanceDescription(
              orgConfig.minimumSpendBillInAdvance
            ),
          })}
        />
        <FormField
          name="minimumSpendDescription"
          helpText={t('features:pricing.displayedBillLineItem')}
          label={t('forms:labels.minimumSpendDescription')}
          control={FormInput}
        />
      </FormSection>
    </React.Fragment>
  );
};
