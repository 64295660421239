import React, { useCallback, useMemo } from 'react';

import { StyleProps } from '@chakra-ui/react';
import { formatInTimeZone } from 'date-fns-tz';

import useDatePickerDate from '../useDatePickerDate';
import {
  CalendarTranslations,
  defaultTranslations,
} from '../Calendar/CalendarContext';

import { SystemDatePicker } from './SystemDatePicker';

// Re-export to match component name.
export type DatePickerTranslations = CalendarTranslations;

export interface DatePickerProps extends StyleProps {
  value: string | null;
  isDisabled?: boolean;
  isRequired?: boolean;
  showTimeSelect?: boolean;
  timeZone?: string;
  translations?: CalendarTranslations;
  onChange: (newDate: string | null) => void;
}

/**
 * A wrapper around our SystemDatePicker that adds timezone support.
 *
 * We have a simpler date-picker component that works entirely using the Date
 * class, which works in the system's timezone by default.
 * We then use this wrapper component to provide timezone conversion functionality
 * so that we can keep all of the funky timezone logic in one place.
 *
 * This component expects the string representation of the date passed into it
 * (via the `value` prop) to be in the the time zone that is passed in (via the
 * `timezone` prop).
 * When this value changes, we convert it to the system's
 * time zone so that it can be used by our SystemDatePicker.
 * When a new time/date is selected, we construct that time/date in the
 * selected timezone before passing it to the `onChange` callback.
 */
export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  isDisabled = false,
  isRequired,
  showTimeSelect = false,
  timeZone,
  translations = defaultTranslations,
  ...styleProps
}) => {
  const { toSystemDate, fromSystemDate } = useDatePickerDate(
    timeZone,
    showTimeSelect
  );

  const handleChange = useCallback(
    (newDate: Date | null) => {
      onChange(fromSystemDate(newDate));
    },
    [onChange, fromSystemDate]
  );

  const selectedDate = useMemo(
    () => toSystemDate(value),
    [toSystemDate, value]
  );

  const formattedTimeZone = useMemo<string | undefined>(() => {
    if (!timeZone) return undefined;
    return formatInTimeZone(new Date(), timeZone, 'zzz');
  }, [timeZone]);

  return (
    <SystemDatePicker
      value={selectedDate}
      isClearable={!isRequired}
      isDisabled={isDisabled}
      showTimeSelect={showTimeSelect}
      translations={translations}
      timeZoneLabel={formattedTimeZone}
      onChange={handleChange}
      {...styleProps}
    />
  );
};
