import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import { StoreWithActions } from '@/state/types';

export type RecentEntities = Partial<Record<DataType, Array<Id>>>;

interface RecentlyViewedState {
  recentEntities: Record<Id, RecentEntities>;
}

interface RecentlyViewedActions {
  addRecentEntity: (organizationId: Id, dataType: DataType, id: Id) => void;
}

type RecentlyViewedStore = StoreWithActions<
  RecentlyViewedState,
  RecentlyViewedActions
>;

const VIEWED_DATA_TYPE_PAGE_LIMIT = 10;

const useRecentlyViewedStore = create<RecentlyViewedStore>()(
  persist(
    (set) => ({
      recentEntities: {},
      actions: {
        addRecentEntity: (organizationId, dataType, id) =>
          set((state) => {
            const currentList =
              state.recentEntities[organizationId]?.[dataType] || [];

            // Limit the new list to the maximum allowed length
            const limitedList = [
              id,
              ...currentList.filter((storedId) => storedId !== id),
            ].slice(0, VIEWED_DATA_TYPE_PAGE_LIMIT);

            // Update the recentEntities object with the new list for this dataType
            const updatedEntities = {
              ...state.recentEntities,
              [organizationId]: {
                ...state.recentEntities[organizationId],
                [dataType]: limitedList,
              },
            };

            return { recentEntities: updatedEntities };
          }),
      },
    }),
    {
      name: 'M3TER__RE',
      partialize: (state) => ({ recentEntities: state.recentEntities }),
    }
  )
);

export const useRecentEntitiesState = () =>
  useRecentlyViewedStore((state) => state.recentEntities);

export const useRecentlyViewedActions = () =>
  useRecentlyViewedStore((state) => state.actions);
