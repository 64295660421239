import { useQuery } from '@tanstack/react-query';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import { dataTypeRetrieveQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';

const useLatestEntityIntegrationRun = (entityType: string, entityId?: Id) => {
  const pathParams = useOrgPathParams({ entityId, entityType });
  const { data: latestIntegrationRun } = useQuery(
    dataTypeRetrieveQuery(
      {
        dataType: DataType.IntegrationRun,
        actionName: 'getLatestEntityRun',
        pathParams,
      },
      { enabled: !!entityId }
    )
  );

  return { latestIntegrationRun };
};

export default useLatestEntityIntegrationRun;
