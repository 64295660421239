import React, { ReactNode } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Skeleton,
  VStack,
  VisuallyHidden,
} from '@chakra-ui/react';

import { FCWithControl } from '../../../types';
import { useFormError } from '../../../hooks';
import { useFieldsetContext } from '../FormFieldset/FormFieldset';

export interface FormFieldProps {
  name: string;
  label: string;
  hideLabel?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
  isReadOnly?: boolean;
  showField?: boolean;
  helpText?: ReactNode;
  alert?: ReactNode;
}

export const FormField: FCWithControl<FormFieldProps> = (props) => {
  const fieldset = useFieldsetContext();

  const {
    name,
    label,
    isDisabled = fieldset?.isDisabled ?? false,
    isLoading = false,
    isRequired = false,
    isReadOnly = false,
    hideLabel = false,
    showField = true,
    helpText,
    alert,
    type,
    control: Control,
    ...controlProps
  } = props;

  const { isInvalid, message } = useFormError(name);

  const formLabel = <FormLabel>{label}</FormLabel>;

  return (
    <FormControl
      id={name}
      isInvalid={!!isInvalid}
      isDisabled={isDisabled}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      display={type === 'hidden' ? 'none' : undefined}
      cursor={isReadOnly ? 'no-drop' : 'initial'}
    >
      {hideLabel ? <VisuallyHidden>{formLabel}</VisuallyHidden> : formLabel}
      <Skeleton
        isLoaded={!isLoading}
        w="100%"
        pointerEvents={isReadOnly ? 'none' : 'initial'}
      >
        {helpText && (
          <FormHelperText mt={0} mb={2}>
            {helpText}
          </FormHelperText>
        )}
        <VStack spacing={4} alignItems="stretch">
          {alert}
          {showField && (
            <Control
              name={name}
              isDisabled={isDisabled}
              isRequired={isRequired}
              type={type}
              {...controlProps}
            />
          )}
        </VStack>
        {isInvalid && <FormErrorMessage>{message}</FormErrorMessage>}
      </Skeleton>
    </FormControl>
  );
};
