import React from 'react';

import { VStack } from '@chakra-ui/react';

import { Organization } from '@m3ter-com/m3ter-api';

import { OrganizationDetails } from '@/components/features/organization/OrganizationDetails';
import { OrganizationConfigSummary } from '@/components/features/organization/OrganizationConfigSummary';

export interface OrganizationOverviewProps {
  editConfigUrl: string;
  org: Organization;
}

export const OrganizationOverview: React.FC<OrganizationOverviewProps> = ({
  editConfigUrl,
  org,
}) => (
  <VStack spacing={4} alignItems="stretch">
    <OrganizationDetails org={org} showMetadata={false} />
    <OrganizationConfigSummary editUrl={editConfigUrl} />
  </VStack>
);
