import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  VisuallyHidden,
  VStack,
} from '@chakra-ui/react';
import isEqual from 'lodash/isEqual';
import { XIcon } from 'lucide-react';
import { v4 } from 'uuid';

import { CustomFields } from '@m3ter-com/m3ter-api';
import { useFormError, useTranslation } from '@m3ter-com/console-core/hooks';
import {
  AutocompleteInput,
  Input,
  Select,
  SelectOption,
} from '@m3ter-com/ui-components';
import {
  FormAddRowButton,
  FormMultiRowGridWrapper,
  FormMultiRowWrapper,
} from '@m3ter-com/console-core/components';

export interface CustomFieldsEditorProps {
  name: string;
  value?: CustomFields;
  defaults?: CustomFields;
  onChange: (newValue: CustomFields) => void;
}

type FieldType = 'string' | 'number';
type FieldValue = string | number;

interface Row {
  id: string;
  name: string;
  value: FieldValue;
}

interface CustomFieldsEditorRowProps {
  index: number;
  name: string;
  nameOptions: Array<string>;
  value: FieldValue;
  defaultValue?: FieldValue;
  onNameChange: (newName: string) => void;
  onValueChange: (newValue: FieldValue) => void;
  onRemove?: () => void;
}

const emptyObject: Partial<CustomFields> = {};

const castValue = (type: FieldType, value: FieldValue): FieldValue => {
  if (type === 'number') {
    const newValue = Number(value);
    return !Number.isNaN(newValue) ? newValue : 0;
  }
  return String(value);
};

const getType = (value: FieldValue): FieldType =>
  typeof value === 'number' ? 'number' : 'string';

const CustomFieldsEditorRow: React.FC<CustomFieldsEditorRowProps> = ({
  index,
  name,
  nameOptions,
  value,
  defaultValue,
  onNameChange,
  onValueChange,
  onRemove,
}) => {
  const { t } = useTranslation();

  const typeOptions = useMemo<Array<SelectOption<FieldType>>>(
    () => [
      { value: 'string', label: t('features:customFields.string') },
      { value: 'number', label: t('features:customFields.number') },
    ],
    [t]
  );

  const [type, setType] = useState<FieldType>(getType(value));

  // When entering / selecting the name of a default custom field, the
  // defaultValue will change. We need to force the type when this happens.
  useEffect(() => {
    if (defaultValue) {
      setType(getType(defaultValue));
    }
  }, [defaultValue]);

  const onTypeChange = useCallback(
    (newFieldType: string | null) => {
      // We can safely cast here since we aren't making the <Select> clearable
      setType(newFieldType as FieldType);
      // Also need to update the value to be the new type.
      onValueChange(castValue(newFieldType as FieldType, value));
    },
    [value, onValueChange]
  );

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onValueChange(castValue(type, event.target.value));
    },
    [type, onValueChange]
  );

  const nameLabel = <FormLabel>{t('forms:labels.name')}</FormLabel>;
  const typeLabel = <FormLabel>{t('forms:labels.type')}</FormLabel>;
  const valueLabel = <FormLabel>{t('forms:labels.value')}</FormLabel>;

  return (
    <React.Fragment>
      <FormControl>
        {index > 0 ? <VisuallyHidden>{nameLabel}</VisuallyHidden> : nameLabel}
        <AutocompleteInput
          value={name}
          options={nameOptions}
          onChange={onNameChange}
        />
      </FormControl>
      <FormControl>
        {index > 0 ? <VisuallyHidden>{typeLabel}</VisuallyHidden> : typeLabel}
        <Select<FieldType>
          value={type}
          onChange={onTypeChange}
          isDisabled={!!defaultValue}
          options={typeOptions}
        />
      </FormControl>
      <FormControl>
        {index > 0 ? <VisuallyHidden>{valueLabel}</VisuallyHidden> : valueLabel}
        <Input
          value={value}
          onChange={handleValueChange}
          type={type === 'number' ? 'number' : 'text'}
        />
      </FormControl>
      <div>
        {onRemove && (
          <IconButton
            aria-label={t('common:remove')}
            icon={<XIcon />}
            onClick={onRemove}
            mt={index === 0 ? 8 : 0}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export const CustomFieldsEditor: React.FC<CustomFieldsEditorProps> = ({
  name,
  value = emptyObject,
  defaults = emptyObject,
  onChange,
}) => {
  const { t } = useTranslation();
  const { isInvalid, message } = useFormError(name);

  // We need to convert the object value into an array and maintain it in state
  // so that we can guarantee order and have consistent (React) keys.
  // We use a uuid for each row to prevent React mounting new components on key change
  // or keeping old components on adding/removing rows.
  const [rows, setRows] = useState<Array<Row>>(() =>
    Object.entries(value).map(([rowName, rowValue]) => ({
      id: v4(),
      name: rowName,
      value: rowValue as FieldValue,
    }))
  );

  // Calculate the available options from the defaults, but excluding any that are already being used.
  const nameOptions = useMemo(
    () =>
      Object.keys(defaults).reduce<Array<string>>((acc, key) => {
        if (!rows.find((row) => row.name === key)) {
          acc.push(key);
        }
        return acc;
      }, []),
    [rows, defaults]
  );

  const onRemoveRow = useCallback((id: string) => {
    setRows((currentRows) => currentRows.filter((row) => row.id !== id));
  }, []);

  const onNameChange = useCallback((id: string, newName: string) => {
    setRows((currentRows) =>
      currentRows.map((row) =>
        row.id === id ? { ...row, name: newName } : row
      )
    );
  }, []);

  const onValueChange = useCallback((id: string, newValue: FieldValue) => {
    setRows((currentRows) =>
      currentRows.map((row) =>
        row.id === id ? { ...row, value: newValue } : row
      )
    );
  }, []);

  const onAdd = useCallback(() => {
    setRows((currentRows) => [
      ...currentRows,
      { id: v4(), name: '', value: '' },
    ]);
  }, []);

  // When rows change we can re-build the object from the rows and call onChange.
  useEffect(() => {
    const updated = rows.reduce<CustomFields>((acc, row) => {
      acc[row.name] = row.value;
      return acc;
    }, {});

    if (!isEqual(updated, value)) {
      onChange(updated);
    }
  }, [onChange, rows, value]);

  return (
    <VStack width="100%" alignItems="stretch" spacing={4}>
      <FormControl id={name} isInvalid={!!isInvalid}>
        <FormMultiRowWrapper
          hasFields={rows.length > 0}
          emptyContentMessage={t('features:customFields.noCustomFieldsDefined')}
        >
          {rows.map((row, index) => {
            const { id, name: rowName, value: rowValue } = row;
            const defaultValue = defaults?.[rowName];

            return (
              <FormMultiRowGridWrapper key={id} columnCount={3}>
                <CustomFieldsEditorRow
                  index={index}
                  name={rowName}
                  value={rowValue}
                  nameOptions={nameOptions}
                  defaultValue={defaultValue}
                  onNameChange={(newName: string) => {
                    onNameChange(id, newName);
                  }}
                  onValueChange={(newValue: FieldValue) => {
                    onValueChange(id, newValue);
                  }}
                  onRemove={() => {
                    onRemoveRow(id);
                  }}
                />
              </FormMultiRowGridWrapper>
            );
          })}
        </FormMultiRowWrapper>
        {isInvalid && <FormErrorMessage>{message}</FormErrorMessage>}
      </FormControl>
      <FormAddRowButton onAdd={onAdd} />
    </VStack>
  );
};
