import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  del,
  PathParams,
  DataType,
  DataTypeToEntity,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { dataDeleted } from '@/store/data/data';
import { extractError } from '@/util/error';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useNotification from '@/hooks/util/useNotification';
import useOrg from '@/hooks/data/crud/useOrg';

interface DeleteMutationData<DT extends DataType> {
  actionName?: string;
  entity: DataTypeToEntity[DT];
  pathParams?: PathParams;
}

const useEntityDeleteMutation = <DT extends DataType>(dataType: DT) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(dataType);

  const dispatch = useDispatch();
  const { toast } = useNotification();
  const { currentOrgId: organizationId } = useOrg();

  const queryClient = useQueryClient();

  const { mutate: deleteEntity } = useMutation({
    mutationFn: async (deleteData: DeleteMutationData<DT>) => {
      const pathParams = {
        organizationId,
        ...deleteData.pathParams,
      };
      return del({
        actionName: deleteData.actionName,
        dataType,
        id: deleteData.entity.id,
        pathParams,
      });
    },
    onSuccess: (_, deleteData) => {
      dispatch(dataDeleted(dataType, [deleteData.entity.id]));
      queryClient.invalidateQueries({ queryKey: [dataType] });
      toast({
        status: 'success',
        description: t('notifications:entityDeleteSuccess', {
          entityName: entityNamings.singular,
        }),
        duration: 5000,
      });
    },
    onError: (error) => {
      const appError = extractError(error);
      toast({
        status: 'error',
        description: t('notifications:entityDeleteFailure', {
          entityName: entityNamings.singular,
        }).replace('{error}', appError.message),
      });
    },
  });

  return {
    deleteEntity,
  };
};

export default useEntityDeleteMutation;
