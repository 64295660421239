import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DataType, Entity, Id, PathParams } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import {
  deleteItem as deleteItemAction,
  deleteItems as deleteItemsAction,
  selectDeleteState,
} from '@/store/crud';
import { NotificationDefinition } from '@/store/store';
import useEntityNamings from '@/hooks/util/useEntityNamings';

const useEntityDelete = (
  dataType: DataType,
  returnPath?: string,
  pathParams?: PathParams
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(dataType);

  // Memoize the selector otherwise it will be new function per render.
  const deleteStateSelector = useMemo(
    () => selectDeleteState(dataType),
    [dataType]
  );
  const deleteState = useSelector(deleteStateSelector);

  const deleteItem = useCallback(
    (item: Entity) => {
      const successNotification: NotificationDefinition = {
        type: 'success',
        message: t('notifications:entityDeleteSuccess', {
          entityName: entityNamings.singularLower,
        }),
        removeAfter: 5000,
      };
      const failureNotification: NotificationDefinition = {
        type: 'error',
        message: t('notifications:entityDeleteFailure', {
          entityName: entityNamings.singularLower,
        }),
      };
      dispatch(
        deleteItemAction(
          dataType,
          item.id,
          successNotification,
          failureNotification,
          returnPath,
          pathParams
        )
      );
    },
    [t, entityNamings, dispatch, dataType, returnPath, pathParams]
  );

  const deleteItems = useCallback(
    (ids: Array<Id>) => {
      const successNotification: NotificationDefinition = {
        type: 'success',
        message: t('notifications:entitiesDeleteSuccess', {
          entityName: entityNamings.singularLower,
        }),
        removeAfter: 5000,
      };
      const failureNotification: NotificationDefinition = {
        type: 'error',
        message: t('notifications:entitiesDeleteFailure', {
          entityName: entityNamings.singularLower,
        }),
      };
      dispatch(
        deleteItemsAction(
          dataType,
          ids,
          successNotification,
          failureNotification
        )
      );
    },
    [t, entityNamings, dispatch, dataType]
  );

  return {
    isLoading: !!deleteState?.isLoading,
    error: deleteState?.error,
    deleteItem,
    deleteItems,
  };
};

export default useEntityDelete;
