import React from 'react';

import { Link } from '@chakra-ui/react';

import { DataType, IntegrationRun } from '@m3ter-com/m3ter-api';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { IntegrationRunStatusBadge } from '@/components/features/integrations/IntegrationRunStatusBadge';

export interface IntegrationRunLinkProps {
  run: IntegrationRun;
}

export const IntegrationRunLink: React.FC<IntegrationRunLinkProps> = ({
  run,
}) => {
  const { toLongDateTime } = useDateFormatter();

  return (
    <Link
      alignItems="center"
      display="flex"
      flexFlow="row nowrap"
      gap={2}
      justifyContent="flex-start"
      as={CrudDetailsLink}
      dataType={DataType.IntegrationRun}
      id={run.id}
    >
      <span>{toLongDateTime(run.dtStarted)}</span>
      <IntegrationRunStatusBadge status={run.status} />
    </Link>
  );
};
