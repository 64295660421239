import React from 'react';

import { DataType, ExportSchedule } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { ExportSchedulesRoute } from '@/components/routes/data-exports/export-schedules/ExportSchedulesRoute';
import { ExportSchedulesCreateRoute } from '@/components/routes/data-exports/export-schedules/ExportSchedulesCreateRoute';
import { ExportSchedulesEditRoute } from '@/components/routes/data-exports/export-schedules/ExportSchedulesEditRoute';
import { ExportSchedulesDetailsRoute } from '@/components/routes/data-exports/export-schedules/ExportSchedulesDetailsRoute';

export default (): NamedRoute =>
  createCrudRoute<ExportSchedule>({
    path: 'schedules',
    dataType: DataType.ExportSchedule,
    element: <ExportSchedulesRoute />,
    create: <ExportSchedulesCreateRoute />,
    edit: <ExportSchedulesEditRoute />,
    details: <ExportSchedulesDetailsRoute />,
  });
