import {
  AnyAction,
  createAction,
  createReducer,
  createSelector,
  PayloadAction,
  PrepareAction,
} from '@reduxjs/toolkit';

import {
  DataType,
  DataTypeToEntity,
  Entity,
  Id,
  UnknownEntity,
  getEntityRelatedData,
} from '@m3ter-com/m3ter-api';

import { AppError } from '@/types/errors';
import { ListSearchCriteria, ListSortCriteria } from '@/types/lists';

import {
  createSelectByDataTypeAndId,
  createSelectByDataTypeAndIds,
  createSelectById,
  DataState,
} from '@/store/data/data';

import listReducer, { selectList, ListState, ListPage } from './list';
import viewReducer, { ViewState } from './view';
import deleteReducer, { type DeleteState } from './delete';
import crudCreateReducer, { type CreateState } from './create';
import updateReducer, { type UpdateState } from './update';

// Re-export types and actions from sub-slices.
export * from './list';
export * from './view';
export * from './delete';
export * from './create';
export * from './update';

export interface CrudBaseState {
  id?: Id;
  relatedData?: RelatedData;
}

export interface CrudData {
  list: ListState;
  view: ViewState;
  delete: DeleteState;
  create: CreateState;
  update: UpdateState;
}

export interface CrudState {
  dataTypes: Partial<Record<DataType, CrudData>>;
}

export interface BasePayload {
  dataType: DataType;
}

export interface BaseItemPayload {
  dataType: DataType;
  id: Id;
}

interface RelationshipData {
  dataType: DataType;
  ids: Array<string>;
}

interface ListData<E extends Entity> {
  allEntities: Array<E>;
  currentPageEntities: Array<E>;
  currentPageIndex: number;
  error?: AppError;
  filterCriteria?: ListSearchCriteria;
  isLoading: boolean;
  pages: Array<ListPage>;
  searchCriteria?: ListSearchCriteria;
  sortCriteria?: ListSortCriteria;
}

export type RelatedData = Record<string, RelationshipData>;

const name = 'crud';

const ensureDataTypeStateExists = (
  state: CrudState,
  dataType: DataType,
  action: AnyAction
) => {
  if (!state.dataTypes[dataType]) {
    state.dataTypes[dataType] = {
      list: listReducer(undefined, action),
      view: viewReducer(undefined, action),
      delete: deleteReducer(undefined, action),
      create: crudCreateReducer(undefined, action),
      update: updateReducer(undefined, action),
    };
  }
};

const getStateForDataType = (
  state: CrudState,
  dataType: DataType,
  action: AnyAction
): CrudData => {
  ensureDataTypeStateExists(state, dataType, action);
  return state.dataTypes[dataType] as CrudData;
};

const mergeRelationshipsWithEntities = (
  sourceDataType: DataType,
  sourceEntities: Array<UnknownEntity>,
  relationshipEntities: Record<string, Array<Entity>>
) =>
  sourceEntities.map((entity) => {
    const relatedData = getEntityRelatedData(
      sourceDataType,
      entity,
      relationshipEntities
    );
    return { ...entity, ...relatedData };
  });

const initialState: CrudState = {
  dataTypes: {},
};

export const reset = createAction('crud/reset');
export const resetByDataType = createAction<PrepareAction<BasePayload>>(
  'crud/resetByDataType',
  (dataType: DataType) => ({ payload: { dataType } })
);

const crudReducer = createReducer(initialState, (builder) => {
  builder.addCase(reset, () => initialState);
  builder.addCase(
    resetByDataType,
    (state: CrudState, action: PayloadAction<BasePayload>) => {
      const { dataType } = action.payload;
      const dataTypeState = getStateForDataType(state, dataType, action);

      dataTypeState.list = { lists: {} };
      dataTypeState.view = { isLoading: false };
      dataTypeState.delete = { isLoading: false };
      dataTypeState.create = { isSaving: false };
      dataTypeState.update = { isLoading: false, isSaving: false };
    }
  );

  builder.addMatcher(
    (action) => action.type.startsWith('crud/') && action.type !== 'crud/reset',
    (state, action) => {
      const { dataType } = action.payload;
      const dataTypeState = getStateForDataType(state, dataType, action);

      // All CRUD actions are passed to the reducers responsible for each sub-slice.
      dataTypeState.list = listReducer(dataTypeState.list, action);
      dataTypeState.view = viewReducer(dataTypeState.view, action);
      dataTypeState.delete = deleteReducer(dataTypeState.delete, action);
      dataTypeState.create = crudCreateReducer(dataTypeState.create, action);
      dataTypeState.update = updateReducer(dataTypeState.update, action);
    }
  );
});

// Selectors.

const selectCrudState = (state: { [name]: CrudState }): CrudState =>
  state[name];

const selectDataTypes = createSelector(
  selectCrudState,
  (crudState) => crudState.dataTypes
);

const selectDataType = (dataType: DataType) =>
  createSelector(selectDataTypes, (dataTypes) => dataTypes[dataType]);

export const selectItemData = <S extends CrudBaseState>(
  dataType: DataType,
  stateSelector: (state: { crud: CrudState }) => S | undefined
) =>
  createSelector(
    stateSelector,
    createSelectByDataTypeAndId,
    (state, selectByDataTypeAndId) => selectByDataTypeAndId(dataType, state?.id)
  );

export const selectListState = (dataType: DataType, listId: string) =>
  createSelector(selectDataType(dataType), (dataForType) =>
    dataForType ? selectList(dataForType.list, listId) : undefined
  );

export const selectCombinedListData = <E extends Entity = Entity>(
  dataType: DataType,
  listId: string
) =>
  createSelector(
    selectListState(dataType, listId),
    createSelectByDataTypeAndIds,
    (listState, selectByDataTypeAndIds) => {
      if (!listState) {
        const defaultListData: ListData<E> = {
          allEntities: [],
          currentPageEntities: [],
          currentPageIndex: 0,
          isLoading: false,
          pages: [],
        };
        return defaultListData;
      }

      const entitiesByPage = listState.pages.map((page) => {
        if (page.ids.length === 0) {
          return [];
        }

        const coreEntities = selectByDataTypeAndIds(dataType, page.ids);
        if (!page.relatedData || Object.keys(page.relatedData).length === 0) {
          return coreEntities;
        }

        const relatedEntities = Object.fromEntries(
          Object.entries(page.relatedData).map(
            ([relationshipName, relatedEntityIds]) => [
              relationshipName,
              selectByDataTypeAndIds(
                relatedEntityIds.dataType,
                relatedEntityIds.ids
              ),
            ]
          )
        );
        const finalEntities = mergeRelationshipsWithEntities(
          dataType,
          coreEntities,
          relatedEntities
        );
        return finalEntities;
      });

      const listData: ListData<E> = {
        allEntities: entitiesByPage.flat() as Array<E>,
        currentPageEntities: (entitiesByPage[listState.currentPageIndex] ||
          []) as Array<E>,
        currentPageIndex: listState.currentPageIndex,
        error: listState.error,
        filterCriteria: listState.filterCriteria,
        isLoading: listState.isLoading,
        pages: listState.pages,
        searchCriteria: listState.searchCriteria,
        sortCriteria: listState.sortCriteria,
      };

      return listData;
    }
  );

export const selectViewState = (dataType: DataType) =>
  createSelector(selectDataType(dataType), (dataForType) => dataForType?.view);

export const selectRelatedData = <S extends CrudBaseState, E extends Entity>(
  dataType: DataType,
  entitySelector: (state: {
    crud: CrudState;
    data: DataState;
  }) => E | undefined,
  stateSelector: (state: { crud: CrudState }) => S | undefined
) =>
  createSelector(
    stateSelector,
    entitySelector,
    createSelectByDataTypeAndIds,
    (state, entity, selectByDataTypeAndIds) => {
      const relatedData = state?.relatedData;

      let data;

      if (entity && relatedData && Object.keys(relatedData).length > 0) {
        const relatedEntities = Object.fromEntries(
          Object.entries(relatedData).map(
            ([relationshipName, relationshipData]) => [
              relationshipName,
              selectByDataTypeAndIds(
                relationshipData.dataType,
                relationshipData.ids
              ),
            ]
          )
        );
        data = getEntityRelatedData(dataType, entity, relatedEntities);
      }

      return data;
    }
  );

export const selectViewRelatedData = (dataType: DataType) =>
  selectRelatedData(
    dataType,
    selectItemData(dataType, selectViewState(dataType)),
    selectViewState(dataType)
  );

export const selectIsViewLoading = (dataType: DataType) =>
  createSelector(
    selectViewState(dataType),
    (viewState) => viewState?.isLoading ?? false
  );

export const selectViewError = (dataType: DataType) =>
  createSelector(selectViewState(dataType), (viewState) => viewState?.error);

export const selectViewEntity = <DT extends DataType>(dataType: DT) =>
  createSelector(
    selectViewState(dataType),
    createSelectById<DataTypeToEntity[DT]>(dataType),
    (viewState, entitySelector) => entitySelector(viewState?.id)
  );

export const selectCreateState = (dataType: DataType) =>
  createSelector(
    selectDataType(dataType),
    (dataForType) => dataForType?.create
  );

export const selectUpdateState = (dataType: DataType) =>
  createSelector(
    selectDataType(dataType),
    (dataForType) => dataForType?.update
  );

export const selectUpdateEntity = <DT extends DataType>(dataType: DT) =>
  createSelector(
    selectUpdateState(dataType),
    createSelectById<DataTypeToEntity[DT]>(dataType),
    (updateState, entitySelector) => entitySelector(updateState?.id)
  );

export const selectDeleteState = (dataType: DataType) =>
  createSelector(
    selectDataType(dataType),
    (dataForType) => dataForType?.delete
  );

export const selectFilterCriteria = (dataType: DataType, listId: string) =>
  createSelector(
    selectListState(dataType, listId),
    (listState) => listState?.filterCriteria
  );

// Default export is the reducer itself.
export default crudReducer;
