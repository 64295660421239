import React, { ComponentProps } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { InputGroup, InputRightAddon } from '@chakra-ui/react';
import { XIcon } from 'lucide-react';

import { IconButton, Input, SelectOption } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudListFilterComparatorSelect } from './CrudListFilterComparatorSelect';

interface CrudListInputFilterProps
  extends Omit<ComponentProps<typeof Input>, 'value' | 'onChange'> {
  name: string;
  comparatorOptions: Array<SelectOption>;
}

export const CrudListInputFilter: React.FC<CrudListInputFilterProps> = ({
  name,
  comparatorOptions,
  ...props
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: {
          value = { comparator: comparatorOptions[0].value, value: '' },
          onChange,
        },
      }) => (
        <CrudListFilterComparatorSelect
          value={value}
          onChange={onChange}
          comparatorOptions={comparatorOptions}
        >
          <InputGroup>
            <Input
              value={value.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange({ ...value, value: event.target.value })
              }
              {...props}
            />
            <InputRightAddon p={0}>
              <IconButton
                icon={<XIcon size={16} />}
                borderRadius="inherit"
                aria-label={t('features:search.clearFilter')}
                onClick={() => onChange({ ...value, value: '' })}
              />
            </InputRightAddon>
          </InputGroup>
        </CrudListFilterComparatorSelect>
      )}
    />
  );
};
