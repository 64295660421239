import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { SimpleGrid } from '@chakra-ui/react';

import { DataType, CounterAdjustment } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { formatEntityUnit } from '@/util/data';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { DetailsCard } from '@/components/common/data/DetailsCard';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink';

export interface ItemCounterAdjustmentsDetailsProps {
  data: CounterAdjustment;
}

export const ItemCounterAdjustmentsDetails: React.FC<
  ItemCounterAdjustmentsDetailsProps
> = ({ data: itemCounterAdjustment }) => {
  const { t } = useTranslation();
  const { toLongDate } = useDateFormatter();

  const pathParams = useOrgPathParams();

  const { data: itemCounter } = useQuery(
    dataTypeRetrieveQuery({
      dataType: DataType.Counter,
      id: itemCounterAdjustment.counterId,
      pathParams,
    })
  );

  return (
    <React.Fragment>
      <BreadcrumbItem
        title={`${itemCounterAdjustment.value}${
          itemCounter ? ` - ${itemCounter.unit}` : `${t('forms:labels.units')}`
        }`}
      />
      <DetailsCard
        showEditButton
        data={itemCounterAdjustment}
        dataType={DataType.CounterAdjustment}
        showMetadata={false}
        details={
          <SimpleGrid columns={4} gap={6} w="100%">
            <KeyValue
              label={t('forms:labels.date')}
              value={toLongDate(itemCounterAdjustment.date)}
            />
            <KeyValue
              label={t('forms:labels.value')}
              value={itemCounterAdjustment.value}
            />
            {itemCounter && (
              <KeyValue
                label={t('forms:labels.unit')}
                value={formatEntityUnit(itemCounter.unit)}
              />
            )}
            <KeyValueReferenceLink
              label={t('common:counter')}
              dataType={DataType.Counter}
              id={itemCounterAdjustment.counterId}
              accessor="name"
            />
            <KeyValueReferenceLink
              label={t('common:account')}
              dataType={DataType.Account}
              id={itemCounterAdjustment.accountId}
              accessor="name"
            />
            <KeyValue
              label={t('forms:labels.purchaseOrderNumber')}
              value={itemCounterAdjustment.purchaseOrderNumber || '-'}
            />
          </SimpleGrid>
        }
      />
    </React.Fragment>
  );
};
