import React from 'react';

import { DataType, UserInvite } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormInput,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import schema from '@/validation/userInvite';
import { FormDatePicker } from '@/components/forms/FormDatePicker';
import {
  FormEntityMultiSelect,
  DataTypeFormEntityMultiSelect,
} from '@/components/forms/FormEntityMultiSelect';

export interface UserInviteFormProps extends BaseFormProps<UserInvite> {}

const defaultInitialValues: Partial<UserInvite> = {};

export const UserInviteForm: React.FC<UserInviteFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={schema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: t('common:invitation'),
          })}
        >
          <FormField
            isRequired
            name="firstName"
            label={t('forms:labels.firstName')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="lastName"
            label={t('forms:labels.lastName')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="email"
            label={t('forms:labels.emailAddress')}
            type="email"
            control={FormInput}
          />
          <FormField
            name="contactNumber"
            label={t('forms:labels.contactNumber')}
            control={FormInput}
            type="number"
            helpText={t('forms:helpText.contactNumber')}
          />
          <FormField
            name="dtEndAccess"
            label={t('forms:labels.accessEndDate')}
            control={FormDatePicker}
            showTimeSelect
            helpText={t('forms:helpText.userAccessEndDate')}
          />
          <FormField
            isRequired
            name="dtExpiry"
            label={t('forms:labels.inviteExpiryDate')}
            control={FormDatePicker}
            helpText={t('forms:helpText.userInviteExpiryDate')}
          />
          <FormField
            name="permissionPolicyIds"
            label={t('common:permissionPolicys')}
            control={
              FormEntityMultiSelect as DataTypeFormEntityMultiSelect<DataType.PermissionPolicy>
            }
            dataType={DataType.PermissionPolicy}
            accessor="name"
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t('features:invitations.inviteUser')}
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
