import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Link,
} from '@chakra-ui/react';

import { DataType, PlanGroup, PlanGroupLink } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';

import { EntityRouteListIds } from '@/types/lists';

import useEntityDelete from '@/hooks/data/crud/useEntityDelete';
import type { NotificationDefinition } from '@/store/store';
import { addEntityLink } from '@/store/utils/linkEntity';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { PlanGroupAddPlansButton } from '@/components/features/pricing/PlanGroupAddPlansButton';

interface PlanGroupLinksListProps {
  planGroup: PlanGroup;
}

const listRelationships = ['plan'];

export const PlanGroupLinksList: React.FC<PlanGroupLinksListProps> = ({
  planGroup,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const addPlanSuccessNotification = useMemo<NotificationDefinition>(
    () => ({
      type: 'success',
      message: t('notifications:planGroupLinkCreatedSuccess'),
      removeAfter: 5000,
    }),
    [t]
  );
  const onAddPlans = useCallback(
    (planIds: Array<string>) => {
      dispatch(
        addEntityLink(
          DataType.PlanGroup,
          planGroup.id,
          DataType.Plan,
          planIds,
          addPlanSuccessNotification
        )
      );
    },
    [addPlanSuccessNotification, planGroup, dispatch]
  );

  const planGroupLinkQueryParams = useMemo(
    () => ({
      planGroup: planGroup.id,
    }),
    [planGroup.id]
  );

  const columns = useMemo<Array<ColumnDefinition<PlanGroupLink>>>(
    () => [
      {
        id: 'plan',
        header: t('common:plan'),
        accessor: (item: EntityWithRelationships<PlanGroupLink>) =>
          !!item.plan && (
            <Link
              as={CrudDetailsLink}
              dataType={DataType.Plan}
              id={item.planId}
            >
              {item.plan.name}
            </Link>
          ),
      },
    ],
    [t]
  );

  const { deleteItem } = useEntityDelete(DataType.PlanGroupLink);

  return (
    <Card>
      <CardHeader>
        <Flex justify="space-between" alignItems="center">
          <Heading as="h3" size="md">
            {t('features:planGroups.includedPlans')}
          </Heading>
          <PlanGroupAddPlansButton
            onAddPlans={onAddPlans}
            accountId={planGroup.accountId}
          />
        </Flex>
      </CardHeader>
      <CardBody>
        <CrudList<PlanGroupLink>
          listId={EntityRouteListIds.PlanGroupLink}
          dataType={DataType.PlanGroupLink}
          columns={columns}
          params={planGroupLinkQueryParams}
          relationships={listRelationships}
          onDelete={deleteItem}
        />
      </CardBody>
    </Card>
  );
};
