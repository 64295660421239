import { each, enforce, test } from 'vest';
import i18next from 'i18next';

import { CustomFields } from '@m3ter-com/m3ter-api';

export const validateCustomFields = (
  name: string,
  customFields: CustomFields = {}
) => {
  each(Object.entries(customFields), ([key, value]) => {
    test(
      name,
      i18next.t('forms:validations.customFields.nameAndValueRequired'),
      () => {
        enforce(key).isNotEmpty();
        enforce(`${value}`).isNotEmpty();
      }
    );
  });
};
