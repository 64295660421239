import React from 'react';

import { SimpleGrid } from '@chakra-ui/react';

import { ExternalMapping } from '@m3ter-com/m3ter-api';
import { KeyValue, Well } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { IntegrationDestinationLogo } from '@/components/features/integrations/IntegrationDestinationLogo';

interface ExternalMappingFormPrerequisiteFieldsDisplayProps
  extends Pick<
    ExternalMapping,
    'externalSystem' | 'externalTable' | 'm3terEntity'
  > {}

export const ExternalMappingFormPrerequisiteFieldsDisplay: React.FC<
  ExternalMappingFormPrerequisiteFieldsDisplayProps
> = ({ externalSystem, externalTable, m3terEntity }) => {
  const { t } = useTranslation();

  return (
    <Well w="100%">
      <SimpleGrid columns={3}>
        <KeyValue
          label={t('forms:labels.m3terEntityType')}
          value={t(`features:externalMappings.entityTypes.${m3terEntity}`)}
        />
        <KeyValue
          label={t('forms:labels.externalSystem')}
          value={
            <IntegrationDestinationLogo
              destination={externalSystem}
              height="20px"
            />
          }
        />
        <KeyValue
          label={t('forms:labels.externalTable')}
          value={externalTable}
        />
      </SimpleGrid>
    </Well>
  );
};
