import React, { useCallback, useMemo } from 'react';

import { HStack } from '@chakra-ui/react';

import {
  DataType,
  Balance,
  DrawdownChargeTypes,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack, MultiSelectOption } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormAdvancedNumberInput,
  FormField,
  FormInput,
  FormMultiSelect,
  FormSection,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { FormDatePicker } from '@/components/forms/FormDatePicker';
import {
  DataTypeFormEntityMultiSelect,
  FormEntityMultiSelect,
} from '@/components/forms/FormEntityMultiSelect';
import { FormPicklistSelect } from '@/components/features/accounts/FormPicklistSelect';

export interface BalanceFormProps
  extends BaseFormProps<UnsavedEntity<Balance>> {}

const defaultInitialValues: Partial<Balance> = {};

export const BalanceForm: React.FC<BalanceFormProps> = ({
  initialValues = defaultInitialValues,
  isEdit,
  isSaving,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Balance);

  const lineItemTypeOptions = useMemo<Array<MultiSelectOption>>(
    () =>
      Object.values(DrawdownChargeTypes).map((chargeType) => ({
        value: chargeType,
        label: t(`features:billing.lineItemTypes.${chargeType}`),
      })),
    [t]
  );

  const onSubmit = useCallback(
    (formValues: Balance) => {
      const { lineItemTypes = [], ...rest } = formValues;

      onSave({
        ...rest,
        lineItemTypes: lineItemTypes.length > 0 ? lineItemTypes : undefined,
      });
    },
    [onSave]
  );

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <NameCodeFields />
          <FormField
            name="description"
            label={t('forms:labels.description')}
            control={FormInput}
          />
          <FormField
            isRequired
            name="currency"
            label={t('forms:labels.currency')}
            control={FormPicklistSelect}
            useCodeAsValue
            dataType={DataType.Currency}
          />
          <HStack spacing={4}>
            <FormField
              isRequired
              name="startDate"
              label={t('forms:labels.startDateInclusive')}
              control={FormDatePicker}
              showTimeSelect
            />
            <FormField
              isRequired
              name="endDate"
              label={t('forms:labels.endDateExclusive')}
              control={FormDatePicker}
              showTimeSelect
            />
          </HStack>
        </FormSection>
        <FormSection isOptional heading={t('forms:labels.balanceDrawdown')}>
          <FormField
            name="productIds"
            label={t('forms:labels.drawDownProducts')}
            control={
              FormEntityMultiSelect as DataTypeFormEntityMultiSelect<DataType.Product>
            }
            isClearable
            dataType={DataType.Product}
            accessor="name"
            detailAccessor="code"
            helpText={t('forms:helpText.drawDownProducts', {
              entityName: entityNamings.singularLower,
            })}
          />
          <FormField
            name="lineItemTypes"
            label={t('forms:labels.drawDownChargeTypes')}
            control={FormMultiSelect}
            options={lineItemTypeOptions}
            isClearable
          />
          <FormField
            name="balanceDrawDownDescription"
            label={t('forms:labels.drawDownDescription')}
            control={FormInput}
          />
        </FormSection>
        <FormSection
          isOptional
          heading={t('forms:labels.balanceOverageAndRollover')}
        >
          <FormField
            name="rolloverAmount"
            label={t('forms:labels.rolloverAmount')}
            control={FormAdvancedNumberInput}
          />
          <FormField
            name="rolloverEndDate"
            label={t('forms:labels.rolloverEndDate')}
            control={FormDatePicker}
            showTimeSelect
          />
          <FormField
            name="overageSurchargePercent"
            label={t('forms:labels.overageSurchargePercent')}
            control={FormInput}
            type="number"
          />
          <FormField
            name="overageDescription"
            label={t('forms:labels.overageDescription')}
            control={FormInput}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', {
                  entityName: entityNamings.singularLower,
                })
              : t('forms:buttons.createEntity', {
                  entityName: entityNamings.singularLower,
                })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
