import React, { useMemo } from 'react';

import {
  ButtonGroup,
  Card,
  CardBody,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { Button, CardActionsHeader } from '@m3ter-com/ui-components';
import {
  DataType,
  ExportDestination,
  ExportSchedule,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';

import { EntityRouteListIds } from '@/types/lists';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import useCrudRouteNames from '@/hooks/navigation/useCrudRouteNames';
import useEntityDelete from '@/hooks/data/crud/useEntityDelete';
import { ColumnDefinition, CrudList } from '@/components/common/crud/CrudList';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';
import { NamedLink } from '@/components/common/navigation/NamedLink';
import { getScheduleFrequencyDescription } from '@/util/dataExport';

const searchFields = ['name', 'code'];
const relationships = ['exportDestinations'];

export const ExportSchedulesList: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const entityNamings = useEntityNamings(DataType.ExportSchedule);

  const { editRouteName } = useCrudRouteNames(DataType.ExportSchedule);
  const { deleteItem } = useEntityDelete(DataType.ExportSchedule);

  const columnDefinitions = useMemo<
    Array<ColumnDefinition<EntityWithRelationships<ExportSchedule>>>
  >(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (item) => (
          <Link
            as={CrudDetailsLink}
            dataType={DataType.ExportSchedule}
            id={item.id}
          >
            {item.name}
          </Link>
        ),
      },
      {
        id: 'code',
        header: t('forms:labels.code'),
        accessor: 'code',
      },
      {
        id: 'source-type',
        header: t('forms:labels.sourceType'),
        accessor: (item) =>
          t(`features:dataExports.sourceType.${item.sourceType}`),
      },
      {
        id: 'frequency',
        header: t('forms:labels.frequency'),
        accessor: (item) =>
          item.period
            ? getScheduleFrequencyDescription(item.period, item.scheduleType)
            : t(`features:dataExports.scheduleType.${item.scheduleType}`),
      },
      {
        id: 'destination-ids',
        header: t('common:destinations'),
        accessor: (item: EntityWithRelationships<ExportSchedule>) => (
          <Text as="span" whiteSpace="pre-line">
            {!!item.exportDestinations &&
              item.exportDestinations.map(
                (exportDestination: ExportDestination, index: number) => (
                  <React.Fragment key={exportDestination.id}>
                    <Link
                      as={CrudDetailsLink}
                      dataType={DataType.ExportDestination}
                      id={exportDestination.id}
                    >
                      {exportDestination.name}
                    </Link>
                    {index < item.exportDestinations.length - 1 && ', '}
                  </React.Fragment>
                )
              )}
          </Text>
        ),
      },
      {
        id: 'actions',
        header: '',
        accessor: (item) => (
          <EntityCrudActions<ExportSchedule>
            addReturnPath
            dataType={DataType.ExportSchedule}
            editRouteName={editRouteName}
            item={item}
            onDelete={deleteItem}
          />
        ),
      },
    ],
    [deleteItem, editRouteName, t]
  );

  const queryParams = useMemo(
    () => ({ returnPath: `${location.pathname}${location.search}` }),
    [location]
  );

  return (
    <Card maxH="fit-content">
      <CardActionsHeader
        actions={
          <ButtonGroup size="sm">
            <Button
              as={NamedLink}
              name="ad-hoc-export"
              queryParams={queryParams}
            >
              {t('features:dataExports.runAdhocExport')}
            </Button>
            <Button
              addReturnPath
              intent="primary"
              as={CrudCreateLink}
              dataType={DataType.ExportSchedule}
            >
              {t('forms:buttons.createEntity', {
                entityName: entityNamings.singularLower,
              })}
            </Button>
          </ButtonGroup>
        }
      >
        <Heading size="md">{entityNamings.plural}</Heading>
      </CardActionsHeader>
      <CardBody>
        <CrudList<ExportSchedule>
          dataType={DataType.ExportSchedule}
          listId={EntityRouteListIds.ExportSchedule}
          columns={columnDefinitions}
          relationships={relationships}
          searchFields={searchFields}
        />
      </CardBody>
    </Card>
  );
};
